@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  max-width: 100%;

  @include breakpoint(tablet-l) {
    gap: 20px;
    background: $whispering_gray;
    padding: 0 20px 20px 20px;
  }

  &__icon {
    @extend %flex-align;
  }

  &__footer {
    @extend %flex-end;
    width: 100%;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
