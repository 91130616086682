@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;

  &__main {
    padding: 0 32px;
  }

  &__divider {
    position: sticky;
    width: 100%;
    z-index: 999;
    border-bottom: 0.5px solid $gray;
    top: 86px;
  }

  &__content {
    display: flex;
    max-width: 1720px;
    margin: 0 auto;
    margin-top: 24px;
    gap: 40px;

    &__child {
      width: 100%;
      margin: 16px auto;
      padding-bottom: 8px;
      overflow-y: scroll;
      max-width: calc(1720px - 352px);
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
