@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  margin-top: 32px;

  &__content {
    @extend %flex-between;

    gap: 24px;
    margin-bottom: 12px;

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: 10px;
    }
  }
}
