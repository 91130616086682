@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  width: 100%;

  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    gap: 20px;
    background: $whispering_gray;
  }

  &__selection {
    @extend %flex-between;
    margin-bottom: 40px;

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 24px;
    }
  }

  &__select {
    @extend %flex-align;
    gap: 8px;
    cursor: pointer;
    max-width: max-content;

    @include breakpoint(tablet) {
      margin-bottom: 20px;
    }

    &__text {
      @extend %Circular-Std-14-reg;
    }
  }

  &__header {
    @extend %flex-between;
    width: 100%;
    gap: 40px;
    margin-bottom: 40px;

    @include breakpoint(tablet-l) {
      align-items: flex-start;
      gap: 15px;
      margin-bottom: 0;
      margin-top: 20px;
    }

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    &__view {
      svg {
        cursor: pointer;
      }
    }

    &__title {
      @extend %Circular-Std-25-bold;
      color: black !important;
    }

    &__buttons {
      @extend %flex-align;
      gap: 40px;

      @include breakpoint(tablet-l) {
        gap: 20px;
        justify-content: space-between;
        width: 100%;
      }

      @include breakpoint(tablet-m) {
        align-items: center;
        gap: 5px;
      }

      @include breakpoint(mobile-m) {
        width: 100%;
      }

      button {
        text-transform: capitalize;

        span {
          @include breakpoint(tablet-m) {
            font-size: 12px;
          }
        }
      }
      &__views {
        @extend %flex-align;
        gap: 24px;

        @include breakpoint(tablet-l) {
          gap: 10px;
          flex-wrap: wrap;
        }

        @include breakpoint(tablet-m) {
          gap: 5px;
        }
      }
    }
    &__view {
      @extend %flex-align;
      gap: 24px;

      &__icon {
        cursor: pointer;

        stroke: black;
      }
    }

    &__create {
      @extend %flex-end;
      align-items: center;
      gap: 24px;
      width: 100%;

      &__delete {
        span {
          @extend %Circular-Std-14-boldMax;
        }
      }

      @include breakpoint(tablet-m) {
        align-items: flex-start;
      }

      button {
        text-transform: capitalize;
      }

      &__catalog {
        @extend %flex-align;
        gap: 10px;
        padding: 8px 43px;
        border-radius: 5px;
        background-color: $paleBlue;

        &:hover {
          background-color: $pale_cornflower_blue;
        }

        span {
          margin: 0 !important;
        }
      }

      button {
        @include breakpoint(tablet-m) {
          font-size: 12px;
        }
      }

      @include breakpoint(tablet-l) {
        gap: 10px;
      }

      @include breakpoint(mobile) {
        flex-direction: column;
        gap: 5px;
      }

      &__box {
        @extend %flex-column;
        gap: 19px;
        background-color: $white;
        border-radius: 10px;
        border: 0.5px dashed #ccc;
        background: #fff;
        padding: 24px;
        margin-top: 6px;

        &__item {
          @extend %flex-align;
          @extend %Circular-Std-14;
          cursor: pointer;
          white-space: nowrap;
          gap: 8px;
        }

        svg {
          path {
            stroke: black;
          }
        }
      }

      &__button {
        @extend %flex-center;
        gap: 8px;
        border-radius: 5px;

        span {
          margin: 0 !important;

          @include breakpoint(tablet-m) {
            font-size: 12px;
          }
        }

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }

    &__filter {
      @extend %flex-center;
      gap: 29px;
    }
  }

  &__content {
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));

    @include breakpoint(tablet-l) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include breakpoint(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(mobile) {
      grid-template-columns: none;
    }

    &_empty {
      @extend %flex-center;
      @extend %Circular-Std-16-regular;
      margin: 20px 0;
      width: 100%;
    }

    &__grid {
      display: block;
    }
  }

  &__footer {
    @extend %flex-end;
    padding: 20px 0;
    padding-top: 0;

    &_grid {
      padding-top: 20px;
    }

    &__question {
      @extend %flex-between;
      gap: 8px;
      position: relative;

      &__text {
        @extend %Circular-Std-12-reg;
      }
    }

    &__pages {
      @extend %flex-center;
      gap: 8px;

      &__text {
        @extend %Circular-Std-14-light;

        &__bold {
          @extend %Circular-Std-14-med;
        }
      }
    }
  }

  &__actions {
    @extend %flex-column;
    gap: 8px;
    width: 100%;

    &__item {
      width: 100%;
      padding: 5px 8px;
      max-width: 114px;
      color: $sapphire;
      border-radius: 2px;
      background-color: $paleBlue;
      border: 1px solid $paleBlue;

      span {
        @extend %Circular-Std-12-light;
        text-transform: capitalize;

        @include breakpoint(tablet-l) {
          font-size: 10px;
        }

        @include breakpoint(tablet) {
          font-size: 12px;
        }
      }
    }
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
