@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %Public-Sans-12-reg;
  width: 40px;
  height: 40px;
  position: relative;

  &__border {
    border: 1px solid white;
  }
}

.lead {
  position: absolute;
  top: -4px;
  left: -10px;
}
