@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  @extend %Circular-Std-14-reg;
  gap: 5px;
  background-color: transparent;
  color: $black;
  padding: 10px;
  width: 100%;
  border-radius: 10px;
  max-width: fit-content;
}
