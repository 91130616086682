@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
// App.scss

.app {
  text-align: center;
  height: 100%;

  .calendar-container {
    margin-top: 20px;
    // height: 10000px;
    // border: 1px solid #ccc;
    // border-radius: 5px;
    max-width: 1200px;

    .rbc-calendar {
      border: none;
      height: 600px;

      .rbc-header {
        background-color: #D2E1F9;
        color: black;
        font-size: 16px !important;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
      }

      .rbc-header + .rbc-header {
        border-left: 1px solid #ddd;
      }

      .rbc-today {
        background-color: #f5f5f5;
      }

      .rbc-event {
        background-color: #2067dd;
        color: white;
        border: 10px;
        border-width: 50px;
        border-color: black;
        border-radius: 4px; /* Add border radius to give a rounded appearance */
        overflow: hidden; /* Hide overflow to prevent event content from overflowing */
      }
      .rbc-event-content {
        padding: 2px 5px; /* Add padding to the event content for better readability */
        overflow: hidden; /* Hide overflow to prevent event content from overflowing */
        text-overflow: ellipsis; /* Add ellipsis to truncate long event titles */
        white-space: nowrap; /* Prevent line breaks in event titles */
      }
      // .rbc-event{
      //   height: 100px;
      // }
      // .rbc-time-slot{
      //   height: 80px;
      // }
      .rbc-events-container{
        width: 100% !important;
      }

      .rbc-timeslot-group{
        min-height: 70px !important;
        background-color: white;
      }

      .rbc-day-bg.rbc-today{
        background-color:#F8F8F8;
      }
      
      .rbc-header.rbc-today{
        background-color:#F8F8F8;
      }

      .rbc-active{
        background-color:#F8F8F8;
      }
      
    }
  }
}
