@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;

  &__header {
    width: 100%;
    @extend %flex-between;

    &__info {
      &__title {
        @extend %Circular-Std-35-reg;

        @include breakpoint(tablet-m) {
          font-size: 25px;
          display: flex;
          flex-direction: column;
        }

        &__number {
          @extend %Circular-Std-12-reg;
          color: $sapphire !important;
        }
      }

      &__date {
        @extend %Circular-Std-16-regular;
        color: $shadowed-slate !important;
      }
    }
    &__logo {
      width: 152px;
      height: 70px;
      border-radius: 8px;

      @include breakpoint(tablet-m) {
        width: 120px;
      }

      @include breakpoint(mobile) {
        width: 120px;
      }
    }
  }

  &__info {
    @extend %flex-between;
    width: 100%;

    &__item {
      &__title {
        @extend %Circular-Std-16-med;
      }

      &__subtitle {
        @extend %Circular-Std-14;

        p {
          @extend %Circular-Std-14;
        }

        &:nth-of-type(2) {
          padding: 8px 0;
        }
      }
    }
  }

  &__count {
    @extend %flex-column;
    gap: 8px;
    width: 100%;
    align-items: flex-end;

    &__item {
      @extend %flex-between-end;
      width: 275px;

      &__title {
        @extend %Circular-Std-14;
        color: $dusk_slate !important;
      }

      &__subtitle {
        @extend %Circular-Std-14;
      }
    }
  }

  &__footer {
    @extend %flex-column;
    gap: 24px;
    padding-top: 40px;

    &__notes {
      @extend %flex-column;
      gap: 4px;

      &__title {
        @extend %Circular-Std-14-reg;
        color: $sapphire !important;
      }
      &__subtitle {
        @extend %Circular-Std-14-reg;
        color: $midnightBlack !important;
      }
    }

    &__details {
      @extend %flex-between-start;
      padding: 24px;
      border-radius: 10px;
      background: $sapphire;

      @include breakpoint(tablet) {
        padding: 24px 16px;
        align-items: flex-start;
      }

      @include breakpoint(tablet-m) {
        flex-direction: column;
        gap: 18px;
      }

      &__left {
        &__dates {
          @extend %flex-column;
        }

        &__title {
          @extend %Circular-Std-16-regular;
          color: $snowWhite !important;
        }

        &__date {
          @extend %Circular-Std-16-light;
          color: $snowWhite !important;

          @include breakpoint(tablet) {
            font-size: 12px;
          }
        }
      }
      &__right {
        max-width: 550px;

        &__title {
          @extend %Circular-Std-16-regular;
          color: $snowWhite !important;
          text-align: right;

          @include breakpoint(tablet-m) {
            text-align: left;
          }
        }

        &__total {
          @extend %Circular-Std-35-bold;
          @extend %flex-align;
          color: $snowWhite !important;
          max-width: 100%;

          &__count {
            @extend %text-ellipsis;
            display: block;
          }

          @include breakpoint(tablet) {
            font-size: 24px;
          }
        }
      }
    }
  }
}
