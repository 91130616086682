@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column-between;
  height: calc(100dvh - 140px);
  background-color: $snowWhite;

  &__head {
    @extend %flex-between;
    margin-bottom: 40px;

    &__name {
      &__title {
        @extend %Circular-Std-21;
        margin-bottom: 4px;
      }

      &__subtitle {
        @extend %Circular-Std-14-reg;
        color: $shadowed-slate !important;
      }
    }

    &__options {
      @extend %flex-align;
      gap: 4px;

      &__btn {
        &_cancel {
          border-radius: 5px;
          padding: 11px 8px;
          background-color: transparent;
          color: $gray;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.175px;
        }

        &_save {
          border-radius: 5px;
          padding: 11px 8px;
          background-color: $paleBlue;
          color: $sapphire;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.175px;
        }
      }
    }
  }

  &__footer {
    @extend %flex-between;
    gap: 32px;
    padding: 20px;

    @include breakpoint(tablet-l) {
      flex-direction: column;
    }
  }

  &__submit {
    @extend %flex-end;
    gap: 10px;

    &__button {
      @extend %Circular-Std-14-med;
      color: $white;
      border-radius: 4px;
      padding: 12px 16px;
      text-transform: capitalize;

      &:hover {
        opacity: 0.8;
        color: $white;
        background-color: $sapphire;
      }
    }

    &__cancel {
      @extend %Circular-Std-14-med;
      border-radius: 4px;
      padding: 12px 16px;
      text-transform: capitalize;

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
