@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-between;
  gap: 8px;
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: $pastelSky;
  cursor: pointer;

  &__content {
    @extend %flex-align;
    gap: 8px;
    overflow: hidden;

    &__file {
      min-width: 39px;
      min-height: 39px;
    }

    &__link {
      @extend %Circular-Std-14;
      color: $sapphire;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-decoration: underline !important;
    }

    &__icon {
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;

      path {
        fill: $dusk_slate;
      }
    }
  }
}
