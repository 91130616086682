@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  width: 100%;

  @include breakpoint(tablet-l) {
    background-color: $whispering_gray;
    padding: 0 20px 20px 20px;
  }

  &__content {
    padding-top: 20px;
  }
}
