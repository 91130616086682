@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;
  width: 100%;
  height: 173px;

  &__preview {
    @extend %flex-center;
    border-radius: 8px;
    height: inherit;
    width: 100%;
    background-color: black;
  }

  @include breakpoint(tablet) {
    max-width: 100%;
  }

  &__video {
    position: relative;
  }

  video {
    height: 148px;
  }

  img {
    height: 152px;
    object-fit: cover;
  }

  &__description {
    @extend %Circular-Std-14;
    font-style: italic;
  }

  &__relate {
    position: relative;
    overflow: hidden;
    height: 152px;

    border-radius: 10px 10px 0 0;
  }

  &__controls {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);

    &__head {
      @extend %flex-between;
      padding: 9px 8px;
    }

    &__main {
      @extend %flex-center;
    }

    &__footer {
      @extend %flex-between;
      padding: 4px 8px;

      color: white;
    }
  }

  &__video {
    position: relative;

    &__opacity {
      &__center {
        @extend %flex-center;
        width: 100%;
      }

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 9px 8px 4px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.2) 100%);

      &__top {
        @extend %flex-between;

        svg {
          path {
            fill: #fff;
          }
        }
      }

      &__play {
        @extend %flex-center;
        width: 22px;
        height: 22px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.4);
        position: absolute;
        top: 70px;
        cursor: pointer;

        svg {
          min-width: 24px;
          min-height: 24px;
        }
      }

      &__bottom {
        @extend %flex-between;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 4px 8px;
        background-color: $midnightBlack;

        &__text {
          @extend %Circular-Std-14;
          color: $snowWhite !important;

          &:last-of-type {
            @extend %Circular-Std-12;
          }
        }
      }
    }
  }
}
