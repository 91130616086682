@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-justify-between;
  width: 100%;
  gap: 16px;
  margin-bottom: 15px;

  &__content {
    @extend %flex-column;
    width: 100%;
    gap: 16px;

    &__footer {
      @extend %flex-between;
    }
  }
}
