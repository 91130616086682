@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    gap: 20px;
    background: $whispering_gray;
  }
  
  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;

    &__item {
      height: 80px;
      margin-bottom: 20px; /* Adjust the margin between items */
      margin-top: 20px;
      width: 100%;
    }

    &__subitem{
      height: 80px;
      margin-bottom: 20px; /* Adjust the margin between items */
      // margin-top: 30px;
      width: 50%;
    }
    
  }

  &__subtitle {
    @extend %Circular-Std-16-light;
    @extend %text-ellipsis;
  }

  &__head {
    @extend %flex-between;
    margin-top: 20px;
    margin-bottom: 20px;

    &__title {
      @extend %Circular-Std-25-bold;
      color: black !important;
    }

    &__body {
      // @extend %Circular-Std-25-bold;
      color: black !important;
    }
  }
}

.estimateBox {
  background-color: white; // Background color of the estimate box
  padding: 10px; // Padding around the estimate text
  border: 1px solid #EDEFF1; // Border around the estimate box
  border-radius: 5px; // Border radius to make the corners rounded
  margin-top: 30px; // Margin from the top of the estimate box
  width: 100%;
  height: 60px;
  .estimateText {
    font-size: 14px; // Font size of the estimate text
    color: #333; // Color of the estimate text
    font-weight: bold; // Font weight of the estimate text
  }
}

.customerInfoContainer {
  display: flex; // Display customer info boxes side by side
  gap: 20px;
}

.customerInfoBox {
  background-color: white; // Background color of each customer info box
  padding: 10px; // Padding around each customer info box
  border: 1px solid #EDEFF1; // Border around each customer info box
  border-radius: 5px; // Border radius to make the corners rounded
  // margin-right: 20px; // Margin between customer info boxes
  margin-top: 30px;
  width: 100%;
  height: 60px;

  .customerText {
    font-size: 14px; // Font size of the estimate text
    color: #333; // Color of the estimate text
    font-weight: bold; // Font weight of the estimate text
  }
}

.card__items {
  display: flex; // Display items side by side
  margin-top: 30px;
  gap: 20px;
  
  // Style for each DatePicker container
  .content__date {
    width: 155px; // Set width for each DatePicker, considering margin between them
    background-color: paleBlue;
    border-radius: 5px;
    // color: #2067dd; 
  }
}

.table-container {
  margin-top: 30px;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #EDEFF1;
}

th, td {
  padding: 10px;
  text-align: left;
  background-color: white; // Background color for all table cells
}

th {
  border-bottom: 1px solid #EDEFF1;
}
.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
