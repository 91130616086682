@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  top: 0;
  gap: 36px;
  z-index: 100;
  position: sticky;
  padding: 20px 40px;
  background: white;
  border-bottom: 0.5px solid $gray;

  @media (min-width: $large-d) {
    padding: 20px 0;
    max-width: 1720px;
    margin: 0 auto;
    border: none;
  }

  @include breakpoint(tablet-l) {
    padding: 20px;
  }

  &__menu {
    display: none;
    cursor: pointer;

    @include breakpoint(wide) {
      display: block;
      min-width: 24px;
    }
  }

  &__search {
    width: 100%;
    max-width: 843px;

    @media (min-width: $large-d) {
      display: flex;
      align-items: center;
      gap: 40px;
      max-width: 1000px;
    }
  }

  &__user {
    @extend %flex-align;
    gap: 24px;

    svg {
      @include breakpoint(tablet-l) {
        height: 24px;
      }
    }

    @include breakpoint(tablet-l) {
      gap: 24px;
    }
  }
}
