@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  min-height: 100vh;
  background-color: $tietiary;

  &__drawer {
    width: 320px;
  }

  &__content {
    @extend %flex-center;
    padding-top: 130px;

    @include breakpoint(desktop) {
      padding: 20px;
    }
  }
}

main {
  background-color: $tietiary;
}

.wrapper {
  display: flex;

  &__drawer {
    display: none;

    @include breakpoint(wide) {
      display: block;
    }
  }

  &__content {
    flex: 1;
    width: 100%;
    height: 100%;

    &_child {
      margin: 35px auto;
      max-width: calc(100% - 80px);

      @include breakpoint(tablet-l) {
        margin: 0;
        padding: 24px 0;
        max-width: 100%;
        height: calc(100dvh - 77.5px);
        overflow-y: auto;
        background-color: #f8f9fc;
      }

      &_background {
        margin: 0;
        padding: 34.5px;
        background-color: #f8f9fc;
        max-width: unset;

        @include breakpoint(tablet-l) {
          margin: 0;
          padding: 24px 0;
          height: calc(100dvh - 77.5px);
          background-color: #f8f9fc;
        }
      }
    }

    &_head {
      margin-left: 5px;
    }
  }
}
