@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.circle {
  opacity: 0;
  animation-duration: 4s;
  animation-name: animate;
  animation-iteration-count: infinite;
  animation-delay: 6s;
}

@keyframes animate {
  to {
    opacity: 1;
  }
}

.circle_web {
  opacity: 0;
  animation-duration: 4s;
  animation-name: animate_web;
  animation-delay: 0;

  animation-iteration-count: infinite;
}

@keyframes animate_web {
  to {
    opacity: 1;
  }
}
