@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center-column;
  position: relative;
  height: 269px;
  width: 100%;
  cursor: pointer;
  max-width: 493px;
  border-radius: 4px;
  gap: 5px;
  background: #f2f2f2;
  border: 1px solid #ccc;

  @include breakpoint(tablet-l) {
    height: 150px;
  }

  &__required {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 25px;

    &__error {
      color: red;
    }
  }

  &__error {
    border-color: red;
  }

  &__file {
    @extend %flex-center-column;
    gap: 5px;

    &__name {
      @extend %flex-align;
      @extend %text-ellipsis;
      display: block;
      gap: 5px;
      font-family: $circularBold;
      font-size: 16px;
      max-width: 100px;
      text-align: center;

      svg {
        cursor: pointer;
        position: relative;
        z-index: 10;
      }
    }

    &__replace {
      font-family: $circularRegular;
      font-size: 14px;
    }
  }
}
