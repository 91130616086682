@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 32px;

  @include breakpoint(mobile) {
    width: 100%;
  }

  &__title {
    @extend %Circular-Std-25-bold;
    text-align: center;

    @include breakpoint(mobile) {
      font-size: 21px;
    }
  }

  &__fields {
    @extend %Circular-Std-21;
    margin-bottom: 8px;
  }

  &__icons {
    @extend %flex-align;
    gap: 8px;

    svg {
      &:first-of-type {
        path {
          fill: $midnightBlack;
        }
      }
    }
  }

  &__header {
    @extend %flex-center;
    @extend %Circular-Std-25-light;
    padding-bottom: 27px;
  }

  &__content {
    @extend %flex-column-between;
    height: calc(100dvh - 40px);

    &__title {
      padding-bottom: 16px;
    }

    &__select {
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding-top: 16px;

      &__item {
        background-color: $platinumGray;
        border-radius: 10px;
      }

      &__wrapper {
        position: relative;

        &__button {
          text-transform: capitalize;
        }

        &__dropdown {
          display: contents;

          &__box {
            top: 50px;
            left: 0;
            position: absolute;

            @include breakpoint(mobile) {
              left: -7px;
            }
          }
        }
      }

      &__error {
        @extend %Circular-Std-14-light;
        color: red !important;
      }

      &__item {
        fieldset {
          border: none;
        }
      }

      &__details {
        @extend %flex-column;
        gap: 16px;

        &__title {
          @extend %Circular-Std-16-light;
          color: $midnightBlack !important;
        }
        &__textarea {
          height: 110px;

          textarea {
            height: 110px !important;
            overflow-y: scroll !important;

            scrollbar-width: none;

            &::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
    }

    &__submit {
      width: 100%;
      max-width: 392px;
      margin-top: 16px;
      border-radius: 10px;
      padding: 20px 16px;
      text-transform: capitalize;
      background-color: $sapphire;
      color: $snowWhite;

      &:hover {
        background-color: $deep_cerulean_blue;
      }
    }

    &__actions {
      @extend %flex-end;
      gap: 24px;
      margin-top: 55px;
      margin-bottom: 40px;

      &__item {
        max-height: 40px;
        border-radius: 5px;
        height: 100%;
        padding: 11px 16px;
        text-transform: capitalize;
      }
    }
  }
}
