@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column-between;
  height: calc(100dvh - 140px);

  &__submit {
    @include breakpoint(tablet-m) {
      padding-bottom: 40px;
    }

    &__item {
      border-radius: 4px;

      &__icon {
        path {
          stroke: #fff;
        }
      }

      span {
        @extend %flex-align;
        @extend %Circular-Std-14-boldMax;
        gap: 4px;
        text-transform: capitalize;
      }
    }
  }
}
