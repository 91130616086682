@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__history {
    margin: 40px 0;
  }

  &__tabs {
    @extend %flex-center;
    border-radius: 5px;
    border: 0.5px dashed $paleSilver;
    background: $platinumGray;
    padding: 16px;
    gap: 24px;
    margin-bottom: 40px;

    @include breakpoint(tablet) {
      flex-direction: column;

      align-items: flex-start;
      gap: 5px;
      padding: 5px;
    }

    &__btn {
      border-radius: 5px;
      background: #fff;
      color: $black;
      max-width: 176px;
      text-transform: capitalize;

      @include breakpoint(tablet) {
        max-width: 100%;
      }

      span {
        @extend %Circular-Std-14;
        letter-spacing: 0.014px;
      }

      &:hover {
        background: #fff;
        color: $black;
      }

      &_active {
        background-color: $sapphire;
        color: $white;

        &:hover {
          background-color: $sapphire;
          color: $white;
        }
      }
    }
  }

  &__head {
    @extend %flex-between;
    align-items: flex-start;
    margin-bottom: 40px;
    position: relative;

    &__left {
      @extend %flex-column;
      gap: 8px;

      &__title {
        @extend %Circular-Std-21;
      }

      &__subtitle {
        @extend %flex-align;

        gap: 8px;

        &__title {
          @extend %Circular-Std-14;
          letter-spacing: 0.014px;
        }

        &__name {
          @extend %Circular-Std-14;
          color: $staleGray;
        }
      }
    }

    &__right {
      @extend %flex-column-between;
      gap: 18px;

      &__usage {
        span {
          @extend %Circular-Std-12-reg;
        }
      }
    }
  }

  &__content {
    &__head {
      @extend %flex-between;
      padding: 0 25px 20px;
      position: relative;
      width: calc(100% + 50px);
      margin: 0 -25px;
      border-bottom: 0.5px dashed $gray;

      &__item {
        @extend %Circular-Std-16-regular;
      }

      &__item {
        @extend %Circular-Std-16-regular;
      }
    }

    &__main {
      @extend %flex-between;
      padding: 16px 0;
      border-bottom: 0.5px dashed $gray;

      &__title {
        @extend %Circular-Std-14;
        text-transform: capitalize;
        color: $shadowed-slate !important;
      }

      &__subtitle {
        @extend %Circular-Std-16-light;
        @extend %text-ellipsis;
        text-transform: capitalize;
        max-width: 250px;
      }
    }
  }

  &__description {
    margin-top: 40px;

    &__img {
      border-radius: 5px;
      height: 205px;
      overflow: hidden;
      margin-bottom: 40px;
    }

    &__title {
      @extend %Circular-Std-16-regular;
      margin-bottom: 16px;
    }
  }
}

.section__title {
  @extend %Circular-Std-16-reg;

  color: $sapphire !important;
  padding: 10px 0;
  margin-bottom: 40px;

  &__history {
    @extend %Circular-Std-16-reg;
    padding: 10px 24px;
    color: $sapphire !important;
    border-bottom: 1px solid $subtle_blue;
  }
}
