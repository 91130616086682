@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;

  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
  }

  &__name {
    @extend %flex-align;
    gap: 8px;

    &__title {
      color: $sapphire;
      cursor: pointer;
      text-decoration: underline !important;
      text-underline-offset: 3px;
    }
  }

  &__icon {
    margin: 0 auto;
  }
  &__cards {
    @extend %flex-column;
    gap: 23px;
    padding: 0 16px;

    &__title {
      @extend %Circular-Std-16-med;
    }

    &__content {
      gap: 24px;
      row-gap: 54px;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(213px, 1fr));
    }
  }

  &__footer {
    @extend %flex-end;
  }
}

.loading {
  @extend %flex-center;
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
