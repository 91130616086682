@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-align;

  &__card {
    @extend %Circular-Std-14-reg;
    text-transform: capitalize;
  }

  &__last {
    @extend %Circular-Std-14-reg;
    margin-left: 4px;
  }
}
