@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  gap: 32px;
  display: flex;
  flex-direction: column;

  &__content {
    &__select {
      padding: 24px 16px 0;

      &__wrapper {
        &__dropdown {
          position: relative;

          &__box {
            position: absolute;
            left: 0;
            top: 35px;

            @include breakpoint(mobile) {
              left: -7px;
            }
          }
        }
      }
    }
  }

  &__icons {
    @extend %flex-align;
    gap: 8px;

    svg {
      &:first-child {
        path {
          fill: $midnightBlack;
        }
      }
    }
  }

  &__header {
    @extend %Circular-Std-25-bold;
  }

  &__form {
    @extend %flex-column;
    gap: 32px;

    &__content {
      @extend %flex-column;
      gap: 32px;
    }
  }

  &__title {
    display: flex;
    gap: 6px;

    &__typo {
      @extend %Circular-Std-21;
      color: $midnightBlack !important;
    }
    &__count {
      @extend %Circular-Std-21;
      color: $midnightBlack !important;
    }

    &__input {
      border-radius: 0;
    }
  }
  &__user {
    display: flex;
    gap: 16px;

    &__content {
      @extend %flex-align;
      flex-direction: column;

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 32px;
        margin-bottom: 8px;
      }

      p {
        @extend %Circular-Std-12-light;
        color: rgba(34, 34, 34, 0.5) !important;
      }
    }
  }
  &__wrapper {
    @extend %flex-justify-between;
  }

  &__actions {
    @extend %flex-end;
    gap: 24px;
    margin-top: 11px;

    &__item {
      max-height: 40px;
      border-radius: 5px;
      text-transform: capitalize;
    }
  }
}

.details {
  @extend %flex-column;
  @extend %Circular-Std-21;
  gap: 16px;
}

.textarea {
  textarea {
    overflow-y: scroll !important;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.badge {
  @extend %flex-column;
  position: relative;
  padding-bottom: 32px;
  gap: 8px;

  &__title {
    display: flex;
    gap: 6px;

    &__typo {
      color: $midnightBlack !important;
      font-family: $circularRegular !important;
      font-size: 21px !important;
      font-weight: 500 !important;
      letter-spacing: 0.035px !important;
    }
    &__count {
      color: $midnightBlack !important;
      font-family: $circularRegular !important;
      font-size: 21px !important;
      font-weight: 500 !important;
      letter-spacing: 0.035px !important;
    }
  }
  &__icon {
    margin-right: 8px;
    svg {
      display: flex;
    }
  }

  &__tag {
    display: flex;
    margin-top: 16px;
    align-items: center;

    &__item {
      gap: 8px;
      display: flex;
      flex-wrap: wrap;

      div {
        margin-right: 0 !important;
      }
    }
  }
  &__wrapper {
    @extend %flex-justify-between;
    position: relative;

    &__add {
      @extend %flex-align;
      gap: 8px;

      &__more {
        color: $sapphire !important;
        font-family: $circularRegular !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        letter-spacing: 0.048px !important;
        cursor: pointer;
      }
      &__icon {
        width: 16px;
        height: 16px;
        transition: 0.3s;
        cursor: pointer;

        path {
          stroke: $sapphire !important;
        }

        &__active {
          transform: rotate(180deg);
        }
      }
    }
    &__dropdown {
      display: contents;

      &__box {
        top: 19px;
        right: 0;
        position: absolute;
      }
    }
  }
}
