@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  max-width: 1302px;
  margin: 0 auto;

  @include breakpoint(wide) {
    padding: 0;
  }

  .label {
    font-size: 12px;
    color: $midnightBlack;
    font-style: italic;
    margin-top: 8px !important;

    &__name {
      &_disable {
        color: $staleGray;
      }
    }

    span {
      font-family: $circularLight;
      font-size: 12px;
      font-weight: 300;
      line-height: 17px;
      letter-spacing: 0.004em;

      &_active {
        color: black !important;
      }

      &_completed {
        color: black !important;
      }

      &__icon {
        color: blue;

        svg {
          color: white;
        }
      }
    }
  }
}
.icon__layout {
  @extend %flex-center;
  width: 32px;
  height: 32px;
  background-color: $paleSilver;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  z-index: 10;

  svg {
    width: 28px;
    height: 28px;

    text {
      fill: $paleSilver;
    }
  }

  &_active {
    background-color: $pastelSky;

    svg {
      width: 28px;
      height: 28px;
      color: $sapphire !important;

      text {
        fill: white;
      }
    }
  }

  &_completed {
    background-color: $sapphire;

    svg {
      width: unset;
      height: unset;
    }
  }
}

.connector {
  left: calc(-50% + 10px);
  right: calc(50% + 10px);

  &__line {
    border-width: 4px;
    border-color: $paleSilver;
    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.03), 4px 0px 6px 0px rgba(0, 0, 0, 0.03);

    &_active {
      span {
        border-color: $sapphire !important;
      }
    }
  }
}
