@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  margin-top: 8px;
  height: calc(100vh - 143.5px);

  &__header {
    @extend %Circular-Std-25-light;
    text-align: center;
    margin: 40px 0 34px;
  }

  &__content {
    @extend %flex-column-between;
    gap: 80px;
    height: 100%;

    &__inputs {
      @extend %flex-column;
      width: 100%;
      gap: 16px;
    }

    &__actions {
      @extend %flex-end;
      width: 100%;
      gap: 24px;

      &__button {
        border-radius: 5px;
        padding: 11px 8px;
        text-transform: capitalize;
      }
    }
  }
}
