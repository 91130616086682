@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  margin-bottom: 24px;

  &__close {
    cursor: pointer;
  }

  &__title {
    @extend %Circular-Std-25-bold;
  }

  &__head {
    @extend %flex-column;
    gap: 4px;

    svg {
      cursor: pointer;
    }

    &__title {
      @extend %Circular-Std-21;
    }

    &__subtitle {
      @extend %Circular-Std-14-reg;
    }
  }

  &__options {
    @extend %flex-align;
    gap: 4px;

    button {
      max-width: 80px;
      padding: 11px 0;
      border-radius: 5px !important;
    }
  }
}
