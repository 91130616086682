@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;

  &__copy {
    cursor: pointer;
  }

  &__add {
    @extend %flex-align;
    @extend %Circular-Std-14;
    cursor: pointer;
    margin-top: 10px;
    gap: 4px;
    color: rgba(34, 34, 34, 0.8) !important;

    p {
      font-family: $circularRegular !important;
    }
  }
}
