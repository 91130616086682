@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  padding: 24px 32px;
  position: sticky;
  top: 0;
  z-index: 999;
  background: #fff;
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.05);

  &__content {
    @extend %flex-between;
    width: 100%;
    max-width: 1512px;
    margin: 0 auto;

    position: relative;

    &__button {
      background-color: transparent;
      cursor: pointer;

      &:focus {
        background-color: transparent;
      }
    }
  }

  &__phone {
    @extend %flex-align;
    gap: 4px;
    right: 0;
    top: -24px;
    padding: 4px 8px;
    border-radius: 0 0 4px 4px;
    color: $sapphire;
    z-index: 10;
    font-family: $circularMedium;
    font-size: 14px;
    background-color: transparent;

    &:focus {
      background-color: transparent;
    }

    svg {
      path {
        fill: $sapphire;
      }
    }

    a {
      color: $sapphire !important;
    }
  }

  &__links {
    @extend %flex-between;
    gap: 16px;

    @include breakpoint(tablet) {
      display: none;
    }

    &__divider {
      color: $sapphire;
    }

    &__text {
      color: $sapphire;
    }

    &__button {
      padding: 12px 16px;
      border-radius: 4px;
      background: $sapphire;
      color: $white;

      &:hover {
        background: $sapphire;
        color: $white;
      }
    }

    &__signin {
      border-radius: 4px;
      padding: 12px 16px;
      background: $sapphire;
      color: $white;
    }
  }
}
