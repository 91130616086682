@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;
  width: 100%;
  height: calc(100dvh - 300px);
  // background: hsl(0deg 0% 0% / 90%);
  // backdrop-filter: blur(10px);

  @include breakpoint(tablet-l) {
    height: calc(100dvh - 340px);
  }

  @include breakpoint(tablet-m) {
    height: calc(100dvh - 380px);
  }

  &__stream {
    height: 100%;
    width: 100%;

    video {
      height: 100%;
      width: 100%;
    }
  }

  &_none {
    // background: hsl(0deg 0% 0% / 90%);
    // backdrop-filter: blur(10px);
  }

  &__general {
    height: 100%;
    width: 100%;

    div {
      height: 100%;
      width: 100%;

      video {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__remote {
    @extend %flex-align;
    display: none;
    max-width: 100%;
    width: 100%;
    overflow: scroll;
    z-index: 10;

    position: absolute;
    top: 0;
    gap: 10px;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
