$gray: #ccc;
$black: #000000;
$white: #ffffff;
$snowWhite: #fff;
$staleGray: #999;
$danger: #8d0000;
$frostBlue: #f0f7ff;
$jet_black: #000;
$primary: #faffff;
$tietiary: #fbfcfe;
$paleBlue: #d2e1f9;
$deep_black: #111;
$soft_gray: #b3b3b3;
$sapphire: #2067dd;
$mintGreen: #47c272;
$pureWhite: #fafafa;
$rich_crimson: #840000;
$pastelSky: #d2e1f9;
$lightGray: #cccccc;
$ocean_blue: #0054aa;
$paleSilver: #e6e6e6;
$dark_azure: #092655;
$obsidianAura: #555;
$midnightBlack: #222;
$royal_blue: #1d60ce;
$aquamarine_blue: #00d2e5;
$subtle_blue: #edeff1;
$frostWhite: #fcfcfc;
$light_steel_gray: #c4c7cb;
$forest_green: #0d7d0b;
$deep-indigo: #455168;
$cottonCloud: #f7f7f7;
$stormySlate: #757780;
$silverSlate: #878787;
$neutral-gray: #f8f8f8;
$graphiteGray: #545454;
$gunmetalGray: #4d4d4d;
$dusk_slate: #667085;
$verdant_green: #0c8401;
$cornflower_blue: #5569b2;
$gentle_blue: #6e8bf3;
$lighter_gray: #d9d9d9;
$pale_cornflower_blue: #bed0ee;
$midnight_gray: #151729;
$light_grayish_silver: #ebebeb;
$medium_gray: #a0a0a0;
$pale_cornflower_blue: #c4d3eb;
$deep_cerulean_blue: #195ac2;
$light_grayish_white: #f5f5f5;
$goldenrod_yellow: #d4bf00;
$translucent_charcoal: #222222cc;
$deep_cerulean_blue: #1859c0;
$flame_orange: #f8511c;
$platinumGray: #f2f2f2;
$tableCellGray: #838383;
$shadowSlate: #22222280;
$whispering_gray: #f8f9fc;
$midnight_slate: #444655;
$deep_cerulean: #1a53b2;
$charcoal_gray: #707070;
$midnight_charcoal: #222222;
$gentle_lavender: #f7f7fb;
$shadowed-slate: #222222b3;
$serenity_blue: #6f7fa0;
$fire_engine_red: #c20808;
$deepCharcoalBlue: #222222b2;
$semi_transparent_white: #ffffff66;
$semi_transparent_black: #222222e6;
$almost_invisible_black: #0000000d;
$semi_transparent_light_gray: #cccccc31;
