@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container__quill {
  gap: 0;
  height: 150px !important;

  .ql-toolbar {
    background-color: $platinumGray !important;
  }
}
