@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  width: 100%;
  @include breakpoint(tablet-l) {
    background: $whispering_gray;
    padding-bottom: 20px;
  }

  &_title {
    @extend %Circular-Std-25-light;
    color: $midnightBlack !important;
    padding-bottom: 40px;

    @include breakpoint(tablet-l) {
      padding-left: 40px;
    }

    @include breakpoint(mobile) {
      padding-left: 20px;
    }
  }

  &_content {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include breakpoint(tablet-l) {
      padding: 0 40px;
    }

    @include breakpoint(mobile) {
      padding: 0 20px;
    }

    &_box {
      display: flex;
      gap: 16px;

      button {
        padding: 12px 16px;
        border-radius: 5px;
        border: 0.5px dashed #ccc;

        @include breakpoint(wide-xl) {
          padding: 8px 10px;
          span {
            margin-right: 0;
            margin-left: 0;
          }
        }

        @include breakpoint(tablet-l) {
          min-width: 40px;
        }

        @include breakpoint(mobile) {
          padding: 8px 10px;
        }
      }
    }

    &_live {
      margin: 20px 0;
      width: 100%;
      border-radius: 10px;

      @include breakpoint(tablet-l) {
        margin: 20px 40px;
      }

      @include breakpoint(mobile) {
        margin: 20px;
      }
    }

    &_footer {
      display: flex;
      justify-content: space-between;

      &__controls {
        @extend %flex-align;
        gap: 16px;
      }

      &_mute {
        min-width: max-content !important;

        span {
          margin: 0;
        }
      }
    }

    &_next {
      display: flex;
      gap: 24px;
    }
  }

  &__invitation {
    width: 100%;

    @include breakpoint(tablet-l) {
      padding: 0 40px 40px;
    }

    &__box {
      @extend %flex-center-column;
      gap: 4px;
      height: 173px;
      cursor: pointer;
      max-width: 272px;
      border-radius: 5px;
      margin-bottom: 24px;
      background: $paleBlue;

      @include breakpoint(tablet-m) {
        max-width: 100%;
      }

      &__text {
        @extend %Circular-Std-16-regular;
        color: $midnightBlack !important;
      }
    }
    &__previews {
      padding-top: 24px;
      border-top: 0.5px dashed #ccc;

      &__title {
        @extend %Circular-Std-14;
        color: $midnightBlack !important;
      }
    }
    &__videos {
      gap: 24px;
      display: grid;
      padding-top: 24px;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      @include breakpoint(wide) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include breakpoint(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include breakpoint(tablet-m) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}
