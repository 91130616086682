@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  width: 100%;

  &__title {
    @extend %Circular-Std-16-regular;
  }

  &__content {
    @extend %flex-align;

    &__item {
      @extend %Circular-Std-12-reg;
      padding: 6.5px 12px;
      color: $black;
      border: 0.5px solid #ccc;
      cursor: pointer;
      background-color: $white;
      text-transform: capitalize;

      &:first-child {
        border-radius: 10px 0px 0px 10px;
      }

      &:last-child {
        border-radius: 0px 10px 10px 0px;
      }

      &_active {
        background-color: $pastelSky;
        color: $sapphire;
      }
    }
  }
}
