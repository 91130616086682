@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &_typo {
    @extend %Circular-Std-14-reg;
    letter-spacing: 0.035px;
    margin-bottom: 8px;
    overflow: hidden;
    -webkit-line-clamp: 2;
    white-space: pre-wrap;
    word-break: break-word;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;

    &_expanded {
      overflow: visible !important;
      display: block;
      -webkit-box-orient: inset;
    }
  }

  &_expand {
    @extend %Circular-Std-12-light;
    color: $dusk_slate !important;
    font-style: italic;
    cursor: pointer;
  }
}
