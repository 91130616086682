@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  width: 100%;
  height: 100%;

  &__content {
    @extend %flex-center;
    max-width: 100%;
    width: 100%;
    height: 100%;

    @include breakpoint(tablet) {
      max-width: 555px;
    }

    @include breakpoint(tablet-m) {
      max-width: 100%;
    }
  }
}
