@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 40px;

  &__header {
    @extend %flex-between;

    button {
      margin-bottom: 40px;
    }

    &:last-child {
      @include breakpoint(tablet-m) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }

    &__title {
      @extend %Circular-Std-25-bold;
    }

    &__view {
      @extend %flex-center;
      gap: 24px;

      svg {
        cursor: pointer !important;
      }

      &__icon {
        cursor: pointer;
        fill: $jet_black;

        &__active {
          path {
            fill: $sapphire;
          }
        }
      }
    }
    &__search {
      max-width: 256px;
      width: 100%;
    }

    &__create {
      border-radius: 5px;
      padding: 8px;

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}
