@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 5px;
  padding: 12px 16px;
  width: 100%;

  @include breakpoint(tablet-m) {
    flex-direction: column;
    align-items: flex-start;
    background: #f7f4f4;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  &__title {
    @extend %Circular-Std-16-bold;
    align-self: flex-start;

    @include breakpoint(tablet-m) {
      font-family: $circularRegular !important;
      color: rgba(34, 34, 34, 0.8) !important;
    }
  }

  &__subtitle {
    @extend %Circular-Std-16-light;
    @extend %text-ellipsis;

    &_link {
      a {
        @extend %text-ellipsis;
        color: $sapphire;
        text-underline-offset: 3px;
        text-decoration: underline !important;
        cursor: pointer;
      }
    }
  }
}
