@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  min-height: calc(100dvh - 120px);

  &__footer {
    @extend %flex-align;
    gap: 24px;
    width: 100%;
    justify-content: flex-end;
    padding: 10px 0;
  }

  &__header {
    text-align: center;
    margin-bottom: 37px;

    &__title {
      @extend %Circular-Std-25-light;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    &__item {
      width: 100%;

      &:nth-child(1) {
        grid-column: span 3;
      }

      &:nth-child(2) {
        grid-column: span 3;
      }

      &:nth-child(3) {
        grid-column: span 3;
      }

      &:nth-child(4) {
        grid-column: span 3;
      }

      &:nth-child(5) {
        grid-column: span 3;
      }

      &:nth-child(9) {
        grid-column: span 3;
      }

      @include breakpoint(mobile) {
        &:nth-child(6) {
          grid-column: span 3;
        }

        &:nth-child(7) {
          grid-column: span 3;
        }

        &:nth-child(8) {
          grid-column: span 3;
        }
      }
    }

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__submit {
      @extend %flex-center;
      text-transform: capitalize;
      margin-top: 16px;
      width: 100%;
    }
  }
}
