@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center-column;
  width: 100%;
  position: relative;

  &__rows {
    @extend %flex-center;
    gap: 16px;
    width: 100%;
    padding: 16px;
    border-bottom: 0.5px dashed $soft_gray;

    &__input {
      border: 0.5px dashed $soft_gray;
      border-radius: 5px;

      input {
        @extend %Circular-Std-12-light;
        padding: 12px 10px;
      }
    }
  }

  &__buttons {
    @extend %flex-between;
    width: 100%;
    padding: 16px;

    &__item {
      span {
        @extend %Circular-Std-12-reg;
      }
    }
  }
}
