@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  transition: 0.5s;

  &__border {
    display: flex;
    gap: 4px;
    padding: 4px;
    border-radius: 5px;
    border: 0.5px dashed $gray;
    background-color: $white;
  }

  &__button {
    color: $soft_gray;
    padding: 5px;
    border-radius: 4px;
    text-transform: capitalize;
    min-width: auto !important;

    &__text {
      font-family: $circularLight;
      font-size: 12px;
    }

    &:hover {
      color: $sapphire;
      background-color: transparent;
    }

    &_active {
      color: $white;
      background-color: $sapphire;

      .container__button__text {
        font-family: $circularRegular;
      }

      &:hover {
        color: $white;
        background-color: $sapphire;
      }
    }
  }
}
