@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-center;
  max-height: 102px;
  max-width: 213px;
  min-width: 213px;
  position: relative;
  border: 0.5px dashed $gray;
  background-color: $pureWhite;
  align-items: flex-start !important;

  &__content {
    @extend %flex-center-column;
    max-width: 100%;
    padding: 32px 12px 12px;
    gap: 7px;

    &__icon {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 16px;
      right: 16px;
    }

    &__link {
      @extend %Circular-Std-14;
      max-width: 100%;
      max-width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: $midnightBlack !important;
    }
  }
}
