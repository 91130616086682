@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  display: grid;
  gap: 12px 24px;
  width: 100%;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
