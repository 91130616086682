@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  &__bottom {
    @extend %flex-center;

    &__img {
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    height: 600px;

    @include breakpoint(tablet-l) {
      height: 400px;
    }

    &__slider {
      height: 600px !important;

      @include breakpoint(tablet-l) {
        height: 400px !important;
      }

      &__pagination {
        right: 0;
        margin-bottom: 45px !important;

        @include breakpoint(desktop) {
          top: 0 !important;
          margin: 0 !important;
          max-width: 150px !important;
          height: fit-content !important;
          background: hsla(0, 0%, 100%, 0.3);
          backdrop-filter: saturate(180%) blur(5px);
          box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  &_about {
    margin: 150px auto;
    text-align: center;
    width: 100%;

    max-width: 1512px;

    @media (max-width: 1680px) {
      padding: 10px 40px;
      max-width: 100%;
    }

    @include breakpoint(tablet-l) {
      padding: 10px 32px;
    }

    @include breakpoint(tablet) {
      margin: 50px auto;
      padding: 0 50px;
    }

    svg {
      @include breakpoint(tablet) {
        width: 270px;
        height: 270px;
      }
    }

    &_sub {
      margin: 24px 0;
      color: rgba(34, 34, 34, 0.8) !important;
      font-family: $circularLight !important;
      font-size: 25px !important;
      font-weight: 300 !important;
      line-height: normal !important;
    }

    &_title {
      color: $midnightBlack !important;
      font-family: $circularBold !important;
      font-size: 49px !important;
      font-weight: 500 !important;
      line-height: normal !important;
      margin-bottom: 24px;

      @include breakpoint(desktop) {
        font-size: 25px !important;
        margin-bottom: 20px !important;
      }
    }
  }

  &_features {
    text-align: center;
    max-width: 1512px;
    width: 100%;
    margin-top: 128px;
    margin: 128px auto;

    @media (max-width: 1680px) {
      padding: 0 40px;
      max-width: 100%;
    }

    @include breakpoint(tablet-l) {
      padding: 10px 32px;
    }

    @include breakpoint(tablet) {
      margin: 20px auto;
    }

    &_block {
      @extend %flex-column;
      gap: 160px;

      @include breakpoint(desktop) {
        gap: 20px;
      }

      &_wrapper {
        @extend %flex-between;
        gap: 40px;
        text-align: start;

        &_icon {
          max-width: 600px;
          width: 100%;
          height: 321px;
          margin-bottom: 3%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &__connector {
          svg {
            @include breakpoint(tablet-l) {
              width: 600px;
            }
          }

          @include breakpoint(tablet) {
            display: none;
          }
        }

        &__connector_single {
          display: none;
          height: 200px;

          svg {
            height: 200px;
          }

          @include breakpoint(tablet) {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        @include breakpoint(tablet) {
          flex-direction: column-reverse;
          margin-top: 20px;
        }

        @include breakpoint(tablet) {
          gap: 0;
        }

        svg {
          @include breakpoint(tablet-l) {
            width: 350px;
            height: 350px;
          }
        }

        &_reversed {
          flex-direction: row-reverse;

          @include breakpoint(tablet) {
            flex-direction: column-reverse;
            margin-top: 20px;
          }
        }
        
        &_mid {
          gap: 16px;
          border-radius: 12px;
          background: #fff;
          padding: 20px 25px;
          max-width: 640px;
          width: 100%;

          @include breakpoint(desktop) {
            padding: 0px 50px;
          }

          @include breakpoint(mobile) {
            padding: 0px 20px;
          }

          &_learn {
            @extend %flex-center;

            button {
              text-transform: none !important;
            }
          }

          &_title {
            color: $dark_azure !important;
            font-size: 35px !important;
            font-weight: 500 !important;
            line-height: normal !important;
            letter-spacing: 0.088px !important;
            font-family: $circularBold !important;
            margin-bottom: 16px;

            @include breakpoint(desktop) {
              font-size: 25px !important;
            }
          }

          &_subtitle {
            margin-bottom: 16px;
            font-size: 25px !important;
            font-weight: 300 !important;
            line-height: 35px !important;
            font-style: normal !important;
            color: $obsidianAura !important;
            font-family: $circularRegular !important;

            @include breakpoint(desktop) {
              font-size: 18px !important;
            }

            @include breakpoint(tablet) {
              font-size: 14px !important;
              line-height: normal !important;
            }
          }
        }

        &_left {
          gap: 16px;
          border-radius: 12px;
          background: #fff;
          padding: 20px 25px;
          max-width: 640px;
          width: 100%;

          @include breakpoint(desktop) {
            padding: 0px 50px;
          }

          @include breakpoint(mobile) {
            padding: 0px 20px;
          }

          &_learn {
            @extend %flex-end;

            button {
              text-transform: none !important;
            }
          }

          &_title {
            color: $dark_azure !important;
            font-size: 35px !important;
            font-weight: 500 !important;
            line-height: normal !important;
            letter-spacing: 0.088px !important;
            font-family: $circularBold !important;
            margin-bottom: 16px;

            @include breakpoint(desktop) {
              font-size: 25px !important;
            }
          }

          &_subtitle {
            margin-bottom: 16px;
            font-size: 25px !important;
            font-weight: 300 !important;
            line-height: 35px !important;
            font-style: normal !important;
            color: $obsidianAura !important;
            font-family: $circularRegular !important;

            @include breakpoint(desktop) {
              font-size: 18px !important;
            }

            @include breakpoint(tablet) {
              font-size: 14px !important;
              line-height: normal !important;
            }
          }
        }
      }
    }
  }
}

.slider {
  height: 400px !important;

  @include breakpoint(tablet) {
    height: 250px !important;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal {
  position: relative;
  background: #fff;
  width: 60%;
  height: 60%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayer {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #000;
  cursor: pointer;
}
