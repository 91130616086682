@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 4px;

  &__title {
    @extend %Circular-Std-14-reg;
    text-transform: capitalize;
  }

  &__subtitle {
    @extend %Circular-Std-14-reg;
  }
}
