@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  padding: 20px;
  background-color: $white;
  height: 100dvh;

  &__title {
    @extend %Circular-Std-16-regular;
    text-align: center;
    margin-bottom: 62px;
    font-size: 49px;
    line-height: normal;
    margin-top: 27px;

    @include breakpoint(tablet) {
      font-size: 30px;
    }
  }

  &__main {
    &__content {
      display: grid;
      grid-template-columns: repeat(2, 200px);
      justify-content: center;
      gap: 116px;

      @include breakpoint(tablet) {
        gap: 75px;
      }

      @include breakpoint(tablet-m) {
        gap: 40px;
        grid-template-columns: repeat(1, 200px);
      }

      &__card {
        @extend %flex-column-between;
        @extend %Circular-Std-14;
        align-items: center;
        gap: 5px;
        width: 200px;
        border-radius: 10px;
        color: black;
        cursor: pointer;
        padding: 8px;
        transition: 0.4s;
        background-color: $sapphire;

        svg {
          width: 120px;
        }

        &_disable {
          opacity: 0.5;
          cursor: auto;

          &:hover {
            transform: unset !important;
          }
        }

        &:hover {
          transform: translate3d(0, 5px, 0);
        }

        &__content {
          @extend %text-ellipsis;
          font-family: $circularRegular;
          color: white;
          font-size: 18px;
          max-width: 100%;
        }

        &__head {
          @extend %flex-start;
        }
      }
    }
  }
}
