@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  @extend %Circular-Std-14-reg;
  width: 100%;
  margin: 20px 0;
}
