@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center-column;
  text-align: center;

  &_bottom {
    margin-bottom: 120px;
  }

  &__title {
    @extend %Circular-Std-49;
    @extend %text-ellipsis;
    max-width: 100%;
    font-family: 'CircularStdBold' !important;

    @include breakpoint(desktop) {
      font-size: 36px;
    }
    @include breakpoint(tablet-l) {
      font-size: 28px;
    }

    @include breakpoint(tablet-m) {
      font-size: 35px !important;
    }

    &__subtitle {
      @extend %Circular-Std-21;
      @extend %multiple-ellipsis-three-lines;
      width: 100%;
      max-width: 100%;
      color: $deepCharcoalBlue !important;

      &_small {
        max-width: 460px;
      }
      @include breakpoint(tablet-l) {
        font-size: 18px;
      }

      &_light {
        color: $shadowSlate !important;
      }

      @include breakpoint(desktop) {
        font-size: 16px;
      }

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }
  }
}
