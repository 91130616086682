@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  min-height: 100dvh;
  position: relative;

  &__users {
    @extend %flex-between;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-evenly;

    @include breakpoint(tablet-l) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 300px));
    }

    @include breakpoint(tablet) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  &__new {
    max-width: 1512px;
    margin: 0 auto;
    padding-inline: 24px;
  }

  &__bottom {
    @extend %flex-center;
    border-radius: 16px;
    border-top: 6px solid $sapphire;
    border-bottom: 6px solid $sapphire;

    margin-bottom: 48px;
  }

  &__booking {
    width: 100%;
    max-width: 680px;
    margin: 0 auto;

    &__container {
      padding: 0;
      width: 100%;
      padding-block: 20px;
    }

    &__title {
      text-align: center;
      font-size: 49px;
      color: $black !important;
    }

    &__box {
      @extend %flex-column;
      gap: 16px;
      width: 100%;
    }
  }

  &__back {
    position: fixed;
    right: 100px;
    opacity: 0;
    z-index: 9999;
    visibility: hidden;
    cursor: pointer;
    top: calc(100vh - 100px);
    transition: opacity 0.3s, visibility 0.3s;

    @include breakpoint(tablet) {
      right: 50px;
      top: calc(100dvh - 110px);
    }

    @include breakpoint(mobile-m) {
      right: 50px;
      top: unset;
      top: calc(100dvh - 70px);
    }

    &__new {
      @extend %flex-center;

      background: hsla(0, 0%, 100%, 0.3);
      backdrop-filter: saturate(180%) blur(5px);
      box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      padding: 10px;

      svg {
        fill: black;
        path {
          fill: black;
        }
      }
    }

    &_active {
      opacity: 1;
      visibility: visible;
    }
  }

  &__main {
    flex: 1 1 auto;
  }

  &_content {
    max-width: 1512px;
    margin: 0 auto;

    padding: 10px 0;

    @media (max-width: 1680px) {
      padding: 10px 80px;
      max-width: 100%;
    }

    @include breakpoint(wide) {
      margin: 10px auto;
      padding: 10px 30px;
      max-width: 100%;
    }

    &_about {
      max-width: 100%;
      padding: 0;
    }

    &_margin {
      width: 100%;
      max-width: unset;
      margin: unset;
      padding: unset;
    }
  }
}
