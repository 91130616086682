@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
// .swiper-slide img {
//   object-fit: contain !important;
// }

.small-container {
  width: 100%;
  max-width: 700px;
  max-height: 313px;

  @include breakpoint(tablet-l) {
    max-width: 500px;
  }

  @include breakpoint(tablet-m) {
    max-width: 370px;
    height: 200px;
  }
}

.container-box {
  position: relative;
  max-height: 313px;

  @include breakpoint(tablet-m) {
    max-height: 200px;
  }
}

.box {
  max-height: 313px;

  @include breakpoint(tablet-m) {
    max-height: 200px;
  }
}

.container-box .arrow {
  position: absolute;
  top: 45%;
  z-index: 999;
  padding: 0;
  height: 44px;
  width: 44px;
  outline: none;
  margin-top: -5px;
  background-color: transparent;
}

.container-box .arrow.arrow-left {
  left: 10px;
}

.container-box .arrow.arrow-right {
  right: 10px;
}

.remove__media__layout {
  @extend %flex-center;
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 10;
  top: 20px;
  left: 20px;
  cursor: pointer;
  border-radius: 50%;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: overlay, normal;

  svg {
    fill: white;
  }

  &:hover {
    svg {
      fill: red;
    }
  }
}

video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
