@import '../../../../../../styles/vars.scss';
@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/fonts.scss';
.container {
  margin-top: 24px;
  margin-bottom: 32px;

  &__content {
    display: grid;
    gap: 24px;
    justify-content: center;
    grid-template-columns: repeat(2, 1fr);

    @include breakpoint(tablet-l) {
      grid-template-columns: none;
      justify-content: unset;
    }

    &__file {
      &__content {
        max-width: 354px;
      }
    }
  }
}
