@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    gap: 20px;
    background: $whispering_gray;
  }

  &__head {
    @extend %flex-between;
    margin-top: 40px;
    margin-bottom: 20px;

    &__title {
      @extend %Circular-Std-25-bold;
      color: black !important;
    }
  }

  &__content {
    display: flex;
    gap: 20px;
    margin-top: 30px;

    &__item {
      width: 20%;

      // &:nth-child(1) {
      //   grid-column: span 3;
      // }

      // &:nth-child(2) {
      //   grid-column: span 3;
      // }
    }

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__submit {
      @extend %flex-center;
      text-transform: capitalize;
      margin-top: 16px;
      width: 100%;
    }
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
