@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.vds-slider {
  /* CSS variables available for customization (defaults shown). */
  --media-slider-width: 100%;
  --media-slider-height: 15px;

  --media-slider-track-width: 100%;
  --media-slider-track-height: 15px;
  --media-slider-track-bg: rgb(255 255 255 / 0.3);
  --media-slider-track-fill-bg: var(--media-brand, #2067dd);
  --media-slider-track-progress-bg: #999;
  --media-slider-track-border-radius: 5px;
  --media-slider-focused-track-width: var(--media-slider-track-width);
  --media-slider-focused-track-height: calc(var(--media-slider-track-height) * 1.25);

  --media-slider-thumb-size: 15px;
  --media-slider-thumb-border: 1px solid #cacaca;
  --media-slider-thumb-border-radius: 9999px;
  --media-slider-thumb-bg: #fff;
  --media-slider-thumb-transition: opacity 0.2s ease-in, box-shadow 0.2s ease;
  --media-slider-focused-thumb-size: calc(var(--media-slider-thumb-size) * 1.1);
  --media-slider-focused-thumb-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.4);
}

.vds-time {
  font-family: $circularRegular !important;
}
