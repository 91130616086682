@import '../../../../../../styles/vars.scss';
@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/fonts.scss';
.container {
  margin-bottom: 32px;

  &__content {
    @extend %flex-between;
    gap: 24px;
    margin-bottom: 12px;

    @include breakpoint(tablet-l) {
      gap: 10px;
    }
  }

  &__footer {
    margin-top: 16px;
  }
}
