@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  max-width: 780px;
  background: #fff;
  border-radius: 5px;
  border: 1px solid $subtle_blue;

  @media (min-width: $large-d) {
    max-width: 760px;
  }

  @include breakpoint(tablet-l) {
    border-radius: 0;
    border: none;

    max-width: 100%;
    margin-bottom: 12px;
  }

  &__gallery {
    @extend %flex-center;
    position: absolute;
    cursor: pointer;
    top: 10px;
    left: 10px;
    width: 40px;
    height: 40px;
    z-index: 10;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.7);
  }

  &_content {
    &_header {
      padding: 24px 24px 0 24px;

      &__text {
        margin: 16px 0;
      }

      @include breakpoint(tablet-l) {
        padding: 8px 20px;
      }

      &_typo {
        &_name {
          color: $midnightBlack !important;
          font-family: $circularRegular !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          letter-spacing: 0.08px !important;
        }
        &_role {
          color: $graphiteGray !important;
          font-family: $circularLight !important;
          font-size: 14px !important;
          font-weight: 300 !important;
          letter-spacing: 0.035px !important;
        }
        &_hour {
          color: $silverSlate !important;
          font-family: $circularLight !important;
          font-size: 12px !important;
          font-weight: 500 !important;
          letter-spacing: 0.048px !important;
        }

        &_team {
          padding: 4px;
          color: $sapphire !important;
          font-family: $circularLight !important;
          font-size: 12px !important;
          font-weight: 300 !important;
          letter-spacing: 0.048px !important ;
        }
      }

      &_block {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        &_nested {
          display: flex;
          gap: 16px;
        }
      }

      &_image {
        display: flex;
        align-items: center;

        &_icon {
          border-radius: 4px;
        }
      }
    }
    &_emoji {
      position: absolute;
      left: 563px;
      bottom: -38px;
    }

    &_body {
      height: 313px;
      max-width: 100%;
      width: 100%;
      margin-bottom: 16px;

      @media (min-width: $large-d) {
        max-width: 760px;
      }

      @include breakpoint(tablet-l) {
        max-width: 100%;
      }

      @include breakpoint(tablet-m) {
        height: 167px;
      }

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
      }
    }

    &_footer {
      padding: 0 24px 24px 24px;
      margin-top: 20px;

      @include breakpoint(tablet-l) {
        margin-top: 12px;
      }

      &_block {
        @extend %flex-between-end;
        padding-bottom: 16px;

        &_like {
          display: flex;
          align-items: center;
          gap: 4px;

          p {
            color: $staleGray !important ;
            font-family: $circularRegular !important ;
            font-size: 14px !important ;
            font-weight: 500 !important ;
            letter-spacing: 0.175px !important ;
          }
        }

        &_count {
          display: flex;
          align-items: center;
          cursor: pointer;

          p {
            color: $dusk_slate !important ;
            font-family: $circularRegular !important ;
            font-size: 14px !important ;
            font-weight: 500 !important ;
            letter-spacing: 0.035px !important ;
          }

          &_likes {
            cursor: default;
            @include breakpoint(mobile-m) {
              display: none;
            }
          }
        }

        &_divider {
          background-color: $staleGray;
          margin: 0 16px;
          width: 1px;
          height: 11px;

          @include breakpoint(mobile-m) {
            display: none;
          }
        }

        &_comment {
          margin-top: 16px;
          position: relative;

          &_scroll {
            @extend %scrollbar-gray;
            max-height: 300px;
            overflow: auto;
            padding-bottom: 10px;
            padding-right: 10px;

            &::-webkit-scrollbar {
              display: none;
            }
          }

          &_all {
            @extend %flex-align;
            gap: 8px;
            font-size: 14px;
            font-family: $circularRegular;
            color: $gunmetalGray;
            padding: 10px 0;
            letter-spacing: 0.035px;

            svg {
              cursor: pointer;
              transition: 0.2s;

              &:hover {
                fill: $sapphire;
              }
            }
          }

          &_box {
            display: flex;
            align-items: center;
            gap: 27px;
            position: relative;

            &_icon {
              border-radius: 50%;
              border: 3px solid #e1f1f4;
            }
          }
        }
      }
    }
  }
  &_divider {
    margin: 24px 0;
  }

  &_image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      filter: blur(4px);
      background-size: cover;
      background-position: center;
    }
  }
}

.dropdown {
  @extend %flex-column;
  gap: 21px;
  padding: 24px;
  border-radius: 10px;
  background: $snowWhite;

  border: 1px solid $subtle_blue;

  &_item {
    @extend %flex-align;
    @extend %Circular-Std-14-reg;
    gap: 8px;
    cursor: pointer;

    &:hover {
      color: $sapphire;
    }
  }
}
