@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column-between;
  width: 100%;
  padding: 0 24px;
  position: relative;
  gap: 24px;

  &__title {
    font-size: 16px !important;
    color: $midnightBlack !important;
    padding-top: 4px;
    max-width: 90%;
    font-weight: 400;
    color: inherit;
    word-break: break-word;
  }

  &__subtitle {
    @extend %Circular-Std-14;
    color: $staleGray !important;
  }

  &__header {
    padding: 24px;
    margin-bottom: 0 !important;
    position: sticky;
    top: 0;
    z-index: 999;
    background: #fff;

    &__title {
      width: 100%;
      font-size: 16px !important;
      font-family: $circularBold;
    }
  }

  &__users {
    @extend %flex-align;
    gap: 16px;

    &__name {
      @extend %text-ellipsis;
      @extend %Circular-Std-16-regular;
      color: $midnightBlack !important;
    }
  }

  &__date {
    @extend %Circular-Std-14;
    color: $shadowed-slate !important;
  }

  &__count {
    @extend %Circular-Std-14;
    color: $staleGray !important;
    border-bottom: 0.5px dashed #ccc;
    padding-bottom: 8px;
  }
}
