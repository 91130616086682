@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 22px;

  @include breakpoint(tablet-l) {
    padding: 0 20px;
    background-color: #f8f9fc;
  }

  &__icon {
    margin: 0 auto;
  }

  &__footer {
    @extend %flex-end;
    padding: 10px 0;
  }

  &__status {
    @extend %flex-center;
    border-radius: 5px;
    padding: 4px;
    width: 65px;

    &__title {
      @extend %flex-align;
      @extend %Circular-Std-12-light;
      gap: 4px;
      text-transform: capitalize;

      &__dot {
        width: 8px;
        min-width: 8px;

        height: 8px;
        border-radius: 50%;
        background-color: #667085;
      }
    }
  }

  &__loading {
    @extend %flex-center;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
