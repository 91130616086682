@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 800px;
  width: 100%;
  background-color: white;
  transform: translate(-50%, -50%);
  border-radius: 10px;

  &__close {
    position: absolute;
    right: 10px;
    top: 9px;
    cursor: pointer;
    z-index: 1;
  }

  &__time {
    @extend %Circular-Std-12-light;
    position: absolute;
    left: 0;
    right: -600px;
    top: 11.5px;
    margin: auto;
    width: fit-content;
    height: fit-content;
    color: $silverSlate;

    @include breakpoint(desktop) {
      right: -400px;
    }
  }

  @include breakpoint(desktop) {
    max-width: 600px;
  }

  &_title {
    @extend %flex-column;
    margin-bottom: 40px;
    position: absolute;
    width: 100%;
    top: 40px;

    &:hover &__warning {
      background-color: $snowWhite;
    }

    &__warning {
      @extend %Circular-Std-12-light;
      padding: 8px;
      position: absolute;
      bottom: -40px;
      max-width: fit-content;
      background-color: $snowWhite;
      color: red !important;
      margin-left: 120px;
      z-index: 7 !important;
      border: 1px solid #eee;
      box-shadow: 0 0 15px #e2e2e2;

      &__icon {
        transform: rotate(180deg);
        position: absolute;
        top: -10px;
        right: 0;
      }
    }

    div {
      border-radius: 0 !important;
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin: 0 20px 20px 0;

    &_cancel {
      color: $gray;
    }

    &_submit {
      padding: 11px;
    }
  }

  &_header {
    @extend %flex-between;
    padding: 24px;
    padding: 24px;
    color: $black;
    font-size: 25px;
    font-family: $circularBold;
    background: rgb(250, 250, 250);
    border-radius: 10px;
    border-bottom: 0.5px solid rgb(204, 204, 204);

    svg {
      cursor: pointer;
    }
  }
}

.textarea {
  textarea {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
