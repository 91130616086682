@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  max-width: max-content;
  width: 100%;
  padding: 20px;
  cursor: auto;
  background: $snowWhite;
  border-radius: 10px;
  border: 0.5px dashed $gray;
  box-shadow: 0px 4px 10px 0px $almost_invisible_black, 0px 4px 10px 0px $almost_invisible_black;

  &__box {
    @extend %flex-align;
    gap: 8px;
    cursor: pointer;
    padding: 4px;
    background-color: transparent;

    &:not(:last-child) {
      margin-bottom: 19px;
    }

    &_text {
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
      letter-spacing: 0.035px;
      color: $semi_transparent_black;

      &:hover {
        color: $sapphire;
      }
    }
  }
}
