@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  min-height: 100dvh;

  &__comments {
    display: flex;
    padding: 8px 24px;
    gap: 16px;

    &__media {
      width: 272px;
      height: 173px;
      border-radius: 10px;
      object-fit: fill;
    }

    &__content {
      @extend %flex-column;
      gap: 20px;

      &__arrow {
        &_active {
          transform: rotate(180deg);
        }
      }

      &__text {
        @extend %Circular-Std-14-light;
        color: $shadowed-slate !important;
      }
    }

    &__first {
      background: $pureWhite;
      margin-top: 32px;

      .container__comments__content__text {
        @extend %Circular-Std-14-reg;
      }
    }

    &__text {
      @extend %Circular-Std-14-light;
      padding-left: 33px;
      color: $shadowed-slate !important;
    }

    &__files {
      @extend %flex-align;
      gap: 10px;

      &__text {
        @extend %Circular-Std-14;
        padding-left: 33px;
        color: $shadowed-slate !important;
      }

      &__video {
        padding-left: 33px;
      }

      &__select {
        @extend %flex-center;
        border-radius: 5px;
        gap: 4px;
        cursor: pointer;
        padding: 2px 4px;
        background-color: $shadowed-slate;

        &__text {
          @extend %Circular-Std-12-reg;
          color: $shadowed-slate !important;
        }

        &__arrow {
          transform: rotate(180deg) !important;
          transition: 0.5s;

          &__extended {
            transform: rotate(0deg) !important;
          }
        }
      }
    }

    &__screenshots {
      @extend %flex-align-start;
      padding-left: 33px;
      gap: 24px;

      @include breakpoint(tablet-l) {
        overflow-x: scroll;
      }

      &__image {
        max-width: 242px;
        width: 100%;
        height: 173px;
        border-radius: 5px;

        @include breakpoint(desktop) {
          max-width: 186px;
        }
      }
    }
  }

  &__head {
    @extend %flex-between;
    padding: 8px 40px;
    border: 0.5px dashed $paleSilver;
    background: $pureWhite;

    &__content {
      text-align: center;
      flex: 1;
    }
  }

  &__wrapper {
    padding: 8px;
  }

  &__messages {
    @extend %flex-column;
    @extend %scrollbar-gray;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100dvh - 350px);

    padding: 16px 24px;
  }

  &__main {
    position: relative;
    padding: 20px 40px;
    max-height: 500px;
    background-color: $platinumGray;
  }

  &_quill {
    gap: 0;
  }

  &__close {
    cursor: pointer;
  }

  &__time {
    @extend %Circular-Std-12-light;

    color: $silverSlate !important;

    @include breakpoint(tablet) {
      display: none;
    }
  }

  &_title {
    @extend %flex-center;
    margin-bottom: 40px;
    position: absolute;
    width: 100%;
    top: 40px;

    div {
      border-radius: 0 !important;
    }
  }

  &__images {
    @extend %flex-align;
    gap: 10px;
    height: 50px;
    width: 100%;
    margin: 10px 0;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    img {
      width: 80px;
      height: 50px;
      border-radius: 10px;
    }
  }

  &_footer {
    @extend %flex-end;
    gap: 8px;
    background-color: $platinumGray;

    &__next {
      @include breakpoint(tablet-m) {
        display: flex;
        flex-direction: row !important;
      }

      &__cancel {
        @include breakpoint(tablet-m) {
          display: none;
        }
      }
    }

    &_cancel {
      color: $gray;
    }

    &_submit {
      padding: 11px;

      &_cam {
        padding: 11px;
        @include breakpoint(tablet-m) {
          padding: 14px;
        }
      }
    }
  }
}

.textarea {
  textarea {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
