@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 32px;

  &_content {
    gap: 20px;
    row-gap: 28px;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    @include breakpoint(desktop) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(tablet-l) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      background: $whispering_gray;
      padding: 0 24px 24px 24px;
    }
  }

  &_footer {
    @extend %flex-end;
    padding: 20px 0;

    @include breakpoint(tablet-l) {
      padding: 0 24px;
      background: $whispering_gray;
    }
  }

  &_header {
    @extend %flex-column;
    gap: 12px;

    @include breakpoint(desktop) {
      padding: 0 24px;
    }

    &_title {
      color: $midnightBlack !important;
      @extend %Circular-Std-25-bold;
    }

    &__content {
      @extend %flex-between;

      &__search {
        max-width: 233px;

        @include breakpoint(tablet-m) {
          max-width: 200px;
        }
      }

      &_button {
        gap: 8px;
        @extend %flex-center;
        padding: 8px 15px;
        border-radius: 5px;
        text-transform: capitalize;

        span {
          margin: 0;
        }

        @include breakpoint(tablet-m) {
          padding: 8px;
          min-width: 50px;
        }

        @include breakpoint(mobile-m) {
          min-width: 30px;
        }

        &_text {
          @include breakpoint(tablet) {
            font-size: 12px;
          }
        }

        svg {
          width: 24px;
          height: 24px;

          @include breakpoint(tablet) {
            width: 20px;
            height: 20px;
          }

          @include breakpoint(tablet-m) {
            width: 24px;
            height: 24px;
            margin-right: 0 !important;
            margin-left: 0 !important;
          }

          path {
            stroke: $white;
          }
        }
      }
    }
  }

  &_loading {
    @extend %flex-center;
  }
}
