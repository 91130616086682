@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  @extend %flex-between;
  align-items: flex-end;

  &__icon {
    margin: 0 auto;
    width: 16px;
    height: 16px;

    path {
      fill: $dusk_slate;
    }
  }

  @include breakpoint(tablet-l) {
    padding: 0 20px;
    background: $whispering_gray;
  }

  &__main {
    @extend %flex-column;
    gap: 40px;
    width: 100%;

    &__footer {
      @extend %flex-between;

      @include breakpoint(tablet) {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: flex-end;
      }

      &__coupons {
        @extend %flex-center;
        padding: 22px 20px;
        gap: 12px;
        border-radius: 16px;
        border: 4px solid $paleSilver;

        @include breakpoint(mobile-m) {
          display: flex;
          flex-direction: column;
        }

        &__apply {
          @extend %Circular-Std-14-boldMax;
          min-width: 130px;
          padding: 12px 16px;
          border-radius: 4px;
          background: $sapphire;
          text-transform: capitalize;
          color: $snowWhite;

          &:hover {
            background: #1d61cd;
          }
        }
      }
    }

    &__content {
      @extend %flex-column;
      gap: 24px;

      &__section {
        &__save {
          @extend %flex-end;
          span {
            text-transform: capitalize;
          }
        }
      }

      &__title {
        @extend %flex-align;
        @extend %Circular-Std-25;
        gap: 24px;
        margin-left: 16px;

        &__icon {
          transform: rotate(0deg);

          &__open {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__header {
      @extend %flex-column;
      gap: 40px;

      &__margin {
        @extend %flex-end;
        &__button {
          @extend %Circular-Std-14;
          text-transform: capitalize;
          max-height: 39px;
          max-width: 107px;
          border-radius: 5px;
          border: 0.5px dashed $light_steel_gray;
        }
      }

      &__info {
        @extend %flex-column;
        gap: 8px;
      }

      &__title {
        @extend %Circular-Std-25-bold;
        color: $sapphire !important;
      }

      &__subtitle {
        @extend %Circular-Std-16-med;
        color: $shadowed-slate !important;
      }
    }

    &__total {
      @extend %flex-column-end;
      gap: 16px;

      &__subtitle {
        @extend %Circular-Std-21-medium;
        color: $dusk_slate !important;
      }

      &__title {
        @extend %Circular-Std-35-bold;

        @include breakpoint(tablet) {
          font-size: 25px;
        }
      }
    }

    &__content {
      @extend %flex-column;
      gap: 16px;
      margin-bottom: 24px;
      width: 100%;

      &:last-child {
        gap: 0;
      }

      &__item {
        @extend %flex-align;
        @extend %Circular-Std-25-reg;
        gap: 24px;
        padding: 16px;
        background: #fff;
        border-bottom: 0.5px solid $subtle_blue;
        cursor: pointer;

        &__icon {
          transform: rotate(0deg);

          &__open {
            transform: rotate(180deg);
          }
        }

        &__table {
          display: none;
          padding: 48px 16px;
          border: 0.5px solid #ccc;
          background: rgba(251, 252, 254, 0);
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset !important;

          &__open {
            display: block;
          }
        }
      }

      &__schedule_item {
        background: #fff;
        border-bottom: 0.5px solid $subtle_blue;
        padding: 0 16px;

        &__content {
          @extend %flex-between;
          cursor: pointer;

          &__title {
            @extend %flex-align;
            @extend %Circular-Std-25-reg;
            gap: 24px;
            padding: 16px 0;

            &__icon {
              transform: rotate(0deg);

              &__open {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &__schedule {
        gap: 24px;
        display: none;

        &__open {
          display: flex !important;
          padding: 46px 16px;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15) inset;
        }

        &__item {
          @extend %flex-column;
          gap: 24px;
          width: 100%;
        }
      }

      &__child {
        border-radius: 0px 0px 5px 5px;
        border: 0.5px solid #ccc;
        background: #fff;
        box-shadow: 0px 4px 4px 0px rgba(34, 34, 34, 0.15) inset;

        &__title {
          @extend %Circular-Std-16-regular;
          color: $staleGray !important;
          padding: 32px 40px 8px;
        }

        &__items {
          border-top: 1px dashed #ccc;
          padding: 32px 40px;

          &__footer {
            @extend %flex-between;

            &__total {
              @extend %Circular-Std-21;
              margin-top: 64px;
            }
          }
        }
      }
    }
    &__add {
      position: relative;

      &__content {
        @extend %flex-align;
        gap: 4px;
        max-width: fit-content;
        cursor: pointer;

        &__text {
          @extend %Circular-Std-14;
        }
      }
    }
  }

  &__generate {
    margin-top: 70px;

    @include breakpoint(tablet-l) {
      padding: 20px 0;
    }
    &__button {
      @extend %Circular-Std-14-med;
      padding: 21.5px 16px;
      border-radius: 10px;
      background-color: $sapphire;
      color: $snowWhite;

      &:disabled {
        color: $white !important;
      }

      &:hover {
        background-color: $deep_cerulean_blue;
      }
    }
  }
}
