@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  padding: 20px;
  max-width: 1040px;

  &__content {
    @extend %flex-column;
    gap: 16px;

    @include breakpoint(desktop) {
      gap: 15px;
    }

    &__form {
      @include breakpoint(tablet-m) {
        padding-bottom: 60px;
        border-bottom: 1px dashed #ccc;
      }
    }
  }

  &__card {
    @extend %flex-column-between;
    gap: 16px;
    margin-top: 8px;

    &__bottom {
      border-radius: 10px;
      padding: 16.5px 14px;
      background-color: $platinumGray;
    }
  }

  &__address {
    margin-top: 8px;

    @include breakpoint(tablet-m) {
      gap: 16px;
      width: 100%;
      display: grid;
      margin-top: 15px;
      grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
    }
  }
}
