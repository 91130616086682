@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {

  &__content {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 50px;

    &__create__button{
      margin-right: 10px;
      border-radius: 3px;
      text-transform: none;
      font-size: inherit; /* Reset font size to inherit from parent */
      width: 150px; /* Adjust the width as needed */
      height: 35px;
    }

  }
}
