@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;
  background-color: $deep_cerulean;
  border-radius: 0px 16px 16px 16px;
  font-family: $circularBold;
  color: $white;
  line-height: 20px;
  padding: 8px;
  max-width: 149px;

  &__title {
    @extend %text-ellipsis;
  }

  &__right {
    border-radius: 16px 0 16px 16px;

    .container__icon {
      left: unset;
      right: -19px;
    }
  }

  &__icon {
    position: absolute;
    left: -19px;
    top: 0;
  }
}
