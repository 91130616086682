@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  width: 100%;
  height: 50px;
  margin: 0 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;

  img {
    max-width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
