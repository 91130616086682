@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;

  @include breakpoint(tablet-l) {
    padding: 0 24px 24px 24px;
    background: $whispering_gray;
  }

  &__footer {
    @extend %flex-end;
    padding: 20px 0;
  }

  &__icon {
    margin: 0 auto;

    svg {
      path {
        fill: #282828;
      }
    }
  }

  &__receive {
    transform: rotate(-90deg);
  }

  &__send {
    transform: rotate(90deg);
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
