@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  background-color: transparent;

  &:focus {
    background-color: transparent;
  }
}
