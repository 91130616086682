@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 5px;

  &__text {
    @extend %Circular-Std-12-reg;
    @extend %flex-align;
    gap: 5px;
    color: $dusk_slate;

    &__name {
      @extend %text-ellipsis;
      @extend %Circular-Std-12-reg;
    }
  }
}

.new__test {
  background-color: transparent;
  padding: 10px !important;
}
