@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 24px 32px;
  background-color: $midnight_slate;

  @include breakpoint(tablet) {
    text-align: center;
  }

  @include breakpoint(tablet) {
    padding: 25px;
  }

  &_content {
    max-width: 1512px;
    margin: 0 auto;

    @media (max-width: 1680px) {
      max-width: 100%;
    }

    @include breakpoint(tablet) {
      padding: 0;
    }

    &_box {
      display: flex;
      margin-bottom: 100px;
      justify-content: space-between;

      &_reversed {
        flex-direction: row-reverse;
      }

      @include breakpoint(wide) {
        gap: 25px;
        align-items: center;
        flex-direction: column;
      }

      @include breakpoint(tablet) {
        margin-bottom: 30px;
      }
    }

    &_news {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      @include breakpoint(wide) {
        width: 100%;
      }

      p {
        text-align: center;
      }
    }

    &_links {
      @extend %flex-justify-between;

      gap: 100px;
      width: 100%;

      @include breakpoint(wide) {
        gap: 50px;
      }

      @include breakpoint(tablet-l) {
        text-align: center;
      }

      @include breakpoint(mobile) {
        flex-wrap: wrap;
        gap: 40px 80px;
        justify-content: space-around;
      }

      &_title {
        color: $snowWhite !important;
        font-family: $circularBoldMax !important;
        font-size: 16px $circularRegular !important;
        font-weight: 700 $circularRegular !important;
        letter-spacing: 0.08px $circularRegular !important;
        margin-bottom: 18px;
      }

      &_link {
        margin: 8px 0;
        color: $snowWhite !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        letter-spacing: 0.035px !important;
      }
    }
    &_news {
      &_title {
        width: 100%;
        max-width: 222px;
        margin-bottom: 8px;
        color: $snowWhite !important;
        font-size: 16px !important;
        font-weight: 500 !important;
        letter-spacing: 0.035px !important ;
        font-family: $circularRegular !important;
      }

      &_subtitle {
        width: 100%;
        max-width: 268px;
        margin-bottom: 8px;
        color: $snowWhite !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        letter-spacing: 0.035px !important ;
        font-family: $circularRegular !important;
      }

      &_icon {
        svg {
          background-color: $sapphire;
          border-radius: 10px;
          width: 33px;
          height: 30px;
        }
      }
    }

    &_foot {
      @extend %flex-between;

      @include breakpoint(tablet-m) {
        flex-direction: column;
        gap: 30px;
      }

      &_first {
        display: flex;
        align-items: center;
        gap: 80px;

        @include breakpoint(tablet) {
          gap: 25px;
        }

        @include breakpoint(tablet-m) {
          flex-direction: column;
        }

        &_logo {
          cursor: pointer;
          background-color: transparent;

          &:focus {
            background-color: transparent;
          }
        }
      }

      &_round {
        display: flex;
        flex-direction: row;
        gap: 16px;

        p {
          color: $snowWhite !important;
          font-family: $circularRegular !important;
          font-size: 12px !important;
          font-weight: 300 !important;
          letter-spacing: 0.048px !important;
        }
      }
      &_social {
        @extend %flex-align;
        gap: 8px;
      }
    }
  }
}
