@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 20px;

  &__title {
    @extend %Circular-Std-25-light;
    width: 100%;
    text-align: center;
  }

  &__head {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    &__box {
      max-width: 100%;
      gap: 8px;
      height: 109px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 4px;
  }

  &__submit {
    @extend %flex-center;
    margin: 20px 0 40px 0;
    width: 100%;
  }
}
