@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  &__free {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  &_content {
    margin: 40px 0;

    @include breakpoint(wide) {
      margin: 30px 0;
    }

    &_plan {
      @extend %flex-center;
      text-align: center;
      flex-direction: column;
      gap: 4px;

      @include breakpoint(wide) {
        gap: 30px;
      }

      &_pricing {
        display: flex;
        gap: 40px;

        @include breakpoint(wide) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
    }
  }

  &_rect {
    @extend %flex-center;
    background: white;
    border-radius: 5px;
    border: 0.5px dashed #ccc;
    padding: 4px;
    gap: 4px;

    &_monthly {
      min-width: 45px;
      padding: 4px;

      border-radius: 5px;
      text-transform: none;
      line-height: normal;
      color: $midnightBlack !important;
      font-weight: 450 !important;
      letter-spacing: 0.032px !important;
      font-family: $circularRegular !important;

      span {
        font-size: 12px !important;
      }
    }

    &_yearly {
      min-width: 45px;
      border-radius: 5px;
      padding: 4px;
      color: $snowWhite !important;
      font-size: 12px !important;

      text-transform: none;

      font-weight: 450 !important;
      letter-spacing: 0.032px !important;
      background-color: $sapphire !important;
      font-family: $circularRegular !important;

      span {
        font-size: 12px !important;
      }
    }
  }

  &_faq {
    display: flex;
    align-items: center;
    flex-direction: column;

    &_wrapper {
      gap: 24px;
      display: flex;
      padding: 40px;
      margin-bottom: 100px;
      flex-direction: column;
      background-color: $gentle_lavender;

      @include breakpoint(tablet-l) {
        padding: 15px;
      }
    }

    &_data {
      gap: 20px;
      display: flex;
    }
  }
}

.reputation {
  background: $gentle_lavender;
  margin-bottom: 150px;

  &_content {
    padding: 120px 161px;

    @include breakpoint(tablet-l) {
      padding: 60px;
    }

    @include breakpoint(mobile) {
      padding: 60px 25px;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint(tablet) {
        flex-direction: column;
      }

      &_info {
        max-width: 544px;
        width: 100%;

        @include breakpoint(tablet) {
          margin-bottom: 25px;
          text-align: center;
        }

        &_ai {
          color: $gray !important;
          font-family: $circularRegular !important;
          font-size: 16px !important;
          font-weight: 500 !important;
          line-height: normal !important;
          letter-spacing: 0.08px !important;
        }

        &_enhance {
          color: $midnightBlack !important;
          font-family: $circularRegular !important;
          font-size: 35px !important;
          font-weight: 500 !important;
          line-height: normal;
          letter-spacing: 0.088px !important;
          margin-bottom: 24px;
        }

        &_lorem {
          color: rgba(34, 34, 34, 0.8) !important;
          font-family: $circularLight !important;
          font-size: 21px !important;
          font-weight: 300 !important;
          line-height: normal !important;
        }
      }

      &_buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        &_request {
          border-radius: 5px !important;
          background: $sapphire !important;
          color: $snowWhite !important;
          font-family: $circularLight !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          letter-spacing: 0.175px !important;
          padding: 16px !important;
        }

        &_book {
          color: $sapphire !important;
          font-family: $circularRegular !important;
          font-size: 14px !important;
          font-weight: 500 !important;
          line-height: normal !important;
          letter-spacing: 0.175px !important;
          text-decoration-line: underline !important;
        }
      }
    }
  }
}
