@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;

  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
  }

  &__name {
    @extend %flex-align;
    gap: 8px;

    a {
      color: $sapphire;
      text-decoration: underline !important;
    }
  }

  &__icon {
    margin: 0 auto;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $dusk_slate;
      }
    }
  }
  &__cards {
    @extend %flex-column;
    gap: 23px;

    &__title {
      @extend %Circular-Std-16-regular;
    }

    &__content {
      column-gap: 20px;
      row-gap: 54px;
      display: grid;
      padding: 15px 16px;
      grid-template-columns: repeat(auto-fill, minmax(267px, 1fr));

      @include breakpoint(tablet-m) {
        padding: 0;
      }
    }
  }

  &__footer {
    @extend %flex-start;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
