@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  font-size: 14px;
  max-width: fit-content;
  overflow-x: scroll;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
