@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  width: 100%;

  &__plus {
    cursor: pointer;
  }

  &__form {
    @include breakpoint(tablet-l) {
      padding: 0;
    }
  }

  &__fields {
    @include breakpoint(tablet-l) {
      :first-child {
        padding: 24px 0;
      }

      padding: 53px 0;
    }
  }

  &__content {
    position: relative;
    margin-bottom: 28px;
    padding-top: 28px;
    max-width: 100%;
    border-top: 1px dotted #ccc;
    display: flex;
    gap: 32px;

    &__back {
      @extend %flex-column;
      gap: 24px;
      padding: 24px;
      border-radius: 10px;
      background: #fbfcfe;
      width: 100%;
    }

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    @include breakpoint(tablet-l) {
      padding-top: 52px;
    }

    &__items {
      @extend %flex-between;
      gap: 24px;
    }

    &__remove {
      cursor: pointer;
    }
  }
}
