@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &__scroll {
    max-width: 70%;
    overflow-y: auto;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__content {
      display: flex;
      min-width: fit-content;
    }
  }
}
