@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;

  &__header {
    @extend %flex-column;
    gap: 24px;
    width: 100%;

    &__content {
      @extend %flex-between;
      width: 100%;

      &__icon {
        @extend %flex-align;
        gap: 24px;
        cursor: pointer;
      }

      &__title {
        @extend %Circular-Std-25-bold;
      }
    }

    &__search {
      max-width: 233px;
    }
  }
}
