@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 20px;

  @include breakpoint(wide) {
    padding: 20px;
  }

  @include breakpoint(tablet-l) {
    border: none;
    padding: 24px 4px 20px 3px;
  }

  &__title {
    @extend %Circular-Std-25-bold;

    @include breakpoint(tablet-m) {
      font-size: 20px;
    }
  }
}
