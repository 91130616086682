@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  background-color: $snowWhite;
  padding: 18px 24px;

  &__title {
    @extend %Circular-Std-21-bold;
    margin-bottom: 19px;
  }
}
