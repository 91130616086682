@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  margin-top: 12px;

  &__content {
    @extend %flex-align;
    gap: 10px;

    &__main {
      @extend %flex-align;
      gap: 0;

      &__item {
        @extend %text-ellipsis;
        font-size: 14px;
        font-family: $circularRegular;
        color: $tableCellGray !important;

        &_detector {
          margin-right: 10px;
        }
      }
    }
  }
}
