@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &__plus {
    @extend %flex-align;
    gap: 8px;
    cursor: pointer;
    max-width: fit-content;

    &__text {
      @extend %Circular-Std-14-reg;
    }
  }

  svg {
    cursor: pointer;
  }
}
