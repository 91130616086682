@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column-between;
  gap: 16px;
  padding: 32px;
  background-color: white;

  &__content {
    @extend %flex-column;
    gap: 16px;

    max-height: calc(100dvh - 143px);
    padding: 10px 0;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    font-size: 25px;
    font-family: $circularBold;
  }

  &__footer {
    @extend %flex-end;
    gap: 12px;
  }
}
