@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;

  &__content {
    @extend %flex-column;
    gap: 24px;
    min-width: 140px;

    @include breakpoint(tablet) {
      width: 100%;
    }

    &__title {
      @extend %Circular-Std-16-light;
      color: rgba(34, 34, 34, 0.8) !important;
    }

    &__details {
      @extend %flex-start;
      gap: 32px;
      flex-wrap: wrap;

      &__item {
        @extend %flex-column;
        gap: 8px;

        &__edit {
          width: 100%;
        }

        &__title {
          @extend %Circular-Std-14;
          color: rgba(34, 34, 34, 0.8) !important;
        }
        &__subtitle {
          @extend %Circular-Std-14;
          color: $midnightBlack !important;

          &__link {
            color: $sapphire !important;
          }
        }
      }
    }
  }
  &__date {
    @extend %flex-align;
    gap: 24px;
    margin-bottom: 4px;

    @include breakpoint(tablet) {
      gap: 10px;
    }

    &__picker {
      background-color: $paleBlue;
      border-radius: 5px;
      border: 0.5px dashed $lightGray;
      width: 110px;

      div {
        fieldset {
          border: none;
        }

        svg {
          path {
            fill: $sapphire;
          }
        }
        input {
          padding: 12px 8px;
        }
      }
      label {
        @extend %Public-Sans-12-reg;
        color: $sapphire;
      }
    }
  }
}
