@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.create {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  max-width: 100%;

  &__text {
    width: 100%;
    padding: 10px 16px;
    text-transform: capitalize;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__selected {
      background-color: $light_grayish_white;
    }

    &:hover {
      background-color: $light_grayish_white;
    }
  }

  &__button {
    margin-left: 8px;
    span {
      @extend %Circular-Std-14;
    }
  }
}

.chip {
  min-height: 16px;
  min-width: 16px;
}
