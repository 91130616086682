@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  height: fit-content;
  max-width: 100%;
  padding: 16px 24px;
  border-radius: 5px;
  background: $snowWhite;
  border: 1px solid $subtle_blue;

  @media (min-width: $large-d) {
    max-width: 760px;
  }

  @include breakpoint(tablet-l) {
    max-width: 100%;
    padding: 16px;
    height: auto;
  }

  &__images {
    @extend %flex-align;
    gap: 15px;
    max-width: 100%;
    overflow: scroll;
    padding-top: 5px;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    div {
      min-width: 150px;
      max-width: 150px;
      height: 150px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &_header {
    @extend %flex-align;
    gap: 24px;
    margin-bottom: 8px;

    @include breakpoint(tablet-l) {
      gap: 12px;
    }

    &_avatar {
      border-radius: 4px;
      width: 56px;
      height: 56px;
      border: 3px solid #e1f1f4;

      @include breakpoint(tablet-l) {
        width: 40px;
        height: 40px;
      }
    }

    &__input {
      width: 100%;

      &_field {
        @include breakpoint(tablet-l) {
          height: 40px !important;
        }

        div {
          @include breakpoint(tablet-l) {
            height: 40px !important;
            border-radius: 5px !important;
          }
        }

        input {
          @include breakpoint(tablet-l) {
            height: 40px !important;
            padding: 0 12px !important;
            font-size: 12px;
            line-height: normal !important;
            font-family: $circularLight;
          }
        }
      }
    }

    &_user {
      margin-bottom: 13.5px;
      display: flex;
      gap: 16px;

      &__content {
        max-width: 80%;
      }
    }

    &_username {
      color: $black !important;
      font-style: italic;
      font-size: 14px !important;
      font-weight: 500 !important;
      letter-spacing: 0.035px !important ;
      font-family: $circularLight !important;
    }
  }

  &_footer {
    display: flex;
  }
}
