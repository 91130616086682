@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__empty {
    @extend %flex-center;
    padding: 10px 0;

    background-color: $white;
  }

  &__content {
    max-height: calc(100dvh - 200px);
    overflow-y: auto;
    border-radius: 0 0 10px 10px;
  }

  &__header {
    @extend %flex-between;
    padding: 24px;
    background: $pureWhite;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0.5px solid #ccc;

    @include breakpoint(tablet-m) {
      padding: 12px;
    }

    &__title {
      font-size: 25px;
      font-family: $circularBold;
    }

    svg {
      cursor: pointer;
    }
  }
}
