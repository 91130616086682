@import '../../../../../../styles/vars.scss';
@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/fonts.scss';
.container {
  margin-top: 24px;

  &__message {
    font-size: 12px;
    font-family: $circularMedium;
    margin: 5px 0;
    color: red;
  }

  &_disable {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &__head {
    position: relative;

    &__add {
      margin-left: 16px;
    }
  }
}
