@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 100%;
  max-width: 100%;

  @include breakpoint(tablet-l) {
    max-width: 100%;
  }

  &__top {
    margin-bottom: 16px;

    @include breakpoint(tablet-m) {
      margin-bottom: 18px;
    }
  }

  &__content {
    @extend %flex-justify-between;
    gap: 20px;
    margin-bottom: 24px;

    @include breakpoint(tablet-m) {
      gap: 18px;
      margin-bottom: 18px;
    }

    @include breakpoint(desktop) {
      flex-direction: column;
    }
  }
}
