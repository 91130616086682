@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  margin-top: 12px;

  &__content {
    @extend %flex-between;
    gap: 24px;

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: 10px;
    }

    &__item {
      @extend %flex-align;
      width: 100%;
      flex-wrap: wrap;
      gap: 16px;
      border-radius: 5px;
      padding: 20px;
      background-color: $neutral-gray;
      border: 1px solid $subtle_blue;
    }
  }
}
