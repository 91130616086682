@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  width: 100%;

  @include breakpoint(tablet-l) {
    padding: 0 20px;
    background: $whispering_gray;
  }

  &__title {
    font-size: 25px;
    font-family: $circularBold;
  }

  &__navbar {
    padding: 24px 0;

    position: relative;

    &__ref {
      position: relative;
      max-width: fit-content;
    }

    &__menu {
      display: none;

      @include breakpoint(tablet-l) {
        display: flex;

        cursor: pointer;
      }

      &__items {
        display: flex;
        position: absolute;
        z-index: 999;
        width: 220px;
        padding: 12px;
        max-width: 145px;
        background: #fff;
        flex-direction: column;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      }
    }

    &__content {
      width: 100%;
      margin-top: 12px;
      background: #fff;

      @media (min-width: $large-d) {
        padding-right: 0;
      }

      @include breakpoint(tablet-l) {
        padding-right: 0;
        background: transparent;
      }

      &__title {
        font-size: 21px;
        letter-spacing: 0.032px;
        text-transform: capitalize;
      }
    }

    &__items {
      @extend %flex-align;

      width: fit-content;

      @include breakpoint(wide) {
        gap: 0px;
      }

      @include breakpoint(tablet-l) {
        display: none;
      }

      &__subitems {
        position: absolute;
        top: 50px;
        left: 0;
        z-index: 10;
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

        @include breakpoint(desktop) {
          left: 130px;
          top: 0px;
        }
      }

      &__myItems {
        color: $midnight_charcoal;
        cursor: pointer;
        width: max-content;
        text-transform: capitalize;
        padding: 10px 16px 10px 24px;

        &_active {
          color: $sapphire;
          background: none;
        }
      }

      &__arrow {
        width: 16px;
        height: 16px;

        &_expanded {
          transform: rotate(180deg);

          path {
            stroke: $sapphire;
          }
        }

        &_active {
          path {
            stroke: $sapphire;
          }
        }
      }

      &__item {
        display: flex;
        cursor: pointer;
        font-size: 14px;
        position: relative;
        padding: 12px 16px;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        border-bottom: 2px solid $tableCellGray;

        &__title {
          color: $tableCellGray !important;
          font-family: $circularMedium;
          width: 88px;

          @include breakpoint(tablet-l) {
            width: auto;
          }
        }

        @include breakpoint(tablet-l) {
          display: flex;
          cursor: pointer;
          justify-content: flex-start;
        }

        @include breakpoint(wide) {
          padding: 12px 16px 10px;
          padding-left: 0;
        }

        &__title {
          font-size: 14px;
          text-align: center;
          letter-spacing: 0.035px;
        }

        svg {
          margin-left: 8px;

          @include breakpoint(wide) {
            margin-left: 2px;
          }
        }

        &_myitem {
          padding: 12px 7px;

          @include breakpoint(wide) {
            padding: 12px 0;
          }

          @include breakpoint(desktop) {
            padding: 0 0 0 12px;
          }
        }

        &_active {
          width: 100%;
          display: flex;
          cursor: pointer;
          color: $sapphire !important;
          border-bottom: 2px solid $sapphire;

          .container__navbar__items__item__title {
            color: $sapphire !important;
          }

          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
