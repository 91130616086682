@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  border-bottom: 1px solid $gray;
  padding: 10px 0;

  &_last {
    border-bottom: none;
  }

  &__content {
    @extend %flex-align;
    gap: 9px;

    &__head {
      @extend %flex-align;
      gap: 9px;
      min-width: 130px;
      max-width: 130px;
      align-self: flex-start;
    }

    &__time {
      font-size: 12px;
      margin-right: 10px;
      font-style: italic;
      color: $dusk_slate !important;
      font-family: $circularRegular;
    }

    &__text {
      max-width: 100%;
      word-break: break-word;
      font-size: 14px;
      font-family: unset !important;
      color: $shadowed-slate !important;
    }
  }

  svg {
    path {
      fill: $dusk_slate;
    }
  }

  &__main {
    @extend %flex-align;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;

    img {
      width: 150px;
      height: 150px;
      border-radius: 10px;
    }

    video {
      width: 150px;
      height: 150px;
      border-radius: 10px;
    }
  }
}
