@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  position: relative;

  & > div > div {
    border: none;
  }

  &__emoji {
    position: absolute;
    right: 0;
    top: -263px;
    z-index: 12;

    em-emoji-picker {
      height: 260px;
    }

    &_comments {
      position: static !important;
    }

    &_settings {
      top: -230px;

      em-emoji-picker {
        height: 230px !important;

        @include breakpoint(tablet) {
          width: 300px;
        }
      }
    }
  }

  input {
    padding: 14px 16px;

    &::-webkit-input-placeholder {
      opacity: 1 !important;
    }
  }
}
