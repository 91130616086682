@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 8px;

  &__title {
    @extend %Circular-Std-14-reg;
    color: $shadowed-slate !important;
  }

  &__subtitle {
    @extend %Circular-Std-14-bold;
  }
}
