@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    gap: 20px;
    background: $whispering_gray;
  }

  &__icon {
    @extend %flex-center;
    margin: 0 auto;
  }

  &__top {
    @extend %flex-end;
    width: 100%;
    margin-bottom: 24px;

    &__between {
      @extend %flex-between;
    }

    @include breakpoint(tablet) {
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 16px;
    }

    &__button {
      @extend %Circular-Std-14-med;
      padding: 8px;
      color: $white;
      max-width: 142px;
      border-radius: 5px;
      background-color: $sapphire;

      &:hover {
        background-color: $sapphire;
      }

      &:disabled {
        color: white !important;
      }
    }
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
