@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column-end;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 500px;
  background-color: $snowWhite;
  transform: translate(-50%, -50%);
  border-radius: 10px;

  @include breakpoint(tablet-m) {
    width: 320px;
  }

  @include breakpoint(mobile-m) {
    width: 284px;
  }

  &__header {
    @extend %flex-between;
    width: 100%;
    padding: 8px 16px;
    background: $pureWhite;
    border-radius: 10px 10px 0 0;
    border-bottom: 0.5px solid $gray;

    svg {
      cursor: pointer;
    }

    @include breakpoint(tablet-m) {
      padding: 16px 24px;
    }

    &__title {
      @extend %Circular-Std-25-bold;
      font-weight: bold;
      max-width: 92%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include breakpoint(tablet-m) {
        font-size: 18px !important;
      }
    }
  }

  &__content {
    @extend %flex-column;
    gap: 24px;
    padding: 16px;
    width: 100%;

    @include breakpoint(tablet-m) {
      padding: 16px 24px;
    }

    &__title {
      @extend %Circular-Std-14-reg;
      max-height: 154px;
      white-space: unset;
      overflow-y: scroll;
      line-break: anywhere;

      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include breakpoint(tablet-m) {
        font-size: 18px;
      }

      @include breakpoint(mobile) {
        font-size: 16px;
      }
    }

    &__actions {
      @extend %flex-end;
      gap: 8px;

      &__center {
        @extend %flex-end;
        gap: 8px;
      }

      &__button {
        max-width: 80px;
        width: 100%;
        padding: 11px 16px;
        color: $snowWhite;

        @include breakpoint(tablet-m) {
          padding: 8px;
        }
      }

      &__delete {
        background-color: $sapphire;

        &:hover {
          background-color: $royal_blue;
        }
      }
    }
  }
}
