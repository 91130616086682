@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  padding: 4px;
  background-color: $paleBlue;
  color: $sapphire;
  margin: 24px 0 0;

  &_active {
    background-color: $paleBlue;
  }

  &__content {
    @extend %flex-align;
    font-family: $circularBold !important;
    font-size: 21px;

    height: 27px;

    &__price {
      font-weight: 500;
      font-size: 15px;
      font-family: $circularBold !important;

      &__sales {
        font-size: 12px;
        letter-spacing: 0.048px;
        font-family: $circularBold !important;

        a {
          text-decoration: underline !important;
          color: $sapphire;
        }
      }
    }

    &__duration {
      font-size: 16px;
      align-self: flex-end;
      margin-left: 4px;
      font-family: $circularBold !important;
    }
  }
}
