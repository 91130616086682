@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  height: 313px;
  max-width: 100%;
  width: 100%;
  position: relative;

  @media (min-width: $large-d) {
    max-width: 760px;
  }

  &__post {
    @extend %flex-center;
    width: 100%;
    height: 100%;

    position: relative;

    z-index: 3;

    img {
      object-fit: contain !important;
      height: 100%;
      width: 100%;
    }
  }

  @include breakpoint(tablet-l) {
    max-width: 100%;
  }

  @include breakpoint(tablet-m) {
    height: 250px;
  }

  &_image {
    height: 100%;
    width: 100%;
    position: absolute;

    img {
      object-fit: contain !important;
      width: 100%;
      height: 100%;
      filter: blur(4px);
    }
  }

  &__icon {
    position: absolute;
    bottom: 25px;
    right: 25px;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.7);
    @extend %flex-center;
    z-index: 10;
    border-radius: 50%;

    svg {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}
