@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  &_content {
    padding: 8px;
    display: flex;
    gap: 24px;

    @include breakpoint(tablet-l) {
      padding: 0;
    }

    &_image {
      @extend %flex-align;
      gap: 12px;
      cursor: pointer;
    }

    &_video {
      @extend %flex-align;
      gap: 12px;
      cursor: pointer;
    }

    &_divider {
      @extend %flex-center;
      border: 0.5px solid $staleGray;
    }

    p {
      color: $jet_black !important;
      font-size: 14px !important;
      font-weight: 500 !important;
      letter-spacing: 0.035px !important;
      font-family: $circularRegular !important;
    }
  }
}
