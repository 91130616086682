@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.wrapper__container {
  @extend %flex-center;
  background: url('../../assets/images/backError.webp');
  position: absolute;
  height: 100dvh;
  width: 100%;
  background-color: $midnight_gray;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.5);
  background-color: rgba(32, 103, 221, 1);

  @include breakpoint(tablet-l) {
    padding: 0 20px;
  }

  &__content {
    @extend %flex-center-column;
    text-align: center;

    &__link {
      font-family: $circularRegular;
      position: relative;
      cursor: pointer;
      display: inline-block;
      padding: 10px 25px;
      background-color: white;
      color: $sapphire;
      text-decoration: none;
      margin-top: 25px;
      border-radius: 25px;
      border-bottom: 4px solid $sapphire;
    }

    &__description {
      color: $white !important;
      font-family: $circularRegular;
      font-size: 19.2px;
      max-width: 600px;

      @include breakpoint(tablet-l) {
        font-size: 16px;
      }
    }

    h2 {
      font-family: $circularMedium;
      font-size: 18rem;
      color: $white;
      line-height: 1em;

      @include breakpoint(tablet-l) {
        font-size: 12rem;
      }
    }

    h4 {
      position: relative;
      font-family: $circularMedium;
      border-radius: 20px;
      font-size: 24px;
      margin-bottom: 20px;
      color: $deep_black;
      background: $white;
      padding: 10px 20px;
      display: inline-block;

      @include breakpoint(tablet-l) {
        font-size: 16px;
      }
    }
  }
}
