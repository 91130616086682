@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  max-width: 1040px;
  height: 450px;

  &__free {
    text-decoration: underline;
    text-decoration-thickness: 2px;
  }

  @include breakpoint(desktop) {
    max-width: 600px;
  }

  @include breakpoint(tablet-l) {
    max-width: 500px;
  }

  @include breakpoint(tablet-l) {
    max-width: 400px;
  }

  @include breakpoint(tablet-m) {
    max-width: 350px;
  }
  @include breakpoint(mobile-m) {
    max-width: 350px;
  }

  @include breakpoint(mobile) {
    max-width: 300px;
  }
}
