@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 32px 40px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(3, 1fr);
  border-radius: 0px 0px 5px 5px;
  border: 0.5px solid #ccc;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(34, 34, 34, 0.15) inset;

  @include breakpoint(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include breakpoint(tablet-m) {
    padding: 16px;
    grid-template-columns: 1fr;
  }

  &__generate {
    border: none;
    border-bottom: 0.5px dashed #ccc;
    padding: 32px 24px;

    & div {
      & input {
        color: $midnightBlack !important;

        &::placeholder {
          color: $midnightBlack !important;
        }
      }
    }
  }
}
