@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;
  background-color: $deep_cerulean;
  border-radius: 32px 0 32px 32px;
  padding: 40px;

  &__corner {
    position: absolute;
    right: -88px;
    top: 0;
  }
}
