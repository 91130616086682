@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  gap: 14px;
  padding: 8px 12px;
  background: $snowWhite;

  &__main {
    @extend %flex-align;
    gap: 8px;

    @include breakpoint(tablet-m) {
      flex-direction: column;
      justify-content: flex-start;
      justify-content: flex-start;
    }
  }

  &__title {
    font-size: 21px;
    font-weight: 400;
    font-family: $circularBold;
    line-height: normal;
    letter-spacing: 0.032px;
  }

  &__content {
    @extend %flex-align;
    gap: 14px;
  }

  &__head {
    @extend %flex-between;

    &__title {
      @extend %Circular-Std-21;
      text-transform: capitalize;
      color: $sapphire !important;
    }
  }

  &__subtitle {
    @extend %Circular-Std-21;
    text-transform: capitalize;

    &_lowercase {
      @extend %Circular-Std-21;

      &_cancel {
        margin-left: 5px;
      }
    }

    &__time {
      @extend %Circular-Std-12-light;
    }
  }

  &__content {
    @extend %Circular-Std-14-reg;
  }
}
