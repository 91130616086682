@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column-between;
  margin-top: 40px;
  height: calc(100dvh - 150px);

  &__title {
    @extend %Circular-Std-25-bold;
    width: 100%;
    text-align: center;
  }

  &__content {
    @extend %flex-column;
    gap: 40px;

    &__item {
      @extend %flex-align;
      gap: 24px;
    }

    &__footer {
      @extend %flex-align-end;
      gap: 24px;

      &__button {
        border-radius: 5px;
        padding: 11px 8px;
        text-transform: capitalize;
      }
    }
  }
}
