@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 20px;
  border-radius: 8px;
  border: 1px solid $light_steel_gray;
  background: #fff;
  max-width: 318px;
  position: relative;

  width: 100%;
  height: 410px;
  cursor: pointer;

  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);

  &__main {
    height: calc(100% - 30px);
  }

  &_active {
    cursor: auto !important;
    background-color: $sapphire;

    .container_content_wrapper {
      svg {
        path {
          fill: white !important;
        }
      }
    }

    .container_header_title {
      color: white !important;
    }

    .container_price_title {
      color: white !important;
    }

    .container_content_text {
      color: white !important;
    }
  }

  &_header {
    &_title {
      line-height: normal;
      text-align: center;
      margin-bottom: 20px;
      font-size: 25px;
      color: $midnightBlack !important;
      letter-spacing: 0.032px !important;
      font-family: $circularBold !important;
    }
  }

  &_price {
    width: calc(100% + 40px);
    margin: 0 -20px;
    padding: 4px 0;

    &_title {
      line-height: normal;
      text-align: center;
      background: $paleBlue;
      font-size: 18px !important;
      color: $sapphire !important;
      letter-spacing: 0.088px !important;
      font-family: $circularBold !important;

      span {
        font-size: 18px;
      }
    }
  }

  &_content {
    @extend %flex-column;
    gap: 4px;
    margin-bottom: 60px;

    &_wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_text {
      font-style: normal;
      line-height: normal;
      font-size: 14px !important;
      font-weight: 500 !important;
      color: $obsidianAura !important;
      letter-spacing: 0.08px !important;
      font-family: $circularRegular !important;
      text-align: left !important;
      width: 100%;
    }
  }

  &_button {
    display: flex;
    justify-content: center;

    button {
      width: 100%;
      padding: 15px 0;
      max-width: 250px;
      border-radius: 5px !important;
      background: $paleBlue !important;
    }
  }
}
