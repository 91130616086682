@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(186, 184, 184, 0.8);
}
