@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;
  max-width: 500px;

  &__head {
    @extend %flex-between;

    &__title {
      @extend %Circular-Std-25-bold;
      width: 100%;
      text-align: left;
    }
  }

  &__required {
    @extend %Circular-Std-12-reg;
  }

  &__flex {
    @extend %flex-between;
    gap: 24px;
  }

  &__footer {
    @extend %flex-between;

    &__cancel {
      @extend %Circular-Std-14-med;
      width: 100%;
      max-width: 120px;
      color: $sapphire;
      border-radius: 10px;
      padding: 20.5px 16px;
      background-color: $paleBlue;

      &:hover {
        opacity: 0.8;
        background-color: $paleBlue;
        color: $sapphire;
      }
    }

    &__submit {
      @extend %Circular-Std-14-med;
      width: 100%;
      color: $white;
      max-width: 120px;
      border-radius: 10px;
      padding: 20.5px 16px;
      background-color: $sapphire;

      &:hover {
        opacity: 0.8;
        color: $white;
        background-color: $sapphire;
      }
    }
  }
}
