@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;
  margin-bottom: 24px;

  &__decline {
    @extend %flex-column;
    gap: 10px;
  }

  &__routing {
    @extend %flex-column;
    gap: 12px;

    &__back {
      @extend %flex-center;
      max-width: fit-content;
      cursor: pointer;
      gap: 8px;

      &__icon {
        fill: $jet_black;
      }

      &__text {
        @extend %Circular-Std-14-med;
        text-transform: uppercase;
      }
    }

    &__pages {
      @extend %flex-center;
      max-width: fit-content;
      gap: 4px;

      &__icon {
        transform: rotate(-90deg);
      }

      &__text {
        @extend %Circular-Std-14-reg;
        color: $sapphire;
        cursor: pointer;
      }
    }
  }

  &__main {
    @extend %flex-between;
    gap: 24px;

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      @extend %Circular-Std-25-bold;
    }

    &__add {
      @extend %flex-center;
      gap: 8px;
      max-height: 40px;
      padding: 10px 16px;
      border-radius: 5px;
      text-transform: capitalize;

      span {
        margin: 0;
        @extend %Circular-Std-14-med;
      }

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}
