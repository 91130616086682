@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  width: 100%;

  @include breakpoint(tablet-m) {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;

    &__title {
      @extend %Circular-Std-16-bold;
      @include breakpoint(tablet-m) {
        margin-bottom: 10px;
      }
    }

    &__subtitle {
      @extend %Circular-Std-14-light;
      color: $shadowed-slate !important;
    }
  }
}
