@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  flex-direction: column;
  height: 269px;
  width: 100%;
  cursor: pointer;
  max-width: 493px;
  border-radius: 4px;
  gap: 5px;
  background: $platinumGray;
  border: 1px solid $gray;

  &__icon {
    @extend %flex-center;
  }

  &__title {
    font-size: 14px;
    font-weight: 500;

    letter-spacing: 0.035px;
  }

  &__close {
    @extend %flex-center;

    width: 24px;
    height: 24px;
    border-radius: 5px;
    background-color: $white;
  }

  &__box {
    display: flex;
    cursor: pointer;
    max-width: 300px;
    overflow: hidden;
    position: relative;
    border-radius: 5px;

    img {
      object-fit: cover;
    }
  }

  &__filled {
    @extend %flex-column-between;
    top: 8px;
    z-index: 10;
    right: 8.5px;
    position: absolute;
    align-items: flex-end;
  }

  &__replace {
    @extend %flex-center;
    position: absolute;
    width: 100%;
    bottom: 16px;

    &_box {
      @extend %flex-align;
      gap: 4px;
      padding: 4px;
      border-radius: 4px;
      background: $snowWhite;
    }
  }

  &_upload {
    @extend %flex-center;
    width: 100%;
    margin-top: 21px;
    max-width: 416px;
  }
}
