@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 12px;
  background: #fff;
  height: fit-content;
  border-radius: 5px 5px 0px 0px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
}
