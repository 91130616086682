@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  background-color: $white;
  padding: 24px 16px;
  max-height: calc(100dvh - 200px);
  overflow-y: auto;

  &__footer {
    @extend %flex-end;
    margin-top: 24px;

    &__submit {
      background-color: $sapphire;
      color: $white;
      max-width: 122px;
      padding: 20px 0;

      &:hover {
        background-color: $sapphire;
        color: $white;
        opacity: 0.8;
      }
    }
  }

  &__reason {
    margin-bottom: 24px;

    &__content {
      @extend %flex-align;
      gap: 16px;
      flex-wrap: wrap;
    }

    &__title {
      @extend %Circular-Std-21;
      margin-bottom: 16px;
    }
  }
}
