@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.media-buffering-indicator {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  z-index: 1;
}

.media-buffering-spinner {
  opacity: 0;
  transition: opacity 200ms ease;
  pointer-events: none;
}

.media-buffering-track {
  color: $light_grayish_white;
  opacity: 0.25;
}

.media-buffering-track-fill {
  color: var(--media-brand, #f5f5f5);
  opacity: 0.75;
}

.media-player[data-buffering] .media-buffering-spinner {
  opacity: 1;
  animation: media-buffering-spin 1s linear infinite;
}

@keyframes media-buffering-spin {
  to {
    transform: rotate(360deg);
  }
}
