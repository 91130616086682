@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 100%;
  cursor: pointer;
  max-width: 213px;
  border-radius: 5px;
  transition: 0.3s;
  position: relative;
  background: #fff;
  border-bottom: 2px solid $sapphire;
  box-shadow: 0px 3px 4px 0px #00000033;

  &:hover {
    border-bottom-width: 4px;
    transform: translateY(-3px);
  }

  @media (min-width: $large-d) {
    max-width: 100%;
  }

  @include breakpoint(tablet-l) {
    max-width: 100%;
  }

  &__wrapper {
    padding: 12px;
    padding-bottom: 0;

    &__header {
      @extend %flex-justify-between;
      align-items: flex-end;

      &__date {
        @extend %Circular-Std-12-light;
        color: $staleGray !important;
      }

      &__dropdown {
        width: max-content;
      }
    }

    &__content {
      @extend %flex-column;
      align-items: center;
      gap: 3px;
      padding: 13px 31px 8px 32px;

      &__name {
        @extend %Circular-Std-14-reg;
        color: $midnightBlack !important;
        text-align: center;
      }

      &__number {
        @extend %Circular-Std-14-reg;
        color: $sapphire !important;
      }

      img {
        width: 40px;
        height: 40px;
      }
    }
  }

  &__footer {
    padding: 12px;
    padding-top: 2px;

    &__row {
      @extend %flex-justify-between;
      padding-top: 10px;

      &:first-child {
        border-bottom: 0.5px solid #ccc;
        padding-bottom: 10px;
        padding-top: 0;
      }

      &__name {
        @extend %Circular-Std-14-reg;
        color: $midnightBlack !important;

        &_slice {
          @extend %text-ellipsis;
          @extend %Circular-Std-14-reg;
          max-width: 100px;
          color: $midnightBlack !important;
        }
      }
    }
  }
}
