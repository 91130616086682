@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 8px;

  &__header {
    @extend %flex-center;
    @extend %Circular-Std-25-light;
    padding: 40px 0 18px 0;
    color: $midnightBlack !important;
  }
  &__title {
    @extend %Circular-Std-21;
    display: flex;
    gap: 6px;

    &__typo {
      @extend %Circular-Std-21;
      color: $midnightBlack !important;
    }

    &__count {
      @extend %Circular-Std-21;
      color: $midnightBlack !important;
    }

    &__input {
      border-bottom: 0.5px dotted #ccc;
    }
  }
  &__user {
    display: flex;
    gap: 16px;
    max-width: 100%;
    overflow-x: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__content {
      @extend %flex-align;
      flex-direction: column;
      min-width: fit-content;
      gap: 8px;

      &__name {
        @extend %text-ellipsis;
        max-width: 150px;
      }

      &__remove {
        position: absolute;
        top: -4px;
        right: -4px;
        z-index: 5;
      }

      img {
        width: 32px;
        height: 32px;
        object-fit: cover;
        border-radius: 32px;
      }

      p {
        @extend %Circular-Std-12-light;
        color: rgba(34, 34, 34, 0.5) !important;
      }
    }
  }
  &__wrapper {
    @extend %flex-justify-between;

    &__add {
      color: $sapphire !important;
      font-family: $circularRegular !important;
      font-size: 12px !important;
      font-weight: 500 !important;
      letter-spacing: 0.048px !important;
      cursor: pointer;
    }
  }
}

.details {
  @extend %Circular-Std-16-light;
  padding: 8px 16px;
  border-bottom: 0.5px dotted #ccc;
}

.textarea {
  height: 110px;

  textarea {
    height: 110px !important;
    overflow-y: scroll !important;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.badge {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 28px;
  border-bottom: 0.5px dotted #ccc;

  &__title {
    display: flex;
    gap: 6px;

    &__typo {
      color: $midnightBlack !important;
      font-family: $circularRegular !important;
      font-size: 21px !important;
      font-weight: 450 !important;
      letter-spacing: 0.035px !important;
    }
    &__count {
      color: $midnightBlack !important;
      font-family: $circularRegular !important;
      font-size: 21px !important;
      font-weight: 450 !important;
      letter-spacing: 0.035px !important;
    }
  }
  &__icon {
    margin-right: 8px;
    svg {
      display: flex;
    }
  }

  &__tag {
    display: flex;
    margin-top: 16px;
    align-items: center;

    &__item {
      gap: 8px;
      display: flex;
      flex-wrap: wrap;

      div {
        margin-right: 0 !important;
      }
    }
  }
  &__wrapper {
    @extend %flex-justify-between;
    position: relative;

    &__add {
      @extend %flex-align;
      gap: 8px;

      &__more {
        color: $sapphire !important;
        font-family: $circularRegular !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        letter-spacing: 0.048px !important;
        cursor: pointer;
      }
      &__icon {
        width: 16px;
        height: 16px;
        transition: 0.3s;
        cursor: pointer;

        path {
          stroke: $sapphire !important;
        }

        &__active {
          transform: rotate(180deg);
        }
      }
    }
    &__dropdown {
      display: contents;

      &__box {
        top: 19px;
        right: 0;
        position: absolute;
      }
    }
  }
}
