@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-justify-center;
  width: 100%;

  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;

    &__title {
      font-family: $circularBold;
      font-size: 14px;
      color: '222';
      letter-spacing: 0.035px;
    }
  }

  &__subtitle {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &__items {
      font-family: $circularLight;
      font-size: 14px;
      letter-spacing: 0.035px;

      @include breakpoint(tablet-l) {
        font-size: 12px;
      }

      @include breakpoint(mobile) {
        font-size: 10px;
      }
    }
  }
}
