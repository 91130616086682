@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &:hover {
    fieldset {
      border-color: rebeccapurple !important;
    }
  }

  &__label {
    letter-spacing: 0.08px;
    top: -1px;
  }

  &__select {
    letter-spacing: 0.08px;
    text-transform: capitalize;

    svg {
      cursor: pointer;
    }

    &__item {
      letter-spacing: 0.08px;
      text-transform: capitalize;
    }
  }
}

.clearIcon {
  margin-right: 8px;
  cursor: pointer;
}
