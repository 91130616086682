@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=ABeeZee:ital@1&display=swap');

@font-face {
  font-family: 'CircularStdBold';
  src: url('../../src/fonts/CircularStd-Bold.ttf') format('truetype'),
    url('../../src/fonts/CircularStd-Bold.otf') format('opentype'),
    url('../../src/fonts/CircularStd-Bold.woff') format('woff'),
    url('../../src/fonts/CircularStd-Book.eot') format('embedded-opentype');
  font-display: swap;
}

@font-face {
  font-family: 'CircularStdMedium';
  src: url('../../src/fonts/CircularStd-Medium.ttf') format('truetype'),
    url('../../src/fonts/CircularStd-Medium.otf') format('opentype'),
    url('../../src/fonts/CircularStd-Medium.woff') format('woff'),
    url('../../src/fonts/CircularStd-Medium.eot') format('embedded-opentype');
  font-display: swap;
}

@font-face {
  font-family: 'CircularStdRegular';
  src: url('../../src/fonts/CircularStd-Book.ttf') format('truetype'),
    url('../../src/fonts/CircularStd-Book.otf') format('opentype'),
    url('../../src/fonts/CircularStd-Book.woff') format('woff'),
    url('../../src/fonts/CircularStd-Book.eot') format('embedded-opentype');
  font-display: swap;
}

@font-face {
  font-family: 'CircularStdBoldMax';
  src: url('../../src/fonts/CircularStd-Black.ttf') format('truetype'),
    url('../../src/fonts/CircularStd-Black.otf') format('opentype'),
    url('../../src/fonts/CircularStd-Black.woff') format('woff'),
    url('../../src/fonts/CircularStd-Black.eot') format('embedded-opentype');
  font-display: swap;
}

@font-face {
  font-family: 'CircularStdLight';
  src: url('../../src/fonts/CircularStd-Light.ttf') format('truetype'),
    url('../../src/fonts/CircularStd-Light.otf') format('opentype');
  font-display: swap;
}

%Public-Sans-12-reg {
  font-family: $publicSans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.048px;
}

%Public-Sans-10-light {
  font-family: $publicSans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.025px;
}

%Circular-Std-12-reg {
  font-family: $circularRegular !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 0.048px;
}

%Circular-Std-12-light {
  font-family: $circularLight !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.048px;
}

%Circular-Std-12 {
  font-family: $circularLight !important;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.048px;
}

%Circular-Std-12-medium {
  font-family: $circularMedium !important;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.15px;
}

%Circular-Std-12-bold {
  font-family: $circularBold !important;
  font-size: 12px;
  letter-spacing: 0.048px;
}

%Circular-Std-10-light {
  font-family: $circularLight !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.048px;
}

%Circular-Std-10-medium {
  font-family: $circularLight !important;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.04px;
}

%Public-Sans-14-reg {
  font-family: $publicSans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.048px;
}

%Circular-Std-14-reg {
  font-family: $circularRegular !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 0.035px;
}

%Circular-Std-14-bold {
  font-family: $circularBold !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.035px;
}

%Circular-Std-14-light {
  font-family: $circularLight !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.035px;
}

%Circular-Std-14 {
  font-family: $circularLight !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.035px;
}

%Circular-Std-14-med {
  font-family: $circularMedium !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.175px;
}

%Circular-Std-14-boldMax {
  font-family: $circularBoldMax !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.175px;
}

%Circular-Std-16-light {
  font-family: $circularLight !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.08px;
}

%Circular-Std-16-bold {
  font-family: $circularBold !important;
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.08px;
}

%Circular-Std-16-reg {
  font-family: $circularRegular !important;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0.08px;
}

%Circular-Std-16-medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: $circularMedium !important;
}

%Circular-Std-16-med {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: $circularMedium !important;
}

%Circular-Std-49 {
  font-size: 49px;
  font-weight: 450;
  letter-spacing: 0.032px;
  font-family: 'CircularStdRegular' !important;
}

%Circular-Std-14 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.035px;
  font-family: 'CircularStdRegular' !important;
}

%Circular-Std-14-light {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.035px;
  font-family: 'CircularStdLight' !important;
}

%Circular-Std-16-light {
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: 'CircularStdLight' !important;
}

%Circular-Std-16-regular {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: $circularRegular !important;
}

%Circular-Std-16-medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: $circularRegular !important;
}

%Circular-Std-49-regular {
  font-size: 49px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: $circularRegular !important;
}

%Circular-Std-49-bold {
  font-size: 49px;
  line-height: normal;
  font-family: $circularBold !important;
}

%Circular-Std-21 {
  font-style: normal;
  font-weight: 450;
  font-size: 21px;
  line-height: normal;
  letter-spacing: 0.032px;
  font-family: 'CircularStdRegular' !important;
}

%Circular-Std-21-bold {
  font-style: normal;
  font-weight: 450;
  font-size: 21px;
  line-height: normal;
  letter-spacing: 0.032px;
  font-family: 'CircularStdBold' !important;
}

%Circular-Std-21-medium {
  font-style: normal;
  font-size: 21px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.032px;
  font-family: 'CircularStdLight' !important;
}

%Circular-Std-21-light {
  font-style: normal;
  font-size: 21px;
  line-height: normal;
  font-family: 'CircularStdLight' !important;
}

%Circular-Std-21-bold {
  font-size: 21px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.032px;
  font-weight: 700 !important;
  font-family: 'CircularStdBold' !important;
}

%Circular-Std-25 {
  font-size: 25px;
  font-style: normal;
  font-weight: 300 !important;
  line-height: normal;
  letter-spacing: 0.032px;
  font-family: 'CircularStdLight' !important;
}

%Circular-Std-25-bold {
  font-size: 25px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.032px;
  font-family: 'CircularStdBold' !important;
}

%Circular-Std-35-bold {
  font-size: 35px;
  letter-spacing: 0.032px;
  font-family: 'CircularStdBold' !important;
}

%Circular-Std-25-medium {
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: 'CircularStdLight' !important;
}

%Circular-Std-25-bold {
  font-size: 25px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
  font-family: 'CircularStdBold' !important;
}

%Circular-Std-25-light {
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.032px;
  font-family: $circularLight !important;
}
%Circular-Std-25-reg {
  font-size: 25px;
  font-weight: 450 !important;
  font-style: normal;
  line-height: normal;
  font-family: 'CircularStdLight';
}

%Circular-Std-35-reg {
  font-family: 'CircularStdRegular';
  font-size: 35px;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.088px;
}

%Circular-Std-35 {
  font-family: 'CircularStdRegular';
  font-size: 35px;
  font-weight: 450;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.088px;
}

%Circular-Std-35-bold {
  font-size: 35px;
  font-weight: 700 !important;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.088px;
  font-family: 'CircularStdBold' !important;
}

%Circular-Std-22-light {
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  font-family: 'CircularStdLight';
}

%Circular-Std-18-light {
  font-family: $circularLight !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.08px;
}

%Circular-Std-18-regular {
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: 0.08px;
  font-family: $circularRegular !important;
}

%Circular-Std-30 {
  font-family: $circularRegular !important;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.045px;
}
