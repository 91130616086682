@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 22px;

  &__header {
    @extend %flex-between;

    &:last-child {
      @include breakpoint(tablet-m) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }

    &__title {
      @extend %Circular-Std-25-bold;
    }

    &__search {
      max-width: 256px;
      width: 100%;
    }

    &__create {
      text-transform: capitalize;
      border-radius: 5px;
      padding: 8px 15px;

      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}
