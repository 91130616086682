@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 5px;

  &__title {
    @extend %flex-center;
    font-family: $circularRegular;
    padding: 10px 0;

    &__divided {
      padding: 0;
    }
  }
}
