@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
    @extend %flex-column;
    gap: 24px;
    margin-bottom: 24px;
  
    &__routing {
      @extend %flex-column;
      gap: 12px;
    
      &__pages {
        @extend %flex-center;
        max-width: fit-content;
        gap: 4px;
       }
    }
  
    &__main {
      @extend %flex-between;
      gap: 24px;
  
      @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
      }
  
      &__title {
        @extend %Circular-Std-25-bold;
      }     
    }
  }
  