@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 375px;

  @include breakpoint(mobile-m) {
    width: 320px;
  }

  &__head {
    @extend %flex-between;
    padding: 20px;

    &__options {
      @extend %flex-align;
      gap: 24px;

      &__title {
        @extend %Circular-Std-14-med;
        color: $sapphire !important;
        cursor: pointer;

        &_disable {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
    }
  }

  &__content {
    width: 100%;
    border-radius: 0;
    font-size: 21px;
    letter-spacing: 0.032px;
    height: 75px;

    fieldset {
      border-left: none;
      border-right: none;
      height: 75px;
    }

    input {
      height: 75px;
      padding-block: 0;
    }

    &__box {
      @extend %flex-center;
      @extend %Circular-Std-21;
      border-top: 0.5px solid #ccc;
      border-bottom: 0.5px solid #ccc;
      padding: 24px 0;
      font-size: 21px;
      letter-spacing: 0.032px;
      width: 100%;
      background-color: $white;
    }
  }

  &__body {
    @extend %flex-column;
    padding: 0 8px;
    height: calc(100dvh - 144px);
    padding-bottom: 39px;

    textarea {
      @extend %Circular-Std-16-light;
      resize: none;
      padding-top: 34px;
      height: 100%;
      border: none;
      width: 100%;
    }
  }

  &__options {
    margin: 0 auto;
  }
}

.quill {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
  height: calc(100dvh - 200px) !important;
}

.quill > div {
  height: 56px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 0 auto !important;
  max-width: 216px;
  width: 100%;
  padding: 16px 24px;
  border-radius: 16px;
  background: $platinumGray !important;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 4px 0px 10px 0px rgba(0, 0, 0, 0.05);

  span {
    &:nth-child(1) {
      display: flex;
      gap: 24px;
    }
    &:nth-child(3) {
      display: none !important;
    }
  }
}

.quill > div ~ div {
  font-size: 16px;
  padding: 0 !important;
  max-width: 100% !important;
  display: block !important;
  flex: 1 1 auto !important;
  box-shadow: none !important;
  font-family: $circularLight;
  background-color: transparent !important;
}
