@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__footer {
    @extend %flex-end;
    width: 100%;
    padding: 24px 0;
    gap: 24px;
  }
}
