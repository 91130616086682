@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  position: relative;
  gap: 16px;

  @include breakpoint(tablet) {
    gap: 10px;
  }

  &__grid {
    display: grid;
    gap: 16px 20px;
    grid-template-columns: repeat(6, 1fr);
  }

  &__title {
    @extend %Circular-Std-25-bold;

    width: 100%;
    text-align: left;
  }

  &__head {
    margin-bottom: 8px;
    &__box {
      max-width: 100%;
      gap: 8px;
      height: 109px;

      &__content {
        max-width: 300px;
        height: 200px;

        @include breakpoint(tablet) {
          margin: 0 auto;
          max-width: 300px;
        }

        @include breakpoint(tablet-m) {
          margin: 0 auto;
          max-width: 250px;
          height: 200px;
        }
      }

      @include breakpoint(tablet) {
        margin: 0 auto;
        max-width: 300px;
      }
    }
  }

  &__item {
    @extend %flex-between;
    gap: 20px;

    @include breakpoint(tablet-m) {
      gap: 10px;
      flex-wrap: wrap;
    }
  }

  &__submit {
    @extend %flex-end;
    gap: 24px;
    width: 100%;
    padding: 8px 0;
  }
}
