@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
$circular-reg: 'CircularStdRegular', sans-serif;
.container {
  width: 100%;

  &__content {
    @extend %flex-justify-between;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    max-width: 1040px;

    @include breakpoint(tablet-l) {
      flex-direction: column;
      gap: 20px;
    }

    &__main {
      @extend %flex-column;
      width: 100%;
      gap: 16px;

      &:first-child {
        padding-right: 24px;
        margin-right: 12px;
        border-right: 1px solid #d9e0ea;

        @include breakpoint(tablet-l) {
          border: none;
          padding: 0;
          margin: 0;
        }
      }

      &:last-child {
        padding-left: 12px;

        @include breakpoint(tablet-l) {
          padding: 0;
        }
      }
      
      &__select {
        font-family: $circular-reg;
        margin-left: 5px;
        margin-top: 10px; 
      }
    }

    @include breakpoint(wide) {
      padding: 20px;
      gap: 20px;
    }
    @include breakpoint(tablet-m) {
      gap: 24px;
      padding: 0;
      border: none;
      padding-bottom: 12px;
    }

    &__items {
      @extend %flex-between;
      flex: 1;
      gap: 16px;

      @include breakpoint(wide) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      @include breakpoint(tablet-m) {
        gap: 24px;
      }
    }

    &__footer {
      @extend %flex-center;

      &__file {
        flex-direction: row !important;
        align-items: center !important;
        gap: 16px;
        margin: 0 auto;
        height: 112px;
        max-width: 100%;
        border-radius: 4px;
        background: $platinumGray;
        border: 2px dashed rgba(204, 204, 204, 0.8);

        &__replace {
          bottom: 0 !important;
        }

        & > div {
          height: 100% !important;

          img {
            height: 100% !important;
            object-fit: fill !important;
          }
        }

        p {
          @extend %Circular-Std-25-bold;
          color: $dusk_slate;
          background-color: transparent;

          @include breakpoint(tablet-l) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

// .description {
//   // color: $enigmatic-midnight;
//   // font-family: $circular-reg;
//   margin-bottom: 5px;
//   margin-left: 20px;
//   margin-top: 10px;
// }