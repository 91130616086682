@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  width: 100%;
  height: 100%;

  &__head {
    @extend %flex-column;
    gap: 40px;

    &__info {
      @extend %flex-align;

      @include breakpoint(tablet-l) {
        padding-left: 40px;
      }

      @include breakpoint(mobile) {
        padding-left: 20px;
        flex-direction: column;
        align-items: flex-start;
      }

      &__label {
        @extend %Circular-Std-25-bold;
        width: fit-content;
        white-space: nowrap;
      }

      &__title {
        @extend %Circular-Std-25-bold;
        font-size: 36px;
        width: 100%;
        text-align: center;

        @include breakpoint(tablet) {
          font-size: 28px;
        }
        @include breakpoint(mobile) {
          text-align: start;
        }
      }
    }

    &__controls {
      @extend %flex-end;
      gap: 24px;

      @include breakpoint(tablet-l) {
        padding-right: 40px;
      }

      @include breakpoint(mobile) {
        padding-right: 20px;
      }
    }
  }

  &__footer {
    @extend %flex-end;
    padding: 20px 0;
  }

  @include breakpoint(tablet-l) {
    background: $whispering_gray;
  }

  &__title {
    @extend %Circular-Std-35-bold;
    color: $midnightBlack !important;
    padding-bottom: 15px;

    @include breakpoint(tablet-l) {
      padding-left: 40px;
    }

    @include breakpoint(mobile) {
      padding-left: 20px;
    }
  }

  &__content {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include breakpoint(tablet-l) {
      padding: 0 40px;
      gap: 10px;
    }

    @include breakpoint(mobile) {
      padding: 0 20px;
    }

    &__box {
      display: flex;
      gap: 16px;

      &_check {
        path {
          fill: white;
        }
      }

      @include breakpoint(tablet-l) {
        gap: 10px;
      }

      button {
        padding: 12px 16px;
        border-radius: 5px;
        background-color: $dark_azure;

        @include breakpoint(wide-xl) {
          padding: 8px 10px;
        }

        @include breakpoint(tablet-l) {
          min-width: 40px;
        }

        @include breakpoint(mobile) {
          padding: 8px 10px;
        }

        &:hover {
          background-color: $dark_azure;
          opacity: 0.8;
        }
      }

      &__title {
        @extend %Circular-Std-12-reg;
        text-transform: capitalize;
        color: white !important;
      }

      &__invite {
        @extend %Circular-Std-14-reg;
        text-transform: capitalize;
        color: $snowWhite !important;

        @include breakpoint(wide-xl) {
          display: none;
        }
      }
    }

    &__live {
      display: flex;
      margin: 20px 0;
      border-radius: 10px;
      overflow: hidden;

      @include breakpoint(tablet-l) {
        margin: 20px 40px;
      }

      @include breakpoint(mobile) {
        margin: 30px 20px;
      }

      &__members {
        @extend %flex-column;
        max-width: 200px;
        width: 100%;
        padding: 8px;
        gap: 10px;
        background-color: black;
        overflow-y: scroll;
        height: calc(100dvh - 300px);

        @include breakpoint(tablet-l) {
          height: calc(100dvh - 340px);
        }

        @include breakpoint(tablet-m) {
          height: calc(100dvh - 380px);
        }
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;

      @include breakpoint(tablet-l) {
        padding: 0 40px;
        flex-direction: column-reverse;
        gap: 10px;
      }

      @include breakpoint(mobile) {
        padding: 0 20px;
      }

      &__controls {
        @extend %flex-align;
        gap: 16px;
      }

      &__copy {
        position: relative;

        &__button {
          border: 1px dashed #ccc;
          border-radius: 5px;
          padding: 11px 8px;
          color: $midnightBlack;

          span {
            margin-left: 0;
          }
        }
        &__tooltip {
          position: absolute;
          z-index: 55;
          bottom: 47px;
          padding: 12px 16px;
          border-radius: 8px;
          background: $paleBlue;
          border: 1px dashed #ccc;
        }
      }

      &__mute {
        min-width: max-content !important;

        span {
          margin: 0;
        }
      }
    }

    &__next {
      display: flex;
      gap: 24px;
      height: 50px;

      @include breakpoint(wide-xl) {
        gap: 12px;

        @include breakpoint(tablet) {
          flex-direction: column;
          align-items: flex-end;
        }

        button {
          // padding: 0 !important;
          min-width: 50px;

          &:last-child {
            span {
              margin-left: 0 !important;
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }

  &__invitation {
    width: 100%;

    @include breakpoint(tablet-l) {
      padding: 0 20px 20px;
    }

    &__box {
      @extend %flex-center-column;
      gap: 4px;
      height: 173px;
      cursor: pointer;
      max-width: 272px;
      border-radius: 5px;
      margin-bottom: 12px;
      background: $paleBlue;

      @include breakpoint(tablet-m) {
        max-width: 100%;
      }

      &__text {
        @extend %Circular-Std-16-regular;
        color: $midnightBlack !important;
      }
    }
    &__previews {
      padding-top: 24px;
      border-top: 0.5px dashed #ccc;

      &__title {
        @extend %Circular-Std-14-bold;
        color: $midnightBlack !important;
      }
    }
    &__videos {
      gap: 24px;
      display: grid;
      padding-top: 24px;
      grid-template-columns: repeat(4, minmax(0, 1fr));

      @include breakpoint(wide) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }

      @include breakpoint(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @include breakpoint(tablet-m) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }
}

.containero {
  width: 100%;
  z-index: 11;
  padding: 8px;
  overflow-x: auto;
  max-width: 100%;
  position: absolute;
  white-space: nowrap;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.6);

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__wrapper {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
  }

  &__items {
    display: flex;
    gap: 12px;
    min-width: min-content;
  }

  &__footer {
    @extend %flex-between;
    width: 100%;
    position: absolute;
    z-index: 10;
    bottom: 24px;
    padding: 24px 22px 0;

    &__date {
      @extend %Circular-Std-14;
      color: $white !important;
    }
  }
}

.video {
  position: relative;
  height: 659px;

  &_player {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: unset;
    width: 100%;
    height: 659px;
    background-color: black;

    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
