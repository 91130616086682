@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-justify-between;
  gap: 24px;

  &__content {
    @extend %flex-column;
    gap: 24px;
    margin-bottom: 24px;
    width: 100%;
  }

  &__top {
    @extend %flex-between;
    gap: 24px;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    &__field {
      flex: 1;
      @include breakpoint(tablet) {
        width: 100%;
      }
    }

    &__name {
      flex: 2;
      width: 100%;
      position: relative;

      @include breakpoint(tablet) {
        flex: 1;
      }

      &__text {
        @extend %Circular-Std-12;
        position: absolute;
        color: rgb(231, 1, 1) !important;
        bottom: -15px;
      }
    }
  }

  svg {
    cursor: pointer;
  }
}
