@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;

  height: calc(100vh - 119px - 130px - 200px);

  @include breakpoint(tablet-m) {
    justify-content: space-between;
    height: calc(100dvh - 132px);
  }

  &__main {
    flex: 1 1 auto;
  }

  &__footer {
    max-width: 470px;
    margin: 0 auto;
    width: 100%;

    &__btn {
      width: 100%;
      max-width: 100%;
      padding: 18px 141px;
      font-family: $circularBold;
      background-color: $tietiary;
      border: 1px solid $sapphire;

      border-radius: 10px;
      line-height: normal !important;

      @include breakpoint(tablet-m) {
        padding: 18px;
      }

      svg {
        width: 16px;
        height: 16px;
      }

      span {
        line-height: 17.71px;
        font-size: 14px;
        margin-left: 0px;
      }
    }
  }

  &__enroll {
    @extend %flex-center;
    @extend %Circular-Std-14;
    gap: 4px;

    margin-top: 10px;
    color: $deepCharcoalBlue;

    @include breakpoint(tablet-l) {
      margin-top: 55px;
    }

    &__text {
      font-family: $circularRegular !important;
    }

    &__link {
      min-width: 0;
      text-transform: none;
      text-underline-offset: 3px;
      text-decoration: underline !important;
      font-family: $circularMedium !important;
    }

    a {
      padding: 0 !important;
      display: block;
      text-decoration: none !important;
    }
  }
}
