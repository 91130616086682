@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  border-radius: 5px;
  position: relative;
  max-width: 500px;
  width: 100%;
  cursor: pointer;

  @include breakpoint(tablet-m) {
    width: 100%;
  }

  &__header {
    @extend %flex-between;
    width: 100%;
    padding: 8px 16px;
    border: 1px solid $subtle_blue;
    background-color: $neutral-gray;
    border-radius: 8px;
    position: relative;

    &__label {
      position: absolute;
      top: -14px;
      color: $dusk_slate !important;
      font-size: 8px;
      font-weight: 700;
      font-family: $circularBold;
      letter-spacing: 0.032px;
    }

    &_active {
      border-radius: 10px 10px 0px 0px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 4px 0px 10px 0px rgba(0, 0, 0, 0.05);
    }

    &__title {
      @extend %Circular-Std-16-reg;
      color: $dusk_slate !important;
    }

    &__icon {
      &_active {
        transform: rotate(180deg);
      }
    }
  }

  &__content {
    border-radius: 5px;
    background-color: $snowWhite;
    position: absolute;
    z-index: 111;
    width: 100%;

    &__header {
      @extend %flex-between;
      @extend %Circular-Std-25-bold;
      padding: 8px 16px;
      border-radius: 10px 10px 0px 0px;
      border-bottom: 1px solid $sapphire;
      background: $platinumGray;
      overflow: hidden;

      &__icon {
        transform: rotate(180deg);
      }
    }

    &__body {
      padding: 8px;
    }

    &__search {
      margin-bottom: 7px;

      width: 100%;
    }

    &__list {
      height: 151px;
      overflow-y: scroll;
      padding-bottom: 20px;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &__user {
        @extend %flex-align;

        &__name {
          @extend %text-ellipsis;
          @extend %Circular-Std-16-light;
        }
      }
    }
    &__buttons {
      @extend %flex-end;
      gap: 16px;
      padding-top: 8px;
      padding-right: 4px;
      text-transform: capitalize;

      &__apply {
        text-transform: capitalize;
      }
      &__cancel {
        text-transform: capitalize;
      }
    }
  }
}
