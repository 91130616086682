@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px;
  }

  @include breakpoint(tablet-l) {
    padding: 0 16px;
  }

  &__head {
    &__back {
      margin-bottom: 8px;
      padding: 0 !important;
      justify-content: flex-start;

      span {
        color: black;
        padding: 0 !important;
      }
    }

    &__navigation {
      margin-bottom: 40px;

      @include breakpoint(tablet) {
        margin-bottom: 10px;
      }
    }

    &__search {
      @extend %flex-between;
      margin-bottom: 5px;

      &__name {
        @extend %Circular-Std-25-bold;
      }

      &__controls {
        @extend %flex-align;
        gap: 24px;

        @include breakpoint(desktop) {
          flex-direction: column;
          position: absolute;
          right: 0;
          background-color: white;
          z-index: 10;
          padding: 10px;
          align-items: flex-start;
          display: none;
          border-radius: 5px;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
        }

        &_active {
          display: flex;
          gap: 5px;

          button {
            justify-content: flex-start;

            &:hover {
              box-shadow: none !important;
              background-color: $sapphire;
              color: white !important;
              border-radius: 2px;
            }
          }
        }

        button {
          text-transform: capitalize;
        }
      }

      @include breakpoint(tablet) {
        margin-bottom: 10px;
      }
    }

    &__description {
      @extend %flex-align;

      @include breakpoint(tablet-l) {
        gap: 16px;
      }

      @include breakpoint(tablet) {
        gap: 5px;
        align-items: flex-start;
        flex-direction: column;
      }

      &__title {
        @extend %Circular-Std-12;
        @extend %text-ellipsis;
        font-family: $circularRegular !important;
        padding: 10px 8px;
        color: $tableCellGray !important;

        @include breakpoint(tablet-l) {
          font-size: 14px;
        }
      }

      &__subtitle {
        @extend %Circular-Std-16-regular;
        @extend %flex-align;
        padding: 10px 8px;
        color: $sapphire !important;

        @include breakpoint(tablet-l) {
          font-size: 14px;
        }
      }
    }
  }

  &__navigation {
    @extend %flex-align;
    margin: 12px 0 21px;

    min-width: max-content;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__btn {
      @extend %flex-align;
      cursor: pointer;
      font-family: $circularRegular;
      gap: 4px;
      margin-left: 10px;
      font-size: 14px;
    }

    @include breakpoint(tablet) {
      margin: 20px 0;
    }

    &__edit {
      path {
        stroke: $sapphire;
      }
    }

    &__add {
      span {
        font-size: 12px;
        color: black;
      }

      path {
        stroke: black;
      }
    }
  }
}
