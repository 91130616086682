@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  padding: 16px 0;

  &_start {
    align-items: flex-start;
  }

  &__approved {
    @extend %Circular-Std-12-light;
    color: rgba(34, 34, 34, 0.5) !important;
  }

  &__info {
    @extend %flex-column;
    gap: 8px;

    &__title {
      @extend %Circular-Std-14;
    }

    &__view {
      @extend %Circular-Std-12-light;
      color: $sapphire !important;
      cursor: pointer;
    }

    &__time {
      @extend %Circular-Std-12-light;

      color: rgba(34, 34, 34, 0.5) !important;
    }
  }

  &__update {
    @extend %flex-align;
    gap: 8px;

    &__decline {
      max-width: 66px;
      min-width: 66px;
      padding: 8px 0;
      color: $flame_orange;
      border-radius: 5px;
      border: 1px solid #fee7e0;

      span {
        @extend %Circular-Std-14-med;
        text-transform: capitalize;
      }
    }

    &__approve {
      max-width: 72px;
      min-width: 72px;

      padding: 8px 0;
      border-radius: 5px;
      border: 1px solid #fee7e0;
      color: $white;
      background-color: $verdant_green;

      &:hover {
        background-color: $verdant_green;
        color: $white;
      }

      span {
        @extend %Circular-Std-14-med;

        text-transform: capitalize;
      }
    }
  }
}
