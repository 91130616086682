@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  height: 313px;
  max-width: 100%;
  display: flex;
  gap: 1px;
  position: relative;
  cursor: pointer;

  &__slider {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  &__item {
    width: 100%;
    position: relative;

    &:nth-child(1) {
      grid-area: first;
    }
    &:nth-child(2) {
      grid-area: second;
    }
    &:nth-child(3) {
      grid-area: three;
    }
    &:nth-child(4) {
      grid-area: four;
    }
    &:nth-child(5) {
      grid-area: five;
    }

    img {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }
  }

  &__three {
    display: grid;
    gap: 1px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 193px 120px;

    grid-template-areas:
      'first first'
      'second three';
  }

  &__four {
    display: grid;
    grid-template-areas:
      'first second'
      'three four';

    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-template-rows: 193px 120px;
  }

  &__five {
    display: grid;
    grid-template-rows: 193px 120px;
    grid-template-columns: repeat(12, 1fr);
    grid-auto-flow: dense;

    .container__item {
      position: relative;

      &:nth-child(1) {
        grid-area: inherit;
        grid-column: span 6;
      }

      &:nth-child(2) {
        grid-area: inherit;
        grid-column: span 6;
      }

      &:nth-child(3) {
        grid-area: inherit;
        grid-column: span 4;
      }
      &:nth-child(4) {
        grid-area: inherit;
        grid-column: span 4;
      }
      &:nth-child(5) {
        grid-area: inherit;
        grid-column: span 4;
      }
    }
  }

  &__layout {
    @extend %flex-center;
    @extend %Circular-Std-35-reg;
    position: absolute;
    right: 0;
    bottom: 0;
    background: rgba(34, 34, 34, 0.8);
    width: 100%;
    height: 100%;
    color: $white;
  }
}
