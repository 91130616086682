@import '../../../../../../styles/vars.scss';
@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/fonts.scss';
.container {
  @extend %flex-justify-between;
  gap: 24px;
  padding-bottom: 24px;

  @include breakpoint(tablet-l) {
    flex-direction: column;
  }

  &__content {
    @extend %flex-column;
    width: 100%;
    gap: 12px;
  }

  &__file {
    flex-direction: column-reverse !important;
    height: 238px;
    max-width: 264px;

    &__content {
      max-width: 264px;
      height: 238px;
    }
  }
}
