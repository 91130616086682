@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  @extend %flex-center-column;
  border-radius: 8px;
  position: relative;
  border: 1px solid #d6d6d6;
  background-color: $light_grayish_silver;
  padding: 24px;
  gap: 8px;

  &__download {
    @extend %flex-center;
    gap: 8px;
    cursor: pointer;
    font-size: 14px;
    color: $obsidianAura;

    border-bottom: 2px solid $obsidianAura;
    padding: 8px;

    &_disabled {
      opacity: 0.5;
      cursor: auto !important;

      svg {
        cursor: auto !important;
      }
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }

  &__title {
    font-size: 14px;
    line-height: normal;
    color: $black;
    font-family: $circularRegular;
  }
}
