@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.wrapper {
  @extend %flex-center;
  margin: 60px 0;
}
.container {
  @extend %flex-center;
  flex-direction: column;
  width: 150px;
  height: 150px;
  border: 0.5px solid $lightGray;
  border-radius: 4px;
  background: $snowWhite;
  cursor: pointer;

  &__icon {
    margin-bottom: 5px;
  }

  &__title {
    color: $shadowed-slate;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.035px;
  }

  &__close {
    @extend %flex-center;

    width: 24px;
    height: 24px;
    background-color: $white;
    border-radius: 5px;
  }

  &__box {
    position: relative;
    display: flex;
    width: 100%;
    cursor: pointer;
    max-width: 150px;
    border-radius: 5px;
    overflow: hidden;
  }

  &__filled {
    @extend %flex-column-between;
    align-items: flex-end;
    position: absolute;
    right: 8.5px;
    top: 8px;
  }

  &__replace {
    @extend %flex-center;
    position: absolute;
    bottom: 16px;
    width: 100%;

    &_box {
      @extend %flex-align;
      border-radius: 4px;
      background: $snowWhite;
      padding: 4px;
      gap: 4px;
    }
  }
}
