@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 35px 70px;

  svg {
    cursor: pointer;
  }

  @include breakpoint(desktop) {
    padding: 35px 26px;
  }

  @include breakpoint(tablet-m) {
    padding: 20px;
  }
}
