@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.vds-controls {
  --media-controls-padding: 0px;
  --media-controls-in-transition: opacity 0.2s ease-in;
  --media-controls-out-transition: opacity 0.2s ease-out;
}

.vds-controls-group-last {
  background-color: $midnight_charcoal;
  padding: 16px 24px;
  display: flex;
  gap: 24px;
  justify-content: space-between;
  align-items: center;

  &-left {
    @extend %flex-align;
    gap: 24px;
  }

  &-right {
    @extend %flex-align;
    gap: 24px;
  }
}

.media-player {
  /* Player CSS variables (defaults shown). */
  --media-font-family: sans-serif;
  --media-controls-color: #f5f5f5;
  --media-focus-ring: 0 0 0 3px rgb(78 156 246);
}

.vds-button {
  display: flex;
  justify-content: center;
  align-items: center;
  /* CSS variables available for customization (defaults shown). */
  --media-button-color: var(--media-controls-color, #f5f5f5);
  --media-button-size: 24px;
  --media-button-padding: 0px;
  --media-button-border-radius: 8px;
  --media-button-hover-bg: rgb(255 255 255 / 0.2);
  --media-button-hover-transform: scale(1);
  --media-button-hover-transition: transform 0.2s ease-in;
  --media-button-touch-hover-border-radius: 100%;
  --media-button-touch-hover-bg: rgb(255 255 255 / 0.2);

  svg {
    width: 24px;
    height: 24px;
  }
}

.vds-button[data-paused] .pause-icon,
.vds-button:not([data-paused]) .play-icon {
  display: none;
}

.vds-button:not([data-muted]) .mute-icon,
.vds-button:not([data-state='low']) .volume-low-icon,
.vds-button:not([data-state='high']) .volume-high-icon {
  display: none;
}

.vds-button[data-active] .pip-enter-icon,
.vds-button:not([data-active]) .pip-exit-icon {
  display: none;
}

.vds-button[data-active] .fs-enter-icon,
.vds-button:not([data-active]) .fs-exit-icon {
  display: none;
}

.vds-slider {
  /* CSS variables available for customization (defaults shown). */
  --media-slider-width: 100%;
  --media-slider-height: 48px;

  --media-slider-track-width: 100%;
  --media-slider-track-height: 5px;
  --media-slider-track-bg: rgb(255 255 255 / 0.3);
  --media-slider-track-fill-bg: var(--media-brand, #f5f5f5);
  --media-slider-track-border-radius: 2px;
  --media-slider-focused-track-width: var(--media-slider-track-width);
  --media-slider-focused-track-height: calc(var(--media-slider-track-height) * 1.25);

  --media-slider-thumb-size: 15px;
  --media-slider-thumb-border: 1px solid #cacaca;
  --media-slider-thumb-border-radius: 9999px;
  --media-slider-thumb-bg: #fff;
  --media-slider-thumb-transition: opacity 0.2s ease-in, box-shadow 0.2s ease;
  --media-slider-focused-thumb-size: calc(var(--media-slider-thumb-size) * 1.1);
  --media-slider-focused-thumb-shadow: 0 0 0 4px hsla(0, 0%, 100%, 0.4);
}

.vds-time-group {
  /* CSS variables available for customization (defaults shown). */
  --media-time-divider-gap: 2.5px;
  --media-time-divider-color: #e0e0e0;
  --media-time-font-size: 14px;
  --media-time-font-weight: 400;
  --media-font-family: sans-serif;
  --media-time-color: #f5f5f5;
  --media-time-bg: unset;
  --media-time-border-radius: unset;
  --media-time-letter-spacing: 0.025em;
  --media-time-border: unset;
}
