@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container__content__item {
  // display: flex;
  // align-items: center;  /* Align icon and h1 vertically */
}

.icon {
  margin-top: 20px; 
  width: 30px; /* adjust the size of the icon */
  height: auto;
  margin-right: 10px;
  /* Add any other styles you want for the icon */
}

h1.container__content__item {
  // Styles for the h1 title
  font-size: 1.5rem;  // Adjust the font size as needed
  font-weight: bold;  // Adjust the font weight as needed
  margin-bottom: 40px;  // Adjust the margin as needed
  margin-top: 30px; 
  border-bottom: 0.6px solid #4d4c4c;  // Add a 2px solid line below the h1
  padding-bottom: 5px; 
  width: 86.5%; // Adjust the padding as needed
  display: inline-block;
}


span.container__content__item {
  // Styles for the span elements
  display: inline-block;  // Display spans side by side
  margin-right: 100px;
  margin-left: 10px;
  margin-bottom: 30px;  // Adjust the margin between spans as needed
  width: 40%;  // Set the width to 40%
}

/* Add more specific styles for ControlledInput and ControlledSelect if needed */

.container__footer {
  display: flex;
  justify-content: center;
  // justify-content: space-between;
  margin-top: 10px;  // Adjust the margin as needed
  margin-left: -50px;
}

.container__content__submit {
  cursor: pointer;
  margin: 0 10px;
  // Add more specific styles for the Button component if needed
}

/* Position "Cancel" button to the left */
.container__content__submit:first-child {
  order: 2;
}

.header {
  margin-bottom: 20px; /* Adjust the margin as needed */
  display: flex; /* Add display:flex to enable alignment */
  justify-content: space-between; /* Add space between h6 and h5 */
  align-items: center; /* Center items vertically */
}

.header h6 {
  font-size: 18px;
  font-weight: bold;
  color: #333; /* Change the color as needed */
}

.header h5 {
  font-size: 16px;
  font-weight: bold;
  color: #666; /* Change the color as needed */
  margin-right: 130px; /* Move h5 to the right */
}
/* Position "Generate Estimate" button to the right */
.container__content__submit:last-child {
  order: 1;
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Bring the icon behind the text */
}
