@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    margin: 0 20px;
  }

  &__top {
    @extend %Circular-Std-25-bold;
    color: black !important;
    font-size: 35px;
    margin-bottom: 42px;
  }

  &__header {
    @extend %flex-end;

    &__copy {
      position: relative;

      &__button {
        color: $sapphire;
        min-width: unset !important;

        span {
          font-size: 14px;
          margin-right: 0;
          font-family: $circularMedium !important;
          letter-spacing: 0.175px;
          min-width: unset !important;
        }
      }
      &__tooltip {
        position: absolute;
        z-index: 55;
        top: 47px;
        padding: 12px 16px;
        border-radius: 8px;
        background: $paleBlue;
        border: 1px dashed #ccc;
      }
    }
  }

  &__connecting {
    @extend %flex-center-column;
    max-height: calc(100dvh - 168px);
    background-color: $paleBlue;
    border-radius: 16px;
    margin-top: 20px;
    margin-bottom: 20px;

    width: 100%;
    gap: 24px;
    padding: 95px 0px;

    &__button {
      @extend %Circular-Std-16-bold;
      font-size: 14px;
      line-height: 17.71px;
      text-transform: uppercase;
      letter-spacing: 1.25%;
      border-radius: 10px;
      color: $sapphire !important;
    }

    &__text {
      @extend %Circular-Std-49-bold;
      color: $midnightBlack !important;

      @include breakpoint(tablet-l) {
        font-size: 25px;
      }

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }
  }
}

.bouncing {
  display: flex;
  justify-content: center;
  margin: 40px auto;

  @include breakpoint(mobile) {
    margin: 24px auto;
  }

  &__loader {
    width: 12px;
    height: 12px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: $sapphire;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}
