@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;

  &__wrapper {
    @extend %flex-column-between;
    gap: 32px;
    height: calc(100dvh - 136px);
  }

  &__header {
    display: flex;
    gap: 8px;

    svg {
      min-width: 24px;
    }

    &__title {
      @extend %Circular-Std-25-bold;
      color: $midnightBlack !important;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__actions {
      @extend %flex-align;
      gap: 24px;

      &__item {
        @extend %Circular-Std-14-reg;
        padding: 11px 24px;
        border-radius: 5px;
        background: $paleBlue;
        text-transform: capitalize;

        &:hover {
          background: #b8c6dd;
        }
      }
    }
  }

  &__users {
    @extend %flex-column;
    gap: 8px;

    &__box {
      display: flex;
      gap: 16px;
    }

    &__members {
      @extend %flex-align;
      flex-wrap: wrap;
      gap: 16px;

      &__item {
        @extend %flex-align;
        flex-direction: column;

        img {
          width: 36.5px;
          height: 36.5px;
          object-fit: cover;
          border-radius: 36px;
        }
      }
    }

    &__header {
      display: flex;
      gap: 8px;

      &__title {
        @extend %Circular-Std-21;
        color: $midnightBlack !important;

        &__count {
          @extend %Circular-Std-21;
          color: $midnightBlack !important;
        }
      }
    }

    &__content {
      @extend %flex-align;
      flex-direction: column;

      &__name {
        @extend %text-ellipsis;
        max-width: 150px;
      }

      img {
        width: 59px;
        height: 59px;
        object-fit: cover;
        border-radius: 32px;
        margin-bottom: 8px;
      }

      p {
        font-size: 12px !important;
        font-weight: 300 !important;
        letter-spacing: 0.048px !important;
        color: rgba(34, 34, 34, 0.5) !important;
        font-family: $circularRegular !important;
      }
    }
  }

  &__tags {
    @extend %flex-column;
    gap: 8px;

    &__content {
      gap: 8px;
      display: flex;
      align-items: flex-start;

      &__item {
        gap: 8px;
        display: flex;
        flex-wrap: wrap;

        div {
          margin-right: 0 !important;
        }
      }
    }

    &__header {
      display: flex;
      gap: 8px;

      &__title {
        @extend %Circular-Std-21;
        color: $midnightBlack !important;

        &__count {
          @extend %Circular-Std-21;
          color: $midnightBlack !important;
        }
      }
    }
  }

  &__details {
    @extend %flex-column;
    gap: 24px;

    &__title {
      @extend %Circular-Std-21;
      color: $midnightBlack !important;
    }

    &__description {
      overflow-y: scroll;
      max-width: 470px;
      max-height: 126px;
      min-height: 126px;
      height: 100%;
      border-radius: 10px;
      border: 1px solid $subtle_blue;
      background: #fff;
      padding: 10px 16px;

      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      &__content {
        @extend %Circular-Std-14;
        white-space: normal;
        word-break: break-word;
        overflow: scroll;

        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
  &__actions {
    @extend %flex-end;
    gap: 24px;
    padding-bottom: 40px;

    &__item {
      max-height: 40px;
      min-height: 40px;
      height: 100%;
      border-radius: 5px;
      text-transform: capitalize;
    }
  }
}

.dropdown {
  @extend %flex-column;
  gap: 21px;
  padding: 24px;
  border-radius: 10px;
  background: $snowWhite;
  border: 0.5px dashed $gray;

  &_item {
    @extend %flex-align;
    @extend %Circular-Std-14-reg;
    gap: 8px;
    cursor: pointer;

    &:hover {
      color: $sapphire;
    }
  }
}
