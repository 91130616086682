@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 100%;

  padding: 24px;
  background: $snowWhite;
  border-radius: 4px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);

  @include breakpoint(desktop) {
    max-width: unset;
  }

  @include breakpoint(tablet) {
    padding: 18px;
    max-width: 100%;
  }

  &__edit__drawer {
    padding: 15px;
  }

  &__head {
    @extend %flex-between;
    padding-bottom: 12px;
    gap: 8px;

    &__icon {
      min-width: 27px;
      min-height: 27px;
      cursor: pointer;
    }

    &__title {
      @extend %Circular-Std-21-bold;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include breakpoint(wide) {
        font-size: 18px;
        max-width: 200px;
      }

      @include breakpoint(desktop) {
        max-width: 320px;
      }

      @include breakpoint(tablet-l) {
        max-width: 600px;
      }

      @include breakpoint(tablet) {
        max-width: 450px;
      }

      @include breakpoint(tablet-m) {
        max-width: 360px;
      }

      @include breakpoint(mobile) {
        max-width: 200px;
      }
    }
  }

  &__chip {
    @extend %flex-align;
    max-width: 300px;
    overflow-y: scroll;
    padding-bottom: 12px;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include breakpoint(wide) {
      max-width: 270px;
    }
    @include breakpoint(desktop) {
      max-width: 380px;
    }

    @include breakpoint(tablet-l) {
      max-width: 600px;
    }

    @include breakpoint(tablet) {
      max-width: 476px;
    }

    @include breakpoint(tablet-m) {
      max-width: 400px;
    }

    @include breakpoint(mobile) {
      max-width: 270px;
    }

    @include breakpoint(mobile-m) {
      max-width: 250px;
    }

    svg {
      min-width: 24px;
      max-width: 24px;
      min-height: 24px;
      max-height: 24px;
      margin-right: 8px;
      cursor: default;
    }

    &__item {
      @extend %flex-align;
      max-height: 22px;

      & > div {
        max-height: 22px;
      }
    }
  }

  &__description {
    @extend %Circular-Std-14-reg;
    margin-bottom: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $deepCharcoalBlue !important;

    @include breakpoint(tablet-l) {
      max-width: 720px;
    }

    @include breakpoint(tablet) {
      max-width: 476px;
    }

    @include breakpoint(tablet-m) {
      max-width: 400px;
    }

    @include breakpoint(mobile) {
      max-width: 270px;
    }

    @include breakpoint(mobile-m) {
      max-width: 250px;
    }

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__users {
    @extend %flex-align;
    flex-wrap: wrap;
    padding-left: 8px;
    padding-bottom: 12px;
    position: relative;
    max-height: 80px;
    overflow-y: auto;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &__plus {
      @extend %flex-center;
      @extend %Circular-Std-12-bold;
      z-index: 4;
      width: 32px;
      height: 32px;
      margin-left: -8px;
      border-radius: 50%;
      border: 1px solid #fff;
      background-color: $light_steel_gray;
      cursor: pointer;
    }

    &__item {
      margin-left: -8px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 4px 0px 10px 0px rgba(0, 0, 0, 0.05);

      &_number {
        cursor: pointer;
        color: $black;
        margin-left: -8px;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 4px 0px 10px 0px rgba(0, 0, 0, 0.05);
      }
    }
  }
}

.dropdown {
  @extend %flex-column;
  gap: 21px;
  padding: 24px;
  border-radius: 10px;
  background: $snowWhite;
  border: 0.5px dashed $gray;

  &_item {
    @extend %flex-align;
    @extend %Circular-Std-14-reg;
    gap: 8px;
    cursor: pointer;
    width: max-content;

    &:hover {
      color: $sapphire;
    }
  }
}
