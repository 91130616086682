@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 12px;
  margin-bottom: 12px;

  @include breakpoint(tablet-l) {
    margin-bottom: 22px;
  }

  &__title {
    @extend %flex-align;
    @extend %Circular-Std-25-bold;
    color: $black !important;
    text-decoration: underline;
    text-underline-offset: 3px;
    @include breakpoint(tablet-m) {
      font-size: 20px;
    }
  }

  &__subtitle {
    @extend %Circular-Std-12-reg;
    font-style: italic;
    color: $tableCellGray !important;
  }
}
