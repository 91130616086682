@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 16px;

  &__name {
    text-align: right;

    &__title {
      @extend %Circular-Std-21-bold;
      @extend %text-ellipsis;
      max-width: 200px;

      @include breakpoint(tablet-l) {
        font-size: 14px;
      }
    }

    &__subtitle {
      @extend %Circular-Std-14-reg;
      text-transform: capitalize;
      color: $staleGray !important;

      @include breakpoint(tablet-l) {
        font-size: 12px;
      }
    }
  }

  &__user {
    @extend %flex-align;
    gap: 16px;
    cursor: pointer;
    position: relative;
    background-color: transparent;

    @include breakpoint(tablet-l) {
      flex-direction: row-reverse;
      gap: 4px;
    }

    &__icon {
      transition: 0.3s;

      @include breakpoint(tablet-l) {
        width: 8px;
        height: 8px;
      }

      &_active {
        transform: rotate(180deg);
      }
    }

    &__menu {
      @extend %flex-column;
      position: absolute;
      z-index: 111;
      min-width: 226px;
      max-width: 226px;
      height: auto;
      right: 0;
      bottom: -180px;
      visibility: hidden;
      background-color: white;
      opacity: 0;
      transition: 0.3s;

      @media (min-width: $large-d) {
        bottom: -200px;
      }

      @include breakpoint(tablet-l) {
        bottom: -200px;
        height: auto;
      }

      div {
        font-family: $circularRegular;
        white-space: nowrap;
        transition: 0.3s;
        border-radius: 4px;

        &:hover {
          background-color: $cottonCloud;
        }
      }

      &_active {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
