@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  border: 1px solid $sapphire;
  border-radius: 10px 10px 0px 0px;
  width: 230px;
  height: 300px;
  position: relative;

  &__header {
    @extend %flex-between;
    width: 100%;
    padding: 8px 16px;
    background-color: $platinumGray;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 1px solid $sapphire;

    &__title {
      @extend %Circular-Std-16-light;
    }

    &__icon {
      transform: rotate(180deg);
    }
  }

  &__content {
    background-color: $white;
    overflow: scroll;
    height: 200px;
    padding-bottom: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__search {
      max-width: 220px;
      width: 100%;
      margin: 10px 4px 0 4px;
    }

    &__list {
      padding-bottom: 24px;

      &__user {
        @extend %flex-align;
        padding: 9px 7px 9px 0;

        &__name {
          @extend %Circular-Std-16-light;
        }
      }
    }
    &__buttons {
      @extend %flex-end;
      gap: 16px;
      padding-right: 12px;
      padding-bottom: 16px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      background-color: $white;
      z-index: 10;
    }
  }
}
