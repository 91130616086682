@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  display: flex;
  gap: 16px;
  transition: 0.4s;

  &_reply {
    &_remove {
      display: none;
    }
  }

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_avatar {
    &_icon {
      border-radius: 50% !important;
      border: 3px solid #e1f1f4;
    }
  }

  &_comment {
    padding: 12px 16px;
    width: 100%;
    border-radius: 10px;
    border: 0.5px solid $subtle_blue;
    background: $frostBlue;
    margin-bottom: 16px;

    &_parent {
      width: 100%;
    }

    &_head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 9px;

      &_title {
        &_name {
          @extend %Circular-Std-14-reg;
          color: $black !important;
        }

        &_role {
          @extend %Circular-Std-12-reg;
          color: $graphiteGray !important;
          text-transform: capitalize;
        }
      }

      &_options {
        display: flex;
        gap: 17px;

        p {
          @extend %Circular-Std-12-light;
          color: $silverSlate !important;
        }
      }
    }

    &_action {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;

      &_block {
        display: flex;
        align-items: center;

        &_reply {
          @extend %Circular-Std-14-reg;
          cursor: pointer;
          color: $gunmetalGray !important;
          letter-spacing: 0.035px;
          background-color: transparent;

          &_btn {
            padding-bottom: 16px;
            margin-bottom: 0 !important;
          }
        }

        p {
          font-size: 14px !important ;
          font-weight: 500 !important ;
          letter-spacing: 0.035px !important ;
          margin-right: 4px;
        }

        &_divider {
          background-color: $staleGray;
          margin: 0 8px;
          width: 1px;
          height: 11px;
        }

        &_likes {
          p {
            color: $gunmetalGray !important ;
            font-family: $circularRegular !important ;
            font-size: 14px !important ;
            font-weight: 500 !important ;
            letter-spacing: 0.035px !important ;
          }
        }
      }
    }
  }
}
.wrapper {
  &__replies {
    margin-left: 59px;

    & .wrapper__replies {
      margin-left: 0;
    }
  }
}
