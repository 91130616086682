@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;

  &__icon {
    position: absolute;
    top: -7px;
    right: -10px;
    z-index: 2;
    cursor: pointer;
  }
}
