@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container__content__item {
  // display: flex;
  // align-items: center;  /* Align icon and h1 vertically */
}

.icon {
  margin-top: 20px; 
  width: 30px; /* adjust the size of the icon */
  height: auto;
  margin-right: 10px;
  /* Add any other styles you want for the icon */
}

h1.container__content__item {
  // Styles for the h1 title
  font-size: 1.5rem;  // Adjust the font size as needed
  font-weight: bold;  // Adjust the font weight as needed
  margin-bottom: 40px;  // Adjust the margin as needed
  margin-top: 30px; 
  border-bottom: 0.6px solid #4d4c4c;  // Add a 2px solid line below the h1
  padding-bottom: 5px; 
  width: 86.5%; // Adjust the padding as needed
  display: inline-block;
}


span.container__content__item {
  // Styles for the span elements
  display: inline-block;  // Display spans side by side
  margin-right: 100px;
  margin-left: 10px;
  margin-bottom: 30px;  // Adjust the margin between spans as needed
  width: 40%;  // Set the width to 40%
}

/* Add more specific styles for ControlledInput and ControlledSelect if needed */

.container__footer {
  display: flex;
  justify-content: center;
  // justify-content: space-between;
  margin-top: 10px;  // Adjust the margin as needed
  margin-left: -450px;
}

.container__content__submit {
  cursor: pointer;
  margin: 0 10px;
  // Add more specific styles for the Button component if needed
}

/* Position "Cancel" button to the left */
.container__content__submit:first-child {
  order: 2;
}

.header {
  margin-bottom: 20px; /* Adjust the margin as needed */
  display: flex; /* Add display:flex to enable alignment */
  justify-content: space-between; /* Add space between h6 and h5 */
  align-items: center; /* Center items vertically */
}

.header h6 {
  margin-top: 10px;
  font-size: 28px;
  font-weight: bold;
  color: #333; /* Change the color as needed */
}

.header h5 {
  font-size: 16px;
  font-weight: bold;
  color: #666; /* Change the color as needed */
  margin-right: 130px; /* Move h5 to the right */
}
/* Position "Generate Estimate" button to the right */
.container__content__submit:last-child {
  order: 1;
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1; /* Bring the icon behind the text */
}
// tr {
//   td {
//     font-size: 0.9rem;
//     text-align: right; // Align content within the table cell
    
//     input {
//       width: 50px; // Adjust width as needed
//       padding: 5px; // Adjust padding as needed
//       border: 1px solid #ccc; // Add border for input field
//       border-radius: 3px; // Add border radius for input field
      
//       &:focus {
//         outline: none; // Remove default focus outline
//         border-color: blue; // Change border color on focus
//         box-shadow: 0 0 5px rgba(0,0,255,0.5); // Add box-shadow on focus
//       }
//     }
//   }
// }
h5:nth-child(1) input {
  font-size: .8rem;
  width: 60px;
  font-weight: 500;
  margin: 6px 0 6px auto; /* Adjusted margin to move the input to the right */
  margin-left: 246px;
  padding: 8px;
  border: 1px solid rgb(218, 217, 217);
}
h5:nth-child(2) input {
  font-size: .8rem;
  width: 60px;
  font-weight: 500;
  margin: 6px 0 6px auto; /* Adjusted margin to move the input to the right */
  margin-left: 230px;
  padding: 8px;
  border: 1px solid rgb(218, 217, 217);
}
h5:nth-child(3) input {
  font-size: .8rem;
  width: 60px;
  font-weight: 500;
  margin: 6px 0 6px auto; /* Adjusted margin to move the input to the right */
  margin-left: 147px;
  padding: 8px;
  border: 1px solid rgb(218, 217, 217);
}
h5:nth-child(4) input {
  font-size: .8rem;
  width: 60px;
  font-weight: 500;
  margin: 6px 0 6px auto; /* Adjusted margin to move the input to the right */
  margin-left: 110px;
  padding: 8px;
  border: 1px solid rgb(218, 217, 217);
}
h5:nth-child(5) input {
  font-size: .8rem;
  width: 60px;
  font-weight: 500;
  margin: 6px 0 6px auto; /* Adjusted margin to move the input to the right */
  margin-left: 100px;
  padding: 8px;
  border: 1px solid rgb(218, 217, 217);
}
td {
  padding: 4px;
  border-right: 1px dashed #C4C4C4;
}

