@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;
  margin-bottom: 24px;

  &__approve {
    width: 20px;
    height: 20px;
  }

  &__declined {
    width: 20px;
    height: 20px;
    path {
      stroke: $fire_engine_red;
    }
  }

  &__decline {
    @extend %flex-column;
    gap: 10px;
  }
  &__pending {
    width: 20px;
    height: 20px;
  }

  &__info {
    @extend %flex-align;
    gap: 8px;
    margin-top: 16px;

    &__title {
      @extend %Circular-Std-25-bold;

      @include breakpoint(tablet-m) {
        font-size: 21px;
      }

      &__link {
        color: $sapphire;
      }
    }

    &__status {
      @extend %flex-align;
      gap: 4px;
      padding: 4px;
      max-height: 28px;
      border-radius: 5px;
      border: 1px solid $subtle_blue;
      background-color: $frostWhite;

      svg {
        cursor: auto;
      }

      &__text {
        @extend %Circular-Std-12-reg;
        text-transform: capitalize;
        color: $dusk_slate !important;

        &__approved {
          color: $forest_green !important;
        }

        &__declined {
          color: $fire_engine_red !important;
        }
      }
    }
  }

  &__actions {
    @extend %flex-between;

    @include breakpoint(tablet-l) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }

    &__creation {
      @extend %flex-column;
      gap: 8px;
      padding-left: 8px;

      @include breakpoint(tablet-l) {
        padding-left: 0;
      }

      @include breakpoint(tablet-m) {
        gap: 8px;
        flex-direction: column;
        align-items: flex-start;
      }

      &__item {
        @extend %Circular-Std-14;

        &__name {
          text-transform: capitalize;
        }
      }
    }

    &__buttons {
      @extend %flex-align;
      gap: 24px;

      @include breakpoint(tablet-m) {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 8px;
      }

      &__item {
        max-height: 39px;
        border-radius: 5px;
        padding: 11px 24px;
        text-transform: capitalize;
        background-color: $paleBlue;
      }
    }
  }
}
