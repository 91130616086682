@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__header {
    align-items: flex-start !important;

    &__logo {
      & > div {
        max-height: 70px;
      }
    }
  }

  &__content {
    @extend %flex-justify-between;

    margin-top: 34px;
    gap: 20px;
    margin-bottom: 32px;
    padding-bottom: 32px;
    border-bottom: 1px solid $gray;

    &__left {
      @extend %flex-column;
      gap: 8px;
      max-width: 182px;

      &__head {
        letter-spacing: normal;
        color: $sapphire !important;
        @extend %Circular-Std-25-bold;
      }

      &__box {
        @extend %flex-align;
        gap: 8px;

        &__title {
          @extend %Circular-Std-14-reg;
        }

        &__subtitle {
          @extend %Circular-Std-14-reg;
          color: $shadowed-slate !important;
        }
      }
    }

    &__right {
      @extend %flex-column;
      gap: 8px;
      max-width: 179px;

      &__head {
        @extend %Circular-Std-25-medium;
        @extend %text-ellipsis;
        letter-spacing: normal;
        color: $sapphire !important;
      }

      &__main {
        @extend %Circular-Std-14-reg;
        @extend %text-ellipsis;
      }
    }
  }

  &__footer {
    padding: 8px 0 32px;

    &__content {
      @extend %flex-align;
      justify-content: flex-end;
      width: 100%;
      text-align: right;

      span {
        @extend %text-ellipsis;
        @extend %Circular-Std-35-bold;

        display: block;
      }

      @include breakpoint(tablet) {
        font-size: 25px;
      }
    }
  }

  &__vendor {
    @extend %flex-column;
    gap: 10px;
    padding: 8px;

    &__title {
      @extend %Circular-Std-16-bold;
    }

    &__subtitle {
      @extend %Circular-Std-12-medium;
      word-break: break-word;
      max-width: 100%;
    }
  }

  &__actions {
    @extend %flex-end;
    gap: 24px;
    margin-top: 135px;

    &__item {
      @extend %Circular-Std-14-bold;
      border-radius: 5px;
      text-transform: capitalize;

      &:last-of-type {
        @extend %Circular-Std-14-boldMax;
      }
    }
  }
}
