@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  margin: 0 auto;
  padding: 60px 0;
  min-height: 100dvh;
  overflow-y: scroll;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint(wide) {
    padding: 30px 0;
  }

  @include breakpoint(tablet-m) {
    padding: 32px 0 23px 0;
  }

  &__cards {
    display: none;
  }

  &__content {
    @extend %flex-center;
    padding-top: 77px;
    padding-bottom: 72px;
    min-height: calc(100% - 60px - 77px);

    @include breakpoint(wide-l) {
      padding: 70px 223px 20px;
    }

    @include breakpoint(wide) {
      padding: 20px 70px 20px;
      min-height: calc(100% - 250px);
    }

    @include breakpoint(tablet-l) {
      padding: 20px;
    }
  }

  &__preview {
    color: $midnightBlack;
    padding: 24px;
  }

  &__text {
    @include breakpoint(tablet-m) {
      font-size: 12px;
    }
  }

  &__footer {
    @extend %flex-between;

    max-width: 994px;
    width: 100%;
    margin: 0 auto;

    @include breakpoint(desktop) {
      padding: 0 120px;
    }

    @include breakpoint(tablet-l) {
      padding: 0 20px;
    }

    &__text {
      @include breakpoint(tablet-m) {
        font-size: 12px;
      }
    }

    &__skip {
      @include breakpoint(tablet-l) {
        background-color: $paleBlue;
        color: $sapphire;
      }
      &:hover {
        background-color: $paleBlue;
        color: $midnightBlack;
      }
    }

    &__back {
      background-color: $paleBlue;
      color: $sapphire;

      &_invite {
        @include breakpoint(tablet-l) {
          display: none;
        }
      }

      &:hover {
        background-color: $paleBlue;
        color: $midnightBlack;
      }

      @include breakpoint(tablet-m) {
        padding: 22px !important;
        height: 59px !important;
      }
    }

    &__next {
      background-color: $sapphire;
      color: $white;

      &:hover {
        background-color: $sapphire;
        color: $white;
      }

      @include breakpoint(tablet-m) {
        padding: 21px 34px !important;
        height: 59px !important;
      }
    }

    &__send {
      background-color: $sapphire;
      color: $white;
      padding: 17px !important;

      &:hover {
        background-color: $sapphire;
        color: $white;
      }
    }

    button {
      @extend %Circular-Std-14;
      letter-spacing: 0.175px;
      font-size: 14px;

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
}
