@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;

  img {
    object-fit: cover;
  }

  &__quantity {
    @extend %flex-align;
    gap: 8px;
    padding-right: 21px;
  }

  &__header {
    @extend %flex-between;
    margin-bottom: 24px;

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }
  }

  &__icon {
    margin: 0 auto;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__image {
    max-width: 70px !important;
  }

  @include breakpoint(tablet-l) {
    padding: 0 20px;
  }

  &__title {
    @extend %Circular-Std-25-bold;
  }

  &__actions {
    @extend %flex-end;
    gap: 24px;

    @include breakpoint(tablet) {
      gap: 8px;
      justify-content: flex-start;
      flex-wrap: wrap;
    }

    &__item {
      text-transform: capitalize;
    }
  }

  &__add {
    @extend %flex-align;
    max-width: fit-content;
    gap: 4px;
    cursor: pointer;

    &_disable {
      cursor: auto;
      opacity: 0.6;
    }

    &__text {
      @extend %Circular-Std-14-reg;
    }
  }
}
