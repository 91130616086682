@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;

  @include breakpoint(tablet) {
    margin-top: 35px;
  }

  &__billing {
    margin-top: 40px;
  }

  &__header {
    &__title {
      color: $black !important;
      font-family: $circularBold !important;
    }

    &__subtitle {
      color: $dusk_slate !important;
      font-size: 12px;
      font-weight: 450;
      letter-spacing: 0.048px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 30px;
    margin-bottom: 24px;

    @include breakpoint(tablet) {
      gap: 10px;
    }

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &_title {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    &_subtitle {
      @extend %Circular-Std-21;
    }
  }

  @include breakpoint(tablet) {
    gap: 10px;
  }

  &__title {
    @extend %Circular-Std-25-light;
    width: 100%;
    text-align: center;
    color: $sapphire !important;
  }

  &__head {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    &__box {
      max-width: 100%;
      gap: 8px;
      height: 109px;
    }
  }

  &__item {
    @extend %flex-between;
    gap: 24px;
    width: 100%;
    grid-column: span 2;

    @include breakpoint(tablet) {
      gap: 10px;
    }
  }

  &__address {
    grid-column: span 2;
  }

  &__submit {
    @extend %flex-end;
    margin: 45px 0 40px 0;
    width: 100%;
  }
}
