@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.swiper {
  width: 100%;
  height: 510px;
}

.swiper-slide {
  display: flex;
  font-size: 18px;
  text-align: center;
  justify-content: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  bottom: -4px;
}
