@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  padding: 20px;
  max-width: 1040px;

  &__header {
    display: flex;
    justify-content: space-between; 
    align-items: center;
  }

  &__centeredBox {
    margin-left: 25%;
  }

  &__rightBox {
    margin-left: auto;
    background-color: $paleBlue;
    color: $sapphire;
    
    &:hover {
      background-color: $paleBlue;
      color: $midnightBlack;
      text-emphasis-color: black;
    }
  }
  
  &__content {
    @extend %flex-column;
    gap: 16px;

    @include breakpoint(desktop) {
      gap: 15px;
    }

    &__form {
      @include breakpoint(tablet-m) {
        padding-bottom: 60px;
        border-bottom: 1px dashed #ccc;
      }
    }

    &__agreement {
      display: flex;
      justify-content: center;
    }

    &__date {
      width: -webkit-fill-available;
      background-color: #f2f2f2;
      border-radius: 5px;

      input {
        &:hover {
          border-color: red !important;
        }
      }

      fieldset {
        border-color: transparent;
        border-width: 1px !important;
      }
    }

    &__file {
      flex-direction: row !important;
      align-items: center !important;
      gap: 16px;
      margin: 0 auto;
      height: 112px;
      border-radius: 4px;
      background: $platinumGray;
      border: 2px dashed rgba(204, 204, 204, 0.8);

      &__replace {
        align-items: end;
        bottom: 0 !important;
      }

      & > div {
        height: 100% !important;

        img {
          height: 100% !important;
          object-fit: fill !important;
        }
      }

      p {
        @extend %Circular-Std-25-bold;
        color: $dusk_slate;
        background-color: transparent;

        @include breakpoint(tablet-l) {
          font-size: 14px;
        }
      }
    }
  }

  &__card {
    @extend %flex-column-between;
    gap: 16px;
    margin-top: 8px;

    &__items {
      @extend %flex-between;
      flex: 1;
      gap: 16px;

      @include breakpoint(wide) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }

      @include breakpoint(tablet-m) {
        gap: 24px;
      }
    }
    &__bottom {
      border-radius: 10px;
      padding: 16.5px 14px;
      background-color: $platinumGray;
    }
  }

  &__address {
    margin-top: 8px;

    @include breakpoint(tablet-m) {
      gap: 16px;
      width: 100%;
      display: grid;
      margin-top: 15px;
      grid-template-columns: repeat(auto-fill, minmax(156px, 1fr));
    }
  }
}
