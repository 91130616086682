@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 8px;

  &__title {
    @extend %Circular-Std-14-reg;
    color: rgba(34, 34, 34, 0.8) !important;
  }

  &__subtitle {
    @extend %Circular-Std-14-reg;
  }
}
