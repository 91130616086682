@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__head {
    @extend %flex-center;
    margin-bottom: 40px;

    &__left {
      @extend %flex-column;
      gap: 8px;

      &__title {
        @extend %Circular-Std-21;
      }

      &__subtitle {
        @extend %flex-align;

        gap: 8px;

        &__title {
          @extend %Circular-Std-14;
          letter-spacing: 0.014px;
        }

        &__name {
          @extend %Circular-Std-14;
          color: $staleGray;
        }
      }
    }

    &__right {
      @extend %flex-column-between;
      gap: 18px;

      &__usage {
        span {
          @extend %Circular-Std-12-reg;
        }
      }
    }
  }

  &__content {
    &__head {
      @extend %flex-between;
      padding: 0 25px 20px;
      border-bottom: 0.5px dashed $gray;
      border-bottom: 0.5px dashed $gray;
      width: calc(100% + 50px);
      margin: 0 -25px;

      &__item {
        @extend %Circular-Std-16-regular;
      }

      &__item {
        @extend %Circular-Std-16-regular;
      }
    }

    &__main {
      @extend %flex-between;
      padding: 16px 0;
      border-bottom: 0.5px dashed $gray;

      &__title {
        @extend %Circular-Std-14;
        text-transform: capitalize;
        color: $shadowed-slate !important;
      }

      &__subtitle {
        @extend %Circular-Std-16-light;
        @extend %text-ellipsis;
        text-transform: capitalize;
        max-width: 250px;
      }
    }
  }

  &__description {
    margin-top: 40px;

    &__img {
      border-radius: 5px;
      height: 205px;
      overflow: hidden;
      margin-bottom: 40px;
    }

    &__title {
      @extend %Circular-Std-16-regular;
      margin-bottom: 16px;
    }
  }
}
