@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 40px;
  width: 100%;
  max-width: 600px;
  background-color: $white;
  padding: 24px;

  @include breakpoint(tablet-m) {
    padding: 30px 37px 18px 30px;
  }

  @include breakpoint(mobile) {
    padding: 20px 27px 10px 20px;
  }

  @include breakpoint(mobile-m) {
    padding: 15px 18px 10px 15px;
  }
}
