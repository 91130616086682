@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  width: 100%;
  padding: 24px;
  background-color: $pureWhite;

  &__title {
    @extend %Circular-Std-25-bold;
  }

  svg {
    cursor: pointer;
  }
}
