@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 8px;

  &__status {
    &__low {
      color: $fire_engine_red;
    }
  }

  &__stock {
    padding: 4px;
    color: $dusk_slate;
    border-radius: 5px;
    background-color: $frostWhite;
    border: 1px solid $subtle_blue;
  }
}
