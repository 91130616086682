@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 40px;

  &__content {
    @extend %flex-column;
    gap: 20px;
  }

  &__info {
    @extend %flex-column;
    gap: 8px;

    &__title {
      @extend %Circular-Std-25-bold;
    }

    &__subtitle {
      @extend %Circular-Std-16-light;
      color: $shadowed-slate !important;
    }
  }

  &__actions {
    @extend %flex-end;

    &__between {
      justify-content: space-between;
    }

    @include breakpoint(tablet-m) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &__search {
      max-width: 233px;
      width: 100%;
    }

    &__create {
      @extend %flex-center;
      gap: 8px;
      padding: 8px 13px;
      max-height: 40px;
      border-radius: 5px;
      text-transform: capitalize;

      span {
        margin: 0;

        svg {
          path {
            stroke: $white;
          }
        }
      }
    }
  }
}
