@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 40px;

  @include breakpoint(tablet-l) {
    padding: 0 20px;
    background: $whispering_gray;
  }

  &__icon {
    width: 16px;
    height: 16px;
    margin: 0 auto;

    path {
      fill: $dusk_slate;
    }
  }

  &__header {
    @extend %flex-between-start;

    &__routing {
      @extend %flex-column;
      gap: 8px;

      &__group {
        @extend %flex-align;
        gap: 4px;

        &__text {
          @extend %Circular-Std-14-reg;
          color: $sapphire !important;

          &:first-child {
            cursor: pointer;
          }
        }

        &__icon {
          transform: rotate(-90deg);
          cursor: default;
        }
      }
    }
  }

  &__content {
    @extend %flex-column;
    gap: 66px;

    &__about {
      @extend %flex-between-start;

      @include breakpoint(tablet-l) {
        flex-direction: column;
        align-items: flex-start;
        gap: 25px;
      }

      &__info {
        @extend %flex-column;
        max-width: 60%;
        gap: 16px;

        &__title {
          @extend %Circular-Std-25-bold;
          color: $sapphire !important;
        }

        &__details {
          &__text {
            @extend %Circular-Std-14;
            padding: 4px 8px;
          }
        }
      }

      &__buttons {
        @extend %flex-column-between;
        align-items: flex-end;
        gap: 16px;

        @include breakpoint(tablet-l) {
          align-items: flex-start;
          height: fit-content;
          gap: 24px;
        }

        &__group {
          @extend %flex-align;
          gap: 20px;

          @include breakpoint(tablet) {
            flex-wrap: wrap;
            gap: 10px;
          }

          &__item {
            @extend %flex-column;
            gap: 4px;
            position: relative;

            &__text {
              @extend %Circular-Std-10-medium;
              color: $dusk_slate !important;
              text-align: center;
              position: absolute;
              bottom: -12px;
              left: 30px;
            }
          }
        }

        &__item {
          @extend %Circular-Std-14-med;
          padding: 11px 8px;
          border-radius: 5px;
          text-transform: capitalize;

          span {
            @include breakpoint(tablet) {
              font-size: 12px;
            }
          }
        }

        &__send {
          @extend %Circular-Std-14-med;
          padding: 12px 16px;
          border-radius: 5px;
          text-transform: capitalize;

          &__icon {
            margin-left: 4px;

            path {
              stroke: #fff;
            }
          }

          span {
            margin: 0;
            @include breakpoint(tablet) {
              font-size: 12px;
            }
          }
        }
      }
    }

    &__info {
      &__item {
        @extend %flex-column;
        gap: 32px;
        margin-bottom: 24px;

        &__info {
          @extend %flex-column;
          gap: 24px;

          &__schedule {
            @extend %flex-between;
            padding: 24px 32px;

            &__item {
              width: 50%;

              &__title {
                padding: 18px 16px;
                @extend %Circular-Std-16-bold;
              }

              &__subtitle {
                @extend %Circular-Std-14-reg;
                padding: 18px 16px;
              }
            }
          }

          &__content {
            background: rgba(251, 252, 254, 0);
          }

          &__title {
            @extend %flex-align;
            @extend %Circular-Std-25;
            gap: 24px;
            padding: 16px;
            border-bottom: 0.5px solid $gray;
            cursor: pointer;

            &__icon {
              transform: rotate(0);

              &_open {
                transform: rotate(180deg);
              }
            }
          }
        }
      }

      &__field {
        @extend %flex-column;
        gap: 24px;
        margin-top: 24px;

        &__title {
          @extend %Circular-Std-25-light;
        }
      }

      &__count {
        @extend %flex-column-end;
        gap: 16px;

        &__subtitle {
          @extend %Circular-Std-21-medium;
          color: $dusk_slate !important;
        }

        &__title {
          @extend %Circular-Std-30;
        }
      }
    }
  }

  &__loading {
    @extend %flex-center;
  }
}
