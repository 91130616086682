@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  background-color: $paleBlue;
  border-radius: 5px;
  border: 0.5px dashed #ccc;
  width: 200px;

  &__gray {
    border-radius: 10px;
    background: $platinumGray;
    border: none;

    label {
      @extend %Circular-Std-16-light;
      color: rgba(0, 0, 0, 0.6) !important;
    }

    input {
      padding: 12.5px 8px !important;
    }
  }

  &__errors {
    border: 1px solid red;
    padding: 5px !important;
  }

  div {
    fieldset {
      border: none;
    }

    input {
      padding: 12px 8px;
    }
  }

  label {
    @extend %Public-Sans-12-reg;
    color: $sapphire;
  }
}
