@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  height: 400px;
  position: relative;

  &__img {
    height: 100%;
    max-width: 300px;
    width: 100%;

    img {
      object-fit: contain;
    }
  }

  &__quote {
    position: absolute;
  }
}
