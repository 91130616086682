@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  min-height: calc(100dvh - 120px);

  &__header {
    text-align: center;
    margin-bottom: 20px; /* Adjust the margin between header and content */

    &__title {
      @extend %Circular-Std-25-light;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    &__item {
      width: 100%;
      margin-bottom: 20px; /* Adjust the margin between items */

      @include breakpoint(mobile) {
        &.full-width-mobile {
          grid-column: span 3;
        }
      }
    }

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }

    &__submit {
      @extend %flex-center;
      text-transform: capitalize;
      margin-top: 16px;
      width: 100%;
    }
  }

  &__footer {
    @extend %flex-align;
    gap: 24px;
    width: 100%;
    justify-content: flex-end;
    padding: 10px 0;
    margin-top: 20px; /* Adjust the margin between content and footer */
  }
}
