@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %Circular-Std-16-bold;
  @extend %flex-align;
  gap: 4px;
  padding-left: 22px;
  min-width: fit-content;

  &__invoice {
    gap: 0 !important;

    @include breakpoint(wide-xl) {
      font-size: 14px !important;
    }
    @include breakpoint(wide) {
      font-size: 16px !important;
    }
  }
}
