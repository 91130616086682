@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 4px;
  height: 21px;

  &__content {
    @extend %flex-align;
    gap: 4px;
    cursor: pointer;
  }

  &__title {
    @extend %Circular-Std-14-reg;
  }
}
