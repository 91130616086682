@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.bouncing {
  display: flex;
  justify-content: center;
  margin: 40px auto;

  @include breakpoint(mobile) {
    margin: 24px auto;
  }

  &__loader {
    width: 12px;
    height: 12px;
    margin: 3px 6px;
    border-radius: 50%;
    opacity: 1;
    background-color: $sapphire;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}
