@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;
  position: relative;

  @include breakpoint(tablet) {
    gap: 10px;
  }

  &__title {
    @extend %Circular-Std-25-light;
    text-align: center;
    width: 100%;

    p {
      &:first-child {
        text-align: center;
      }
    }
  }

  &__head {
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;

    &__box {
      gap: 8px;
      border: none;
      height: 109px;
      max-width: 100%;
      border-radius: 10px !important;
    }
  }

  &__item {
    @extend %flex-between;
    gap: 24px;
    margin-bottom: 4px;
    padding-top: 24px;
    border-top: 1px dotted #ccc;

    @include breakpoint(tablet) {
      gap: 10px;
    }
  }

  &__submit {
    @extend %flex-center;
    margin: 20px 0 40px 0;
    width: 100%;

    button {
      min-width: 392px !important;

      @include breakpoint(mobile) {
        min-width: 100% !important;
      }
    }
  }

  &__plus {
    cursor: pointer;
  }

  &__remove {
    cursor: pointer;

    @include breakpoint(tablet-l) {
      right: 0;
      top: 0;
    }
  }
}

.header {
  @extend %flex-column;
  gap: 4px;

  &__title {
    @extend %Circular-Std-21;
    color: $midnightBlack !important;
  }

  &__subtitle {
    @extend %Circular-Std-14;
    color: $shadowed-slate !important;
  }
}
