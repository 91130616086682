@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__head {
    @extend %flex-between;
    margin-bottom: 40px;

    svg {
      cursor: pointer;
    }

    &__info {
      @extend %flex-column;
      gap: 8px;

      &__title {
        @extend %Circular-Std-21;
      }

      &__number {
        @extend %Circular-Std-14-reg;
        letter-spacing: 0.014px;

        span {
          letter-spacing: 0.035px;
          color: $staleGray;
        }
      }

      &__name {
        @extend %Circular-Std-14-reg;
        letter-spacing: 0.014px;

        span {
          letter-spacing: 0.035px;
          color: $staleGray;
        }
      }
    }
  }

  &__footer {
    padding: 20px 0;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
