@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  padding: 8px;
  width: 100%;
  max-width: 416px;
  border-radius: 5px;
  background: $platinumGray;
  border: 1px dashed $lightGray;

  &__info {
    @extend %flex-between;

    &_icon {
      @extend %flex-align;
      border-radius: 5px;
      background: #fff;
      padding: 9.5px;
    }

    &_start {
      display: flex;
      max-width: 350px;

      &_title {
        @extend %text-ellipsis;
        color: $midnightBlack;
        max-width: 100%;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.035px;
      }

      &_block {
        margin-left: 14px;
        max-width: 100%;

        @include breakpoint(tablet-m) {
          margin-left: 5px;
        }

        @include breakpoint(mobile) {
          max-width: 110px;
        }

        &_child {
          margin-top: 8px;
          color: $midnightBlack;
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0.048px;
        }
      }
    }
    &_close {
      @extend %flex-align;
      flex-direction: column;
      align-items: flex-end;

      svg {
        cursor: pointer;
      }

      &_end {
        margin-top: 10px;
        color: $midnightBlack;
        font-size: 12px;
        font-weight: 300;
        letter-spacing: 0.048px;
      }
    }
  }

  &__progress {
    margin-top: 14px;
  }
}
