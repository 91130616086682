@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 100%;

  &__name {
    @extend %flex-align;
    gap: 8px;

    a {
      color: $sapphire;
      text-decoration: underline !important;
    }
  }

  &__icon {
    margin: 0 auto;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $dusk_slate;
      }
    }
  }

  &__type {
    padding: 4px 16px;
    border-radius: 5px;
    text-transform: capitalize;
    margin-left: 16px;
  }

  &__cards {
    @extend %flex-column;
    gap: 23px;

    &__title {
      @extend %Circular-Std-16-regular;
    }

    &__content {
      column-gap: 20px;
      row-gap: 54px;
      display: grid;
      padding: 15px 16px;
      grid-template-columns: repeat(auto-fill, minmax(267px, 1fr));

      @include breakpoint(tablet) {
        row-gap: 20px;
      }
    }
  }

  &__footer {
    @extend %flex-end;
    padding: 20px 0;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
