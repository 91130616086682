@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-end;
  position: relative;

  &_content {
    @extend %flex-end;
    align-items: center;
    cursor: pointer;

    gap: 15px;
    width: 100%;
  }

  &__title {
    color: $jet_black !important;
    font-family: $circularRegular !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    letter-spacing: 0.175px !important;
  }

  &_absolute {
    @extend %scrollbar-gray;
    top: 30px;
    right: 0;
    z-index: 10;
    max-height: 200px;
    height: fit-content;
    padding: 12px;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    background: #fff;
    border-radius: 10px;
    border: 1px solid $subtle_blue;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

    &_content {
      @extend %flex-align;
      gap: 8px;

      & > span {
        padding: 0;
      }

      &:not(:last-child) {
        padding-bottom: 19px;
      }

      &_title {
        @extend %text-ellipsis;
        max-width: 200px;
        color: rgba(34, 34, 34, 0.9) !important;
        font-family: $circularRegular !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        letter-spacing: 0.035px !important;
      }
    }
  }
}
