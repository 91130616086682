@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &__picker {
    background-color: transparent;
    border: none;
    padding: 10px 0 !important;

    input {
      padding: 0 !important;
      font-size: 15px !important;
    }
  }
}
