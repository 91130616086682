@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  top: 0;
  width: 100%;
  height: 100dvh;
  position: sticky;
  max-width: 200px;
  background: #fff;
  box-sizing: border-box;
  border-right: 0.5px solid #ccc;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.03), 4px 0px 6px 0px rgba(34, 34, 34, 0.03);

  @media (max-height: 600px) and (max-width: $wide) {
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include breakpoint(wide) {
    display: none;
  }

  @media (min-width: $large-d) {
    top: 131px;
    margin-top: 16px;
    max-width: 312px;
    position: sticky;
    overflow-y: auto;
    height: fit-content;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__footer {
    @extend %flex-center;
    gap: 10px;

    @include breakpoint(wide) {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    @media (max-height: 600px) and (max-width: $wide) {
      bottom: unset !important;
    }

    svg {
      width: 100px;
    }

    span {
      font-size: 8px;
      font-family: $circularBold;
    }

    &__large {
      position: static;
      bottom: 0;
      width: 100%;
      background-color: white;
    }
  }

  &__drawer {
    max-width: 100%;
    display: block !important;

    .container__content {
      padding: 0 20px;
    }

    .container__box {
      border-radius: 10px;
      padding: 16px;

      &:hover {
        background-color: $pastelSky !important;
        color: $sapphire !important;
      }
    }
  }

  &_logo {
    @extend %flex-center;
    padding: 40px 0 40px 0;

    &_close {
      padding: 24px 20px;
      justify-content: flex-start;
      align-items: flex-start;
      cursor: pointer;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__box {
    @extend %flex-align;
    gap: 4px;
    cursor: pointer;
    padding: 13px 20px;

    &_disable {
      cursor: not-allowed;
      display: none;
    }

    &_icon {
      display: flex;

      &_active {
        svg path {
          stroke: white;
        }

        &_report {
          svg path {
            stroke: white;
            fill: white;
          }
        }
      }
    }

    &_item {
      @extend %flex-between;
      width: 100%;

      svg {
        transform: rotate(0);
      }

      &_active {
        svg {
          transform: rotate(180deg);
        }
      }

      &_title {
        font-family: 'CircularStdBold' !important;
        font-size: 14px;
        font-style: normal;
        font-weight: 700 !important;
        line-height: normal;
        letter-spacing: 0.175px;
      }

      &_icon {
        transform: rotate(180deg);
      }

      &_arrow {
        display: flex;

        &_icon {
          svg {
            path {
              stroke: white;
            }
          }
        }

        &_expanded {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__title {
      color: $midnightBlack;
      font-weight: 500;
      letter-spacing: 0.035px;
    }
  }

  &__content {
    overflow-y: auto;
    height: calc(100dvh - 180px);

    @media (min-width: $large-d) {
      height: fit-content;
    }

    @include breakpoint(wide) {
      height: auto;
    }
  }
}

.expandableContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 16px 0;

  &__item {
    color: $sapphire;
    font-size: 12px;
    padding: 13px 0;
    font-weight: 500;
    cursor: pointer;
    letter-spacing: 0.048px;
    font-family: $circularRegular;
  }
}

.link {
  background-color: transparent;
  color: $midnightBlack;

  &_open {
    background-color: $paleBlue !important;
    color: $deep_cerulean !important;
  }

  &_active {
    background-color: $deep_cerulean;
    color: $snowWhite;

    .container__box {
      &:hover {
        background-color: transparent !important;
        color: $snowWhite !important;
      }
    }
    @include breakpoint(wide) {
      border-radius: 10px;
    }

    svg {
      path {
        stroke: white;
        fill: white;
      }
    }

    &_reports {
      svg {
        path {
          fill: none !important;
          stroke: white;
        }
      }
    }
  }
}
.connector {
  height: 22px;
  margin-left: 10px;
  position: relative;
  left: calc(-50% + 66px);

  right: calc(50% + 10px);

  @media (max-width: 1520px) {
    left: calc(-50% + 10px);
  }

  @include breakpoint(wide) {
    left: calc(-50% + 50px);
  }

  &__line {
    top: -2px;
    border-width: 4px;
    border-color: $paleBlue;
    position: absolute;
    left: 55.5px;
    height: 26px;

    @include breakpoint(wide-l) {
      height: 26.5px;
    }

    &_active {
      span {
        border-color: $sapphire !important;
      }
    }
  }
}

.dropdown {
  padding: 11px 16px 16px 40px;
  display: none;

  &__open {
    display: block;
  }

  &__checked {
    @extend %flex-center;
    width: 12px;
    height: 12px;
    background-color: $sapphire;
    border-radius: 50%;
  }
}
