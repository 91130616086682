@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.title {
  @extend %Circular-Std-25-light;
  padding: 16px 0 40px;
  text-align: center;
}
.container {
  @extend %flex-column;
  gap: 32px;
  padding: 16px;

  &__title {
    @extend %Circular-Std-25-bold;
    text-align: center;

    &:hover {
      background-color: transparent;
    }
  }

  &__add {
    margin-top: 8px;
    border-radius: 5px;
    padding: 10.5px 8px;
    background-color: $pastelSky;

    span {
      @extend %Circular-Std-12-light;
      margin-left: 0;
    }

    &:hover {
      background-color: $pastelSky;
    }
  }

  &__submit {
    border-radius: 10px;
    background: #2067dd;
    color: #fff;
    padding: 21.5px 16px;
    min-width: 100%;
    margin-top: 70px;

    &:hover {
      background-color: #174898;
    }
  }

  &__details {
    @extend %flex-column;
    gap: 16px;

    &__sections {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);

      &__item {
        @extend %flex-between;
        @extend %Circular-Std-12-reg;
        max-width: 100%;
        color: #222;
        border-radius: 5px;
        border: 0.5px dashed #ccc;

        svg {
          path {
            stroke: #222;
          }
        }

        &__active {
          color: #2067dd;
          background-color: $pastelSky;

          svg {
            path {
              stroke: #2067dd;
            }
          }
        }
      }
    }

    &__title {
      @extend %Circular-Std-14-reg;

      &__count {
        color: #00d2e5;
      }
    }
  }
}
