@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  margin-bottom: 24px;
  width: fit-content;
  position: relative;

  @include breakpoint(tablet-l) {
    margin-bottom: 0;
  }

  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    gap: 20px;
    background: $whispering_gray;
  }

  &__content {
    &__menu {
      @include breakpoint(tablet-l) {
        display: flex;
        flex-direction: column;
        background-color: $white;
        max-width: fit-content;
        padding: 10px;
        border-radius: 5px;
        position: absolute;
        display: none;
        z-index: 11;
        box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
      }

      &_active {
        display: flex;
      }
    }

    &__burger {
      display: none;

      @include breakpoint(tablet-l) {
        display: block;
      }
    }
  }

  &__title {
    @extend %Circular-Std-25-bold;
    margin-bottom: 24px;
  }

  &__item {
    @extend %Circular-Std-14-med;
    padding: 12px 16px 10px;
    color: $obsidianAura;
    border-bottom: 2px solid $obsidianAura;

    @include breakpoint(tablet-l) {
      width: fit-content;
      padding-left: 0;
    }

    p {
      @extend %flex-center;
      width: 88px;

      @include breakpoint(tablet-l) {
        width: auto;
        display: block;
      }
    }

    &_active {
      border-bottom: 2px solid $sapphire;
      color: $sapphire;
    }
  }
}
