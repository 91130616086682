@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.palette {
  @extend %flex-column;
  gap: 16px;
  right: 20px;
  width: 348px;
  z-index: 9999;
  padding: 24px;
  border-radius: 8px;
  position: absolute;
  background-color: $white;
  box-shadow: 0px 4px 10px 0px $almost_invisible_black, 0px 4px 10px 0px $almost_invisible_black;

  @include breakpoint(mobile) {
    width: 270px;
  }

  &__circle {
    gap: 16px;
    padding: 16px;
    border-radius: 5px;
    background: $white;
    box-shadow: 0px 4px 10px 0px $almost_invisible_black, 4px 0px 10px 0px $almost_invisible_black;

    & > div {
      width: 12px !important;
      height: 12px !important;
      margin: 0 !important;
    }
  }
  &__title {
    @extend %Circular-Std-25-bold;
    color: $midnightBlack !important;

    @include breakpoint(mobile) {
      font-size: 21px;
    }
  }

  &__color {
    @extend %flex-center;
    gap: 8px;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    padding: 8.35px 8px;
    background: $paleBlue;
  }

  &__select {
    gap: 8px;
  }

  &__input {
    border-radius: 5px !important;

    input {
      padding: 8.5px 16px;
    }
    label {
      top: -7px;
    }
  }

  &__arrow {
    &__expanded {
      transform: rotate(180deg) !important;
    }
  }

  &__button {
    @extend %flex-center;

    &__item {
      padding: 11px 16px;
      border-radius: 4px;
      text-transform: capitalize;
    }
  }
}
