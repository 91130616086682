@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
    @include breakpoint(tablet-l) {
      padding: 0 20px;
    }
  
    &__link {
      text-decoration: underline !important;
    }
  
    &__icon {
      margin: 0 auto;
    }
  
    &__selection {
      @extend %flex-between;
      margin-bottom: 24px;
    }
  
    &__select {
      @extend %Circular-Std-14;
      @extend %flex-align;
      width: fit-content;
      gap: 8px;
      cursor: pointer;
  
      & > span {
        padding: 0;
      }
    }
  }
  
  .activeSortHeader {
    &_asc {
      svg {
        g {
          path {
            &:nth-child(1) {
              stroke: $sapphire;
              opacity: 1 !important;
              fill: $sapphire;
            }
          }
        }
      }
    }
  
    svg {
      g {
        path {
          &:nth-child(2) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }
  