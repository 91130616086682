@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 100%;
  max-width: 100%;
  padding: 10px 0;
  padding-bottom: 0;
  background-color: $white;

  @include breakpoint(tablet-l) {
    padding: 20px;
  }

  @include breakpoint(mobile) {
    padding: 20px;
    background-color: transparent;
  }

  &__title {
    font-size: 16px;
    padding-bottom: 20px;
    letter-spacing: 0.08px;

    @include breakpoint(tablet-l) {
      text-align: center;
    }
  }

  &__cards {
    gap: 0 !important;
    padding-bottom: 32px;

    &__width {
      margin: 10px 16px;
      max-width: 100%;
    }
  }

  &__button {
    @extend %flex-end;
    margin-right: 40px;

    button {
      text-transform: capitalize;
    }

    @include breakpoint(tablet-l) {
      margin-top: 20px;
      margin-right: 0;
    }
  }
}
