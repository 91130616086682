@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 4px;

  &__text {
    @extend %Circular-Std-12-reg;
    text-transform: capitalize;
  }

  &__box {
    width: 8px;
    margin-top: 2px;
    height: 8px;
    border-radius: 50%;
  }
}
