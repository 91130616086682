@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  border-radius: 4px;
  position: relative;
  max-width: max-content;
  background: $platinumGray;

  &_content {
    @extend %flex-align;
    cursor: pointer;
    padding: 4px;
    gap: 8px;

    &__to {
      font-style: italic !important;
    }

    p {
      @extend %Circular-Std-12-light;
      color: $midnightBlack !important;
      white-space: nowrap;

      &:first-of-type {
        min-width: fit-content;
      }

      &:last-of-type {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    svg {
      min-width: 12px;
      min-height: 12px;
    }

    &_absolute {
      top: 30px;
      max-width: 275px;
      min-width: 275px;
      width: fit-content;
      z-index: 100;
      max-height: 175px;
      border-radius: 4px;
      overflow-y: scroll;
      position: absolute;
      background: $snowWhite;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);

      @include breakpoint(mobile) {
        left: -56px;
      }

      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @include breakpoint(tablet-l) {
        width: 280px;
      }

      &_content {
        &_item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          padding: 8px;

          &__flex {
            @extend %flex-align;
            gap: 8px;
            max-width: 300px;

            svg {
              min-width: 14px;
              min-height: 14px;
            }
          }

          span {
            width: 16px;
            height: 16px;

            svg {
              width: 16px;
              height: 16px;
            }
          }

          p {
            @extend %Circular-Std-12-light;
            max-width: 70%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $graphiteGray !important;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
