@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  height: 100%;
  overflow: scroll;
  padding: 20px 20px 0 20px;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__title {
    @extend %flex-center;
    @extend %Circular-Std-25-light;
    color: $midnightBlack !important;
    width: 100%;
    padding: 26px;
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &__button {
    margin: 32px 0 20px 0;
    padding: 16px 24px;
    background-color: $snowWhite;

    &__options {
      @extend %flex-between;
      gap: 4px;

      &__cancel {
        @extend %Circular-Std-14-med;
        width: 100%;
        max-width: 120px;
        color: $sapphire;
        border-radius: 10px;
        padding: 20.5px 16px;
        background-color: $paleBlue;

        &:hover {
          opacity: 0.8;
          background-color: $paleBlue;
          color: $sapphire;
        }
      }

      &__submit {
        @extend %Circular-Std-14-med;
        width: 100%;
        color: $white;
        max-width: 120px;
        border-radius: 10px;
        padding: 20.5px 16px;
        background-color: $sapphire;

        &:hover {
          opacity: 0.8;
          color: $white;
          background-color: $sapphire;
        }
      }
    }
  }
}
