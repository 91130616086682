@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;

  @include breakpoint(tablet-l) {
    padding: 0 20px;
    background-color: $whispering_gray;
  }

  &__footer {
    @extend %flex-between;
    width: 100%;

    &__question {
      @extend %flex-between;
      gap: 8px;
      position: relative;

      &__text {
        @extend %Circular-Std-12-reg;
      }
    }
  }

  &__content {
    padding-bottom: 11px;
    gap: 24px;
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));

    @media (min-width: $large-d) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
    }

    &__empty {
      display: flex !important;
    }

    &__dropDown {
      position: relative;
    }

    @include breakpoint(tablet-l) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include breakpoint(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(mobile) {
      grid-template-columns: none;
    }

    &__empty {
      @extend %flex-center;
      @extend %Circular-Std-16-regular;
      margin: 20px 0;
      width: 100%;
    }

    &__grid {
      display: block;
    }
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
