// media queries vars
$mobile-m: 375px;
$mobile: 475px;
$tablet-m: 576px;
$tablet: 768px;
$tablet-l: 992px;
$desktop: 1024px;
$wide: 1200px;
$wide-xl: 1300px;
$wide-xlm: 1380px;
$wide-l: 1440px;
$large-d: 1520px;
$large-d-max: 1720px;

// Font families
$robotoSansSerif: 'Roboto', sans-serif;
$publicSans: 'Public Sans', sans-serif;
$circularMedium: 'CircularStdMedium';
$circularRegular: 'CircularStdRegular';
$circularBold: 'CircularStdBold';
$circularBoldMax: 'CircularStdBoldMax';
$circularLight: 'CircularStdLight';

// @extends
%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-align {
  display: flex;
  align-items: center;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%flex-justify-between {
  display: flex;
  justify-content: space-between;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-column-end {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

%flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

%flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-between-start {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

%flex-between-end {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

%flex-end {
  display: flex;
  justify-content: flex-end;
}

%flex-start {
  display: flex;
  align-items: flex-start;
}

%flex-align-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

%flex-align-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

%text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

%overlay-shadow {
  backdrop-filter: blur(10px);
  box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.15);
}

%plan-card-shadow {
  box-shadow: 0px 3.8024191856384277px 9.506047248840332px 0px rgba(0, 0, 0, 0.05),
    3.8024191856384277px 0px 9.506047248840332px 0px rgba(0, 0, 0, 0.05);
}

%multiple-ellipsis-three-lines {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

%multiple-ellipsis-three-lines {
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
  text-overflow: ellipsis;
}

%scrollbar-min {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 1px;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 7px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #0e2760;
    border-radius: 6px;
    width: 2px;
  }
}

%scrollbar-min-blue {
  &::-webkit-scrollbar {
    height: 3px;
    border-radius: 2px;
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 7px;
    background: transparent;
    -webkit-box-shadow: inset 0 0 2px rgba(241, 237, 237, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background: #2067dd;
    border-radius: 10px;
  }
}

%scrollbar-gray {
  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background: #ebebeb;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #a0a0a0;
    border-radius: 4px;
    border: 1px solid #ebebeb;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
