@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  margin-top: 8px;
  gap: 24px;

  &__items {
    @extend %flex-between;
    gap: 24px;

    @include breakpoint(tablet) {
      flex-direction: column;
    }
  }

  &__title {
    @extend %Circular-Std-25-bold;

    @include breakpoint(tablet-m) {
      font-size: 20px !important;
    }

    &__text {
      @extend %Circular-Std-25-bold;
      @include breakpoint(tablet-m) {
        font-size: 20px !important;
      }
    }

    &__subtitle {
      color: $charcoal_gray !important;
    }

    @include breakpoint(tablet) {
      font-size: 16px;
    }
  }

  &__item {
    @extend %Circular-Std-25-reg;
  }

  &__form {
    @extend %flex-column;
    gap: 24px;
  }

  &__cost {
    border: 0;
    width: 100%;
    padding: 18px 16px;
    border-radius: 10px;
    background-color: $platinumGray;
  }

  &__description {
    border: 0;
    width: 100%;
    min-height: 140px;
    padding: 18px 16px;
    border-radius: 10px;
    background-color: $platinumGray;
  }

  &__submit {
    @extend %flex-end;
    gap: 24px;
    width: 100%;
    margin-top: 165px;

    button {
      border-radius: 4px;
      max-height: 41px;
      text-transform: capitalize;
    }
  }
}

::placeholder {
  @extend %Circular-Std-16-light;
  color: $midnightBlack;
}
