@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &__title {
    @extend %flex-align;
    @extend %Circular-Std-25-light;
    cursor: pointer;
    gap: 24px;
    padding: 16px;
    border-bottom: 0.5px solid $gray;
    background-color: transparent;
    width: 100%;

    &__icon {
      transform: rotate(180deg);

      &_open {
        transform: rotate(0deg);
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @include breakpoint(tablet-l) {
      grid-template-columns: repeat(2, 1fr);
      row-gap: 16px;
    }

    @include breakpoint(tablet-m) {
      grid-template-columns: 1fr;
    }

    &__item {
      @extend %flex-align;
      padding: 20px 16px;
      gap: 8px;

      &__title {
        @extend %Circular-Std-16-regular;
        text-transform: capitalize;
      }

      &__subtitle {
        @extend %Circular-Std-16-light;
        color: rgba(34, 34, 34, 0.8) !important;
      }
    }
  }
}
