@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  margin: 150px 0;
  padding: 40px 0px;

  @media (max-width: $tablet) {
    margin: 0;
  }

  &_title {
    @extend %Circular-Std-49-regular;
    text-transform: uppercase;
    color: $ocean_blue;
    margin-bottom: 24px;

    @media (max-width: $tablet-m) {
      font-size: 30px;
    }
  }

  &_description {
    @extend %Circular-Std-21-light;
    margin-bottom: 24px;
    color: $shadowed-slate;
  }

  &_btn {
    @extend %Circular-Std-16-medium;
    color: $sapphire;
    padding: 16px;
    background: $paleBlue;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.175px;

    &:hover {
      background: $paleBlue;
      opacity: 0.8;
    }
  }

  &_block {
    @extend %flex-between;
    gap: 30px;

    &_item {
      max-width: 710px;
      width: 100%;

      &:first-child {
        @media (max-width: $tablet) {
          margin-bottom: 30px;
          margin-right: 0;
        }
      }
    }

    @media (max-width: $tablet) {
      flex-direction: column;
      text-align: center;
    }

    &_item_img {
      max-width: 680px;
      height: 386px;
      background: $jet_black;
      border-radius: 10px;
      overflow: hidden;
    }
  }
}
