@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: calc(100% + 48px);
  margin: -5px -24px;
  height: calc(100% + 48px);

  padding: 12px 16px;
  background-color: $white;
  max-width: 399px;
  // width: 100%;
  border-radius: 5px;
  border: 1px dashed #ededed;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 4px 0px 10px 0px rgba(0, 0, 0, 0.05);

  &__head {
    @extend %flex-between;
    padding: 4px 0;

    &__text {
      @extend %Circular-Std-14-reg;
      color: $black !important;
    }

    svg {
      cursor: pointer;
    }
  }

  &__content {
    padding: 8px 0;

    &__text {
      @extend %Circular-Std-14-light;
      color: $black !important;
    }
  }

  &__footer {
    @extend %flex-end;
    width: 100%;

    button {
      span {
        font-size: 12px;
      }
    }
  }
}
