@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  @extend %scrollbar-gray;
  gap: 5px;
  width: 100%;
  max-width: 100%;
  background-color: $white;
  padding: 8px 0 40px 16px;
  overflow-y: scroll;
  padding-top: 15px;
  padding-right: 16px;
  max-height: calc(100dvh - 97.5px - 24px - 85.5px - 80px);

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @include breakpoint(tablet-l) {
    background-color: transparent;
  }

  @include breakpoint(tablet-m) {
    padding: 30px 37px 18px 30px;
  }

  @include breakpoint(mobile) {
    padding: 20px 27px 10px 20px;
  }

  @include breakpoint(mobile-m) {
    padding: 15px 18px 10px 15px;
  }
}

.form {
  max-width: 303px;
}
