@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &__status {
    @extend %flex-center;
    padding: 4px;
    border-radius: 5px;

    &__title {
      @extend %Circular-Std-12-light;
      color: $midnightBlack !important;
      text-transform: capitalize;

      &__accepted {
        color: green !important;
      }

      &__declined {
        color: red !important;
      }
    }
  }
}
