@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  max-width: 100%;
  overflow-x: scroll;

  &_y {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &_scroll {
    margin-bottom: 5px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 5px;
      display: block !important;
    }

    &::-webkit-scrollbar-track {
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $obsidianAura;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
