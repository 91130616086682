@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.quill {
  display: flex;
  flex-direction: column;
  gap: 56px;
}

.ql-toolbar {
  height: 40px;
  border: none !important;
  background-color: $pureWhite !important;
}

.ql-container {
  border: none !important;
  height: 290px !important;
  overflow-y: scroll;
  overflow-x: visible;
  font-size: 16px !important;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.ql-color {
  display: none !important;
}

.ql-formats {
  margin-right: 0 !important;
}

.ql-tooltip {
  left: 4px !important;
}
