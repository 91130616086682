@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  width: 100%;
  max-width: 100%;
  padding: 24px 20px;
  background-color: $white;

  &__content {
    @extend %flex-justify-between;
    gap: 32px;
    width: 100%;

    @include breakpoint(tablet-l) {
      flex-direction: column;
    }

    &__main {
      @extend %flex-justify-between;
      width: 100%;
      gap: 16px;

      @include breakpoint(tablet-l) {
        flex-direction: column;
      }

      &__grid {
        @extend %flex-column-between;
        gap: 16px;
        width: 100%;
      }

      &__address {
        @extend %flex-column-between;
        gap: 16px;
        border-left: 1px solid #d9e0ea;
        padding-left: 12px;
        width: 100%;

        @include breakpoint(tablet-l) {
          border-left: none;
          padding-left: 0;
        }

        &_row {
          @extend %flex-between;
          gap: 12px;

          @include breakpoint(tablet-l) {
            flex-direction: column;
          }
        }
      }
    }
  }

  &__title {
    font-size: 14px;
    padding-bottom: 23px;
    letter-spacing: 0.035px;
  }
  &__inputs {
    padding-bottom: 24px;

    &__row {
      gap: 24px;
      display: flex;
      padding-bottom: 28px;

      &_new {
        @extend %flex-align;
        gap: 24px;
        padding: 0 45px;
        padding-bottom: 28px;

        @include breakpoint(desktop) {
          flex-direction: column;
          padding-inline: 0;
        }
      }

      @include breakpoint(desktop) {
        flex-direction: column;
      }
    }
  }
  &__upload {
    width: 100%;
    max-width: 100%;

    &__container {
      height: 108px !important;
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      flex-direction: row !important;
      gap: 16px;
      border-width: 2px;

      p {
        font-family: $circularBold !important;
        color: $dusk_slate;
        font-size: 25px;
      }

      &__box {
        height: 108px;
        max-width: 100%;

        &:hover {
          .container__upload__container__box__replace {
            visibility: visible;
            opacity: 1;
            transition: 0.3s;
          }
        }

        &__replace {
          bottom: 0 !important;
          visibility: hidden;
          opacity: 0;

          transition: 0.3s;
        }

        & > div {
          height: 100% !important;

          img {
            height: 100% !important;
            object-fit: contain !important;
          }
        }
      }
    }

    @include breakpoint(tablet-l) {
      margin: 0 auto;
    }
  }

  &__button {
    @extend %flex-end;
    padding-top: 24px;

    button {
      span {
        @extend %Circular-Std-14-boldMax;
      }
    }
  }
}
