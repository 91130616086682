@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    gap: 20px;
    background: $whispering_gray;
  }

  &__head {
    @extend %flex-between;
    margin-top: 40px;
    margin-bottom: 20px;

    &__title {
      @extend %Circular-Std-25-bold;
      color: black !important;
    }
  }

  &__options {
    @extend %flex-between;
    margin: 40px 0 24px;

    @include breakpoint(tablet-m) {
      flex-direction: column-reverse;
      gap: 20px;
      align-items: flex-start;
    }
  }

  &__footer {
    @extend %flex-end;
    margin-top: 10px;
    padding-bottom: 16px;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
