@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  overflow: hidden;
  border-radius: 0 0 10px 10px;

  &__head {
    @extend %flex-between;
    padding: 8px 24px;
    background-color: $paleBlue;

    &__title {
      @extend %Circular-Std-25-bold;
    }
  }

  &__content {
    @extend %scrollbar-gray;
    @extend %flex-column;
    gap: 24px;
    max-height: 224px;
    height: auto;
    padding: 24px;
    overflow-y: auto;

    &_scroll {
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__main {
      overflow-y: hidden;
      padding: 8px;
      background-color: white;
    }
  }
}
