@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-between;
  max-width: 420px;

  @media (min-width: $large-d) {
    max-width: 568px;
  }

  @include breakpoint(wide) {
    max-width: 299px;
  }

  @include breakpoint(tablet-l) {
    max-width: 100%;
  }

  &__wrapper {
    gap: 16px;
    width: 100%;
    display: flex;
    padding: 16px;
    max-width: 371px;
    border-radius: 10px;
    flex-direction: column;

    @include breakpoint(tablet-l) {
      max-width: 100%;
    }

    &__main {
      @extend %scrollbar-gray;
      height: fit-content;
      max-height: 339px;
      overflow-y: auto;
      padding-right: 2px;

      @include breakpoint(tablet-l) {
        max-height: 150px;
        height: 150px;
      }
    }

    @media (min-width: $large-d) {
      max-width: 520px;
    }
  }

  &__title {
    @extend %Circular-Std-21-bold;
    @extend %text-ellipsis;
    max-width: min-content;
    width: 95%;

    &__tooltip {
      @extend %Circular-Std-16-light;
      text-align: center;
    }
  }

  &__content {
    color: $midnightBlack !important;
    font-family: $circularLight !important;
    font-size: 16px !important;
    word-break: break-all;
    letter-spacing: 0.08px;

    &__createdBy {
      @extend %text-ellipsis;
      color: $midnightBlack !important;
      font-family: $circularBold !important;
      font-size: 12px !important;
      letter-spacing: 0.014px !important;
    }

    &__createdAt {
      color: $shadowed-slate !important;
      font-size: 12px !important;
      font-weight: 300 !important;
      font-family: $circularRegular;
      letter-spacing: 0.048px !important;
    }
  }
}

.board {
  overflow-y: auto;
  height: calc(100dvh - 265px);

  @include breakpoint(tablet-l) {
    overflow: visible;
    height: auto;
  }

  &__scroll {
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;

    div {
      grid-column: span 1;

      @include breakpoint(tablet) {
        grid-column: span 2;
      }
    }

    &_empty {
      display: flex !important;
      align-items: center;
    }

    &_load {
      @extend %flex-center;
      padding: 10px 0;

      grid-column: span 3 !important;
      margin-top: 10px;
    }

    &_more {
      @include breakpoint(tablet-l) {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
      }

      @include breakpoint(tablet) {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
      }
    }

    @include breakpoint(tablet-l) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 8px;
    }

    @include breakpoint(tablet) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include breakpoint(tablet-m) {
      display: flex;
      flex-direction: column;
    }
  }

  &__empty {
    margin: 25px auto;
  }

  &_box {
    @include breakpoint(tablet-l) {
      border: none;
      background-color: transparent;
      border-radius: 0;
      padding: 0 20px;
    }

    &_head {
      @extend %flex-between;
      margin-bottom: 24px;

      @include breakpoint(tablet-l) {
        justify-content: flex-end;
      }

      button {
        @include breakpoint(wide-xl) {
          align-self: flex-end;
          border-radius: 5px;
          background-color: transparent;
        }

        svg {
          @include breakpoint(wide-xl) {
            height: 24px;
            width: 24px;
          }
        }

        span {
          @include breakpoint(tablet-l) {
            margin-left: 0 !important;
            font-size: 12px;
            font-family: $circularLight !important;
          }
        }
      }
    }

    &_title {
      @extend %Circular-Std-25-bold;
      color: $midnightBlack !important;

      @include breakpoint(tablet-l) {
        display: none;
      }
    }
  }
}

.dropdown {
  @extend %flex-column;
  gap: 21px;
  padding: 24px;
  border-radius: 10px;
  background: $snowWhite;

  border: 1px solid $subtle_blue;

  &_item {
    @extend %flex-align;
    @extend %Circular-Std-14-reg;
    gap: 8px;
    cursor: pointer;

    &:hover {
      color: $sapphire;
    }
  }
}
.tooltip-popup-details {
  overflow-y: auto;
}
