@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  padding: 20px;
  max-width: 1040px;

  @include breakpoint(tablet-l) {
    padding: 20px 0;
  }

  &__plus {
    @extend %flex-align;
    gap: 8px;
    cursor: pointer;
    margin-top: 12px;

    &__text {
      @extend %Circular-Std-14-reg;
    }

    &__new {
      cursor: pointer;

      path {
        stroke: $midnightBlack;
      }
    }
  }

  &__form {
    @include breakpoint(tablet-l) {
      padding: 0;
    }
  }

  &__fields {
    @include breakpoint(tablet-l) {
      :first-child {
        padding: 24px 0;
      }

      padding: 53px 0;
    }
  }

  &__content {
    @extend %flex-between;
    gap: 32px;
    position: relative;
    margin-bottom: 14px;

    &__items {
      @extend %flex-start;
      width: 100%;
      gap: 10px;
    }

    @include breakpoint(tablet-l) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
      padding-bottom: 24px;
      border-bottom: 1px dashed #ccc;

      &:last-child {
        border-bottom: none;
      }
    }

    &__remove {
      cursor: pointer;
      min-width: 16px;
      max-width: 16px;

      @include breakpoint(tablet-l) {
        right: 0;
        top: 0;
      }
    }

    &__email {
      position: relative;
      width: 100%;

      &__error {
        @extend %Circular-Std-12-reg;
        padding-left: 8px;
        color: red !important;
        position: absolute;
      }
    }
  }
}
