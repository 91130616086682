@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.error {
  @extend %flex-center;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 16px;
  height: 16px;
  z-index: 10;
  color: white;
  border-radius: 50%;
  background: red;
}
