@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container__content__item {
}

.icon {
  margin-top: 3px; 
  width: 30px;
  height: auto;
  margin-right: 10px;
}

h1.container__content__item {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 1px; 
  display: inline-block;
}

span.container__content__item {
  display: inline-block; 
  vertical-align: top;
  margin-right: 170px;
  margin-left: 45px;
  margin-bottom: 25px;
  width: 30%;
}

.container__footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-left: -50px;
}

.container__content__submit {
  cursor: pointer;
  margin: 0 10px;
}

.container__content__submit:first-child {
  order: 2;
}

.header {
  margin-bottom: 20px; 
  justify-content: space-between;
  align-items: center;
}

.header h6 {
  font-size: 25px;
  font-weight: bold;
  color: #333;
}

.header h5 {
  font-size: 15px;
  font-weight: bold;
  color: #666;
  margin-right: 60px;
  margin-bottom: 10px;
  text-align: right;
}

.container__content__submit:last-child {
  order: 1;
}

.iconContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.container__content__item b {
  font-weight: 547;
  font-size: 15px;
  font-family: Arial, sans-serif;
}

.box {
  border: 1px solid #ccc; 
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 10px;
  width: 95%;
}