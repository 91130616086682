@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  max-width: 150px;
  width: 100%;

  input {
    font-size: 14px !important;
  }

  button {
    @extend %text-ellipsis;
  }

  &__button {
    &__icon {
      position: absolute;
      top: 0px;
      right: 20px;
      cursor: pointer;

      &_del {
        right: 0;
        position: absolute;
        top: 0px;
        cursor: pointer;
      }
    }
  }
}

.wrapper {
  max-width: 150px;
  padding: 1px 8px;
  border-width: 2px;
  background: transparent;
  border-bottom: 1px solid #ccc;
  font-family: $circularMedium;

  &__button {
    cursor: pointer;
    position: relative;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 150px;
    overflow: hidden;
    width: 150px;
    padding: 8px 16px;
    border-width: 2px;
    background: transparent;
    font-family: $circularMedium;

    &__text {
      position: relative;

      &__main {
        @extend %text-ellipsis;
        max-width: 100px;
        display: block;
      }

      &__remove {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        left: 0;
        cursor: pointer;

        visibility: hidden;
        transition: 0.4s;
        top: 5px;
        backdrop-filter: blur(10px);

        &_active {
          visibility: visible !important;
          top: 0;
          transition: 0.4s;
        }
      }
    }
  }
}
