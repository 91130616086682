@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  margin-right: 15px;
  &:not(:last-child) {
    border-bottom: 0.5px dashed #ccc;
  }

  &__item {
    @extend %flex-between;
    margin-bottom: 16px;

    div {
      width: 100%;
    }

    @include breakpoint(tablet-l) {
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0;
    }
  }
}
