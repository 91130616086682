@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px;
  }

  @include breakpoint(tablet-l) {
    padding: 0 16px;
  }

  &__head {
    &__back {
      margin-bottom: 8px;
      padding: 0 !important;
      justify-content: flex-start;

      span {
        color: black !important;
        padding: 0 !important;
      }
    }

    &__info {
      @extend %flex-column;
      gap: 24px;

      &__number {
        @extend %flex-align;
        @extend %Circular-Std-25-bold;
        gap: 8px;

        @include breakpoint(tablet) {
          font-size: 21px;
        }

        &__link {
          color: $sapphire;
        }

        &__status {
          @extend %flex-align;
          gap: 4px;
          padding: 4px;
          border-radius: 5px;
          background: $frostWhite;
          max-width: fit-content;
          text-transform: capitalize;
          border: 1px solid $subtle_blue;

          p {
            @extend %Circular-Std-12-reg;
          }
        }
      }

      &__actions {
        @extend %flex-between;
        margin-bottom: 24px;

        @include breakpoint(tablet) {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
        }

        &__text {
          @extend %Circular-Std-14-reg;

          &__name {
            text-transform: capitalize;
          }
        }

        &__buttons {
          @extend %flex-align;
          gap: 24px;

          @include breakpoint(tablet) {
            gap: 16px;
            flex-wrap: wrap;
          }

          &__item {
            padding: 12px 16px;
            text-transform: capitalize;
          }
        }
      }
    }

    &__navigation {
      @extend %flex-between;
      margin-bottom: 40px;

      @include breakpoint(tablet) {
        margin-bottom: 10px;
      }

      &__edit {
        max-width: 178px;
        white-space: nowrap;
        border-radius: 5px;
        padding: 11px 8px 11px 8px;
        background-color: $paleBlue;

        @include breakpoint(tablet) {
          padding: 5px;
          min-width: 40px;
          max-width: min-content;
        }
      }
    }

    &__description {
      @extend %flex-align;
      margin-bottom: 40px;
      gap: 24px;

      @include breakpoint(tablet-l) {
        gap: 16px;
      }

      @include breakpoint(tablet) {
        gap: 5px;
        align-items: flex-start;
        flex-direction: column;
      }

      &__title {
        @extend %Circular-Std-16-regular;
        padding: 10px 8px;

        @include breakpoint(tablet-l) {
          font-size: 14px;
        }
      }

      &__subtitle {
        @extend %Circular-Std-16-regular;
        @extend %flex-align;
        padding: 10px 8px;
        text-decoration: underline;
        color: $sapphire !important;

        @include breakpoint(tablet-l) {
          font-size: 14px;
        }
      }
    }
  }

  &__footer {
    @extend %flex-end;

    &__text {
      text-align: right;
      border-radius: 5px;
      padding: 9px 16px;
      background: $frostWhite;
      max-width: fit-content;
      border: 1px solid $subtle_blue;
      @extend %Circular-Std-16-regular;
    }
  }
}

.loader {
  @extend %flex-center;
}

.error {
  @extend %Circular-Std-12;
  color: rgb(229, 2, 2) !important;
}
