@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column-between;
  width: 100%;
  padding: 20px 0;
  margin: 10px 16px;
  max-width: 274px;
  cursor: pointer;
  max-height: 389px;
  border-radius: 8px;
  transition: 0.5s;
  border: 1px solid $light_steel_gray;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);

  @include breakpoint(desktop) {
    min-width: 274px;
  }

  &_active {
    background-color: $deep_cerulean;
    color: $white;

    @include breakpoint(tablet-l) {
      transform: scale(1);
    }

    .container__submit__button {
      background-color: $white;
    }

    .container__submit__button {
      background-color: $white;
    }
  }

  &__head {
    @extend %flex-center;
    width: 100%;
    margin-bottom: 20px;

    &__text {
      white-space: nowrap;
      text-align: center;
      font-size: 25px !important;
      line-height: normal;
      font-family: $circularBold !important;
    }

    &__seperator {
      width: 27px;
      height: 27px;
    }

    &__label {
      @extend %flex-between;
      margin-right: 0;

      span {
        padding: 0;
      }

      &__radio {
        &_active {
          span {
            color: $white !important;
          }
        }
      }
    }
  }

  &__list {
    margin-bottom: 20px;
    padding-inline: 14px;
    max-height: 188px;
    overflow-y: scroll;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__submit {
    @extend %flex-center;
    padding: 0 32px;

    &__button {
      max-width: 100%;
      width: 100%;
      padding: 15px 0;
      border-radius: 6px;
      font-family: $circularRegular;

      background-color: $paleBlue;
    }
  }
}
