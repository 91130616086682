@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 15px;
  background-color: $white;
  border-radius: 10px;
  padding: 20px;
  max-height: calc(100dvh - 40px);
  height: 100%;
  overflow: auto;

  scrollbar-width: 0px;

  &::-webkit-scrollbar {
    display: none;
  }

  &__header {
    @extend %flex-between;
  }

  h2 {
    font-size: 20px;
    font-family: 'CircularStdMedium';
  }

  &__main {
    @extend %flex-column;
    gap: 10px;
    margin-top: 10px;

    li {
      font-family: 'CircularStdRegular';

      font-size: 16px;
      line-height: 1.5;
      color: $black;
      list-style: inside;
      list-style-type: decimal;
    }
  }
}
