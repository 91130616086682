@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  margin: 40px 0;

  @include breakpoint(tablet) {
    margin: 20px 0;
  }

  &_title {
    line-height: normal;
    color: $staleGray !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    letter-spacing: 0.08px !important;
    font-family: $circularRegular !important;
  }

  &_subtitle {
    line-height: normal;
    color: $midnightBlack !important;
    font-size: 49px !important;
    font-weight: 500 !important;
    font-family: $circularRegular !important;
  }
}
