@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 40px;
  }

  &__quantity {
    @extend %flex-align;
    gap: 8px;
    padding-right: 21px;
  }

  &__icon {
    margin: 0 auto;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: $dusk_slate;
      }
    }
  }

  img {
    border: 0.5px dashed #ccc;
    object-fit: cover;
  }

  &__add {
    @extend %flex-align;
    cursor: pointer;
    max-width: fit-content;
    gap: 4px;
    margin-bottom: 24px;

    &__text {
      @extend %Circular-Std-14;
      color: rgba(34, 34, 34, 0.8) !important;
    }
  }
  input {
    padding: 15.5px 16px;
  }
}

.loader {
  @extend %flex-center;
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
