@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  gap: 33px;
  width: 100%;
  display: flex;
  padding: 24px;
  max-width: 100%;
  background-color: $white;

  @include breakpoint(tablet-l) {
    flex-direction: column;
  }

  @include breakpoint(tablet-m) {
    padding: 20px;
  }

  &__submit {
    @extend %flex-end;
    margin-top: 16px;
  }

  &__inputs {
    @extend %flex-column;
    gap: 16px;
    width: 100%;
    max-width: 100%;

    &__address {
      @extend %flex-column;
      gap: 16px;
      padding-top: 16px;
      border-top: 1px solid #d9e0ea;

      &__details {
        @extend %flex-align;
        gap: 20px;

        @include breakpoint(tablet-m) {
          flex-direction: column;
        }
      }
    }

    &_grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 16px 20px;

      @include breakpoint(tablet-m) {
        grid-template-columns: none;
        gap: 16px;
      }
    }

    @include breakpoint(tablet-l) {
      gap: 18px;
      max-width: 100%;
    }

    &__row {
      gap: 24px;
      width: 100%;
      display: flex;
      flex-direction: row;

      @include breakpoint(tablet-l) {
        gap: 18px;
        flex-direction: column;
      }
    }
  }
}
