@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  &__icon {
    @extend %flex-center;
    max-width: 24px;
    width: 100%;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &_active {
    svg {
      fill: $white;

      path {
        fill: $white;
      }
    }

    .container__item {
      color: $white;
    }
  }

  &__item {
    color: $obsidianAura;
    font-size: 14px;
    margin-top: 2px !important;

    letter-spacing: 0.035px;
    text-align: start;
    font-family: $circularRegular;
  }
}
