@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  display: flex;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;

  &__content {
    @extend %flex-column;
    width: 100%;
    gap: 16px;
    border-radius: 10px;
  }

  &__item {
    @extend %flex-between;
    gap: 24px;

    @include breakpoint(tablet) {
      gap: 10px;
    }
    @include breakpoint(mobile){
      flex-direction: column;
    }
  }

  input {
    width: 100%;
  }

  &__remove {
    cursor: pointer;
  }
}
