@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  margin-bottom: 24px;
  margin-top: 24px;

  &__add {
    @extend %flex-align;
    @extend %Circular-Std-14;
    cursor: pointer;
    margin-top: 10px;
    gap: 4px;
    color: rgba(34, 34, 34, 0.8) !important;

    p {
      font-family: $circularRegular !important;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px 30px;
    margin-bottom: 24px;
    width: 100%;

    @include breakpoint(tablet) {
      gap: 10px;
    }

    @include breakpoint(mobile) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__item {
    @extend %flex-between;
    gap: 24px;

    @include breakpoint(tablet) {
      gap: 10px;
    }
  }

  input {
    width: 100%;
  }

  &__remove {
    cursor: pointer;
  }
}
