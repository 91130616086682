@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  display: flex;
  justify-content: space-between;
  gap: 41px;

  &_tab {
    flex-direction: column;
    background-color: $whispering_gray;
    padding-bottom: 20px;
  }

  &__tabs {
    padding: 0 20px;
  }

  &__header {
    display: none;

    @include breakpoint(tablet-l) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
    }

    &__title {
      font-size: 22px;
      font-weight: 500 !important;
      letter-spacing: 0.08px;
    }
  }

  &_activity {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 100%;

    max-width: calc(100dvw - 199px - 420px - 120px);

    @include breakpoint(wide) {
      max-width: calc(100dvw - 300px - 75px);
    }

    @media (min-width: $large-d) {
      max-width: 760px;
      height: calc(100dvh - 155px);
      overflow: auto;
      padding-right: 5px;

      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background: $light_grayish_silver;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $medium_gray;
        border-radius: 4px;
        border: 1px solid $light_grayish_silver;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: $obsidianAura;
      }
    }

    &__load {
      @extend %flex-center;
      width: 100%;

      @include breakpoint(tablet-l) {
        padding: 5px 0 20px 0;
      }
    }

    @include breakpoint(tablet-l) {
      max-width: 100%;
      gap: 12px;
    }

    &_head {
      @include breakpoint(tablet-l) {
        padding: 0 20px;
      }
    }

    &_filter {
      @include breakpoint(tablet-l) {
        padding: 0 20px;
      }
    }
  }

  &_notice {
    width: 100%;
    max-width: 420px;
    height: calc(100dvh - 157px);
    position: sticky;
    top: 122px;
    padding: 24px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid $subtle_blue;
    overflow: hidden;

    @media (min-width: $large-d) {
      max-width: 550px;
      top: 0;
    }

    @include breakpoint(wide) {
      max-width: 299px;
    }

    @include breakpoint(tablet-l) {
      max-width: 100%;
      position: static;
      top: 0;
      height: auto;
      border: none;
      max-width: 100%;
      background-color: transparent;
      padding: 0;
    }
  }

  &_empty {
    @extend %flex-center;
    margin-top: 40px;
  }
}
