@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-center-column;
  gap: 32px;

  &__content {
    @extend %flex-center-column;
    gap: 4px;
    max-width: 564px;
    text-align: center;

    &__title {
      @extend %Circular-Std-49-bold;
    }

    &__subtitle {
      @extend %Circular-Std-18-regular;
      letter-spacing: unset;
      color: $shadowed-slate !important;
    }
  }
}
