@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  display: flex;
  gap: 16px;

  &__image {
    &__icon {
      border-radius: 50%;
      border: 3px solid #e1f1f4;
    }
  }

  &_typo {
    &_name {
      @extend %text-ellipsis;
      @extend %Circular-Std-16-regular;
      display: block;

      color: $midnightBlack !important;
    }
    &_role {
      @extend %Circular-Std-14-reg;
      font-style: italic;
      text-transform: capitalize;
      color: $graphiteGray !important;
    }
    &_hour {
      @extend %Circular-Std-12-reg;
      color: $silverSlate !important;
    }

    &_team {
      @extend %Circular-Std-12-light;
      @extend %text-ellipsis;
      padding: 4px;
      max-width: 100px;
      color: $sapphire !important;
    }
  }
}
