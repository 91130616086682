@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  background-color: $white;
  padding: 24px;
  gap: 10px;

  &__content {
    @extend %flex-column;
    gap: 5px;

    &__title {
      font-family: $circularMedium;
    }

    &__main {
      display: flex;
      gap: 5px;
      max-width: 100%;
      overflow-x: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: 0;
    }
  }

  &__empty {
    font-family: $circularMedium;
  }
}
