@import '../../../../../../styles/vars.scss';
@import '../../../../../../styles/colors.scss';
@import '../../../../../../styles/mixins.scss';
@import '../../../../../../styles/fonts.scss';
.container {
  &__head {
    @extend %flex-align;
    gap: 12px;
    margin-bottom: 27px;

    &__title {
      @extend %flex-align;
      @extend %Circular-Std-21;
      color: $black !important;
      text-decoration: underline;
      text-underline-offset: 3px;
    }

    &__subtitle {
      @extend %Circular-Std-12-reg;
      color: $tableCellGray !important;
    }
  }

  &__content {
    @extend %flex-column;
    gap: 25px;

    &__item {
      @extend %flex-between;
      width: 100%;
      gap: 24px;
    }
  }
}
