@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;
  width: 100%;
  height: 600px;
  background-color: $sapphire;

  @include breakpoint(tablet-l) {
    height: 400px;
  }

  &__content {
    @extend %flex-between;
    max-width: 1512px;
    height: 600px;

    margin: 0 auto;

    @include breakpoint(tablet-l) {
      height: 400px;
    }
  }

  &__img {
    @extend %flex-center;
    width: 100%;
    height: 100%;

    @include breakpoint(desktop) {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__main {
    @extend %flex-center;
    flex-direction: column;
    position: relative;
    z-index: 3;
    padding: 20px;
    max-width: 781px;

    @include breakpoint(tablet-l) {
      padding: 20px;
    }

    @include breakpoint(desktop) {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: hsla(0, 0%, 100%, 0.3);
      backdrop-filter: saturate(180%) blur(5px);
      box-shadow: 0 -3px 10px 3px rgba(0, 0, 0, 0.2);
      max-width: 100%;
    }

    &__title {
      color: $snowWhite !important;
      text-align: center;
      font-family: $circularBold;
      font-size: 35px;
      letter-spacing: 0.088px;
      margin-bottom: 24px;

      @include breakpoint(tablet) {
        font-size: 20px;
      }
    }

    &__subtitle {
      color: $snowWhite !important;
      text-align: left;
      font-family: $circularRegular;
      font-size: 21px;
      letter-spacing: 0.032px;
      height: fit-content;

      @include breakpoint(tablet) {
        font-size: 14px;
      }
    }
  }
}
