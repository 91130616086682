@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 20px;

  &__title {
    @extend %Circular-Std-25-bold;
  }

  &__actions {
    @extend %flex-between;

    @include breakpoint(tablet) {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }

    &__add {
      @extend %flex-center;
      padding: 10px 16px;
      gap: 8px;
      border-radius: 5px;
      max-width: 233px;
      text-transform: capitalize;
      max-width: fit-content;

      span {
        margin: 0;
        @extend %Circular-Std-14-reg;
      }

      svg {
        path {
          stroke: $white;
        }
      }
    }
  }
}
