@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  position: relative;
  &__select {
    cursor: pointer;
    padding: 15.5px 16px;
    border-radius: 5px;
    @extend %flex-between;
    background-color: $neutral-gray;
    border: 1px solid $subtle_blue;

    &__text {
      @extend %Circular-Std-16-regular;
      color: rgba(0, 0, 0, 0.6) !important;
    }

    &__icon {
      width: 16px;
      height: 16px;
      transform: rotate(0);

      &__active {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown {
    top: 60px;
    z-index: 3;
    width: 100%;
    overflow-y: auto;
    max-height: 320px;
    position: absolute;
    background-color: $snowWhite;
    box-shadow: rgba(207, 207, 207, 0.2) 0px 2px 15px 1px;

    &__new {
      padding: 16px;
    }

    &__item {
      padding: 16px;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        background-color: $platinumGray;
      }
    }
  }

  &__add {
    background-color: $paleBlue;
    padding: 8px;
    border-radius: 5px;

    &:hover {
      background-color: $pale_cornflower_blue;
    }

    span {
      margin-left: 0;
    }
  }
}
