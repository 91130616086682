@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 700px;
  width: 100%;
  border-radius: 10px;
  background-color: white;
  border: 0.5px dashed #ccc;
  transform: translate(-50%, -50%) !important;

  @media (min-width: $large-d) {
    max-width: 760px;
  }

  @include breakpoint(tablet-l) {
    max-width: 500px;
  }

  @include breakpoint(tablet-m) {
    max-width: 370px;
  }

  &_header {
    @extend %flex-between;
    padding: 24px;
    background: $pureWhite;
    border-radius: 10px 10px 0px 0px;
    border-bottom: 0.5px solid #ccc;

    @include breakpoint(tablet-m) {
      padding: 12px;
    }

    &__title {
      font-size: 25px;
      font-family: $circularBold;
    }

    svg {
      cursor: pointer;
    }
  }

  &_children {
    padding: 16px 24px;

    @include breakpoint(tablet-m) {
      padding: 20px;
    }
  }

  &_footer {
    @extend %flex-between;
    padding: 24px;

    @include breakpoint(tablet-m) {
      padding: 12px;
      gap: 10px;
      flex-direction: column;
    }

    &_content {
      padding: 8px;
      display: flex;
      gap: 24px;
      cursor: pointer;

      label {
        display: flex;
        cursor: pointer;
        gap: 12px;
      }

      input {
        display: none;
      }

      &_image {
        @extend %flex-center;
        gap: 12px;
      }

      &_video {
        @extend %flex-align;
        gap: 12px;
      }

      &_divider {
        @extend %flex-center;
        border: 0.5px solid $staleGray;
      }

      p {
        color: $jet_black !important;
        font-family: $circularRegular !important;
        font-size: 14px !important;
        font-weight: 500 !important;
        letter-spacing: 0.035px !important;
      }

      &_button {
        padding: 11px 16px;
        border-radius: 10px;
        background: $sapphire;
        color: $snowWhite;

        &:disabled {
          color: $white;
        }

        &:hover {
          background: $sapphire;
        }
      }
    }
  }

  &_emoji {
    position: absolute;
    bottom: 70px;
    right: 0;
    z-index: 6;

    @include breakpoint(tablet-l) {
      height: min-content;
      bottom: 70px;
      right: 8px;
    }

    em-emoji-picker {
      height: 150px;

      @include breakpoint(tablet-l) {
        height: 150px;
      }
    }
  }
}
