@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  max-width: 100%;

  @include breakpoint(wide) {
    max-width: 100%;
    padding: 20px;
  }

  @include breakpoint(tablet-l) {
    padding: 20px 0;
  }
}
