@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 150px;
  height: 150px;
  max-width: 150px;
  min-width: 150px;
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #e1f1f4;

  @include breakpoint(tablet-m) {
    width: 100px;
    height: 100px;
    max-width: 100px;
    min-width: 100px;
  }

  &__avatar {
    border: 0.5px solid #ccc;
    height: inherit;
    overflow: hidden;
    border-radius: 50%;
    width: 100%;
    position: relative;
    object-fit: cover;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 30px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
  }

  &__edit {
    position: absolute;
    top: 14px;
    right: 14px;
    height: 24px;
    z-index: 12;
    stroke: white;
    cursor: pointer;
    margin: 10px 9px;

    @include breakpoint(tablet-m) {
      top: 0;
      right: 0;
    }
  }
}
