@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
    padding: 20px;
    width: 100%;
    background: $snowWhite;
    max-width: 100%;
  
    @include breakpoint(desktop) {
      max-width: 100%;
    }
  
    &__head {
      @extend %flex-start;
      padding-bottom: 20px;
  
      &__title {
        @extend %Circular-Std-21;
        letter-spacing: 0.032px;
        font-family: $circularBold !important;
        
      }
  
      &__button {
        background-color: $paleBlue;
        max-width: 77px;
        border-radius: 4px;
        margin-left: 100px;
  
        span {
          @extend %Circular-Std-14;
          text-transform: capitalize;
        }
  
        &:hover {
          background-color: $paleBlue;
        }
      }
    }
  
    &__content {
      @extend %flex-column;
      gap: 8px;
  
      &__item {
        @extend %flex-align;
        gap: 16px;
  
        &_expiry {
          @extend %flex-between;
          width: 100%;
  
          @include breakpoint(tablet-m) {
            flex-direction: column;
            width: fit-content;
            gap: 5px;
          }
        }
  
        &__time {
          @extend %Circular-Std-12-light;
          font-size: 14px;
          font-family: $circularRegular !important;
          color: $shadowed-slate !important;
  
          &__content {
            padding: 2px 4px;
            border-radius: 4px;
            margin-left: 8px;
            background: $light_grayish_silver;
          }
        }
  
        &__text {
          @extend %Circular-Std-14-bold;
        }
      }
    }
  }
  