@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @include breakpoint(tablet-l) {
    padding: 0 20px 20px 20px;
    background-color: $whispering_gray;
  }

  &_content {
    margin-top: 24px;
  }

  &_link {
    text-decoration: underline !important;
    text-underline-offset: 3px;
    color: $sapphire !important;
    font-size: 14px;
    font-family: $circularLight;

    &_new {
      color: $sapphire !important;
      text-decoration: none !important;
    }
  }

  &__footer {
    @extend %flex-end;
    padding: 20px 0;
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
