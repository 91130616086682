@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  border-radius: 0;
  overflow: hidden;
  height: 401px;

  @include breakpoint(tablet) {
    height: 300px;
  }

  &__provider {
    border-radius: 0 !important;

    video {
      border-radius: 0 !important;
    }
  }

  &__controls {
    @extend %flex-between;
    padding: 16px 14px;
    background-color: $midnightBlack;

    &__first {
      &__duration {
        color: white;
        font-size: 14px;
        font-style: italic;
        font-family: $circularLight;
      }
    }
  }

  &__video {
    position: relative;

    video {
      height: 100%;
    }

    &__close {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      cursor: pointer;
    }

    &__progress {
      position: absolute;
      bottom: 20px;
      z-index: 10;
      width: 100%;
      padding: 0 26px;

      input[type='range'] {
        width: 100%;
        margin-bottom: 20px;
        -webkit-appearance: none;
        appearance: none;
        height: 10px;
        border-radius: 5px;
        background: #ddd;
        outline: none;
        opacity: 0.7;
        -webkit-transition: opacity 0.2s;
        transition: opacity 0.2s;
      }

      input[type='range']:hover {
        opacity: 1;
      }

      input[type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4caf50;
        cursor: pointer;
      }

      input[type='range']::-moz-range-thumb {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #4caf50;
        cursor: pointer;
      }

      input {
        width: 100%;
      }
    }
  }
}

.safari__provider {
  video {
    height: calc(100% - 56px) !important;
    position: absolute;
    top: 0;
    object-fit: cover;
  }
}
