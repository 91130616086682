@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  cursor: pointer;

  &__button {
    .container__title {
      @extend %flex-center;
      font-family: $circularMedium !important;
      font-size: 14px;
      padding: 11px 11px;
      background-color: $sapphire;
      text-transform: uppercase;
      border-radius: 10px;
      color: $white !important;
    }
  }

  &__input {
    display: none;
  }

  &__title {
    @extend %Circular-Std-21;
  }
}
