@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  &__head {
    @extend %flex-between;
    margin-bottom: 24px;
    padding-top: 16px;

    @include breakpoint(tablet-m) {
      flex-direction: column;
      align-items: flex-end;
      gap: 5px;
    }

    &__title {
      @extend %Circular-Std-25-bold;
    }

    &__subtitle {
      @extend %Circular-Std-21;
    }
  }

  &__content {
    @extend %scrollbar-gray;
    max-height: calc(100dvh - 40px - 42px - 56px - 24px - 10px - 30px);
    overflow-y: scroll;
    overflow-x: hidden;
    padding-right: 10px;

    &__first {
      @extend %flex-column;
      gap: 16px;
      margin-bottom: 24px;

      &:not(:last-child) {
        border-bottom: 0.5px solid #ccc;
      }

      &:first-child {
        border: none;
      }

      &__item {
        @extend %flex-between;

        @include breakpoint(tablet-l) {
          flex-direction: column;
        }
      }

      @include breakpoint(tablet-m) {
        gap: 0;
      }
    }

    &__second {
      @extend %flex-column;
      padding: 0 16px;
      gap: 8px;
      padding-bottom: 32px;
      border-bottom: 0.5px solid #ccc;
      margin-bottom: 32px;

      @include breakpoint(tablet-m) {
        background: #f7f4f4;
        border-radius: 10px;
        padding: 10px;
      }

      &__title {
        @extend %Circular-Std-14-reg;
        color: rgba(34, 34, 34, 0.8) !important;
      }

      &__content {
        @extend %Circular-Std-14-reg;
        max-width: 100%;
        overflow: scroll;
        line-height: 24px;
        max-height: 75px;

        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  &__footer {
    @extend %flex-end;
    padding-top: 20px;
  }
}
