@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.title {
  @extend %Circular-Std-25-light;
  padding: 16px 0 40px;
  text-align: center;
}
.container {
  @extend %flex-column-between;
  padding-top: 8px;
  height: calc(100vh - 135.5px);

  &__content {
    @extend %flex-column;
    gap: 32px;
  }

  &__add {
    margin-top: 8px;
    border-radius: 5px;
    padding: 10.5px 8px;
    background-color: $pastelSky;

    span {
      @extend %Circular-Std-12-light;
      margin-left: 0;
    }

    &:hover {
      background-color: $pastelSky;
    }
  }

  &__actions {
    @extend %flex-end;
    gap: 24px;

    button {
      text-transform: capitalize;
    }
  }

  &__details {
    @extend %flex-column;
    gap: 16px;
    max-height: 240px;
    height: 100%;
    overflow: auto;
    padding-right: 15px;
    margin-bottom: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    &__sections {
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(2, 1fr);

      &__item {
        @extend %flex-between;
        @extend %Circular-Std-12-reg;
        max-width: 100%;
        color: $midnightBlack;
        border-radius: 5px;
        border: 0.5px dashed #ccc;

        svg {
          path {
            stroke: $midnightBlack;
          }
        }

        &__active {
          color: $sapphire;
          background-color: $pastelSky;

          svg {
            path {
              stroke: $sapphire;
            }
          }
        }
      }
    }

    &__title {
      @extend %Circular-Std-14-reg;

      &__count {
        color: $aquamarine_blue;
      }
    }
  }
}
