@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  width: 100%;
  max-width: 213px;
  border-radius: 5px;
  border: 0.5px dashed #ccc;
  background: $frostWhite;
  position: relative;
  cursor: pointer;

  @include breakpoint(tablet-l) {
    max-width: 100%;
  }

  &__menu {
    @extend %flex-center;
    position: absolute;
    right: 8px;
    top: 8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $white;
  }

  &__checked {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &__title {
    border-bottom: 1px solid $paleSilver;
    width: 100%;
    padding: 5px;
    color: $sapphire;
    font-family: $circularMedium;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;

    :last-child {
      border-bottom: none;
    }

    &__items {
      @extend %flex-between;
      flex-direction: row;
      color: $translucent_charcoal;
      letter-spacing: 0.035px;
      border-bottom: 1px solid $paleSilver;
      padding: 10px 0;
      gap: 18px;

      &__subtitle {
        @extend %text-ellipsis;
        max-width: 100%;
      }

      span {
        color: $midnightBlack;
        letter-spacing: 0.035px;
      }

      &__description {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
      height: 172px;
      object-fit: cover;
      border-radius: 5px 5px 0 0;

      @include breakpoint(tablet-l) {
        width: 100%;
      }
    }
  }
}
