@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-center;
  height: 313px;
  max-width: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &__overlay {
    display: none;
    position: absolute;
    z-index: 10;
    width: 100%;
    background-color: rgb(0 0 0 / 40%);

    &_disabled {
      display: none !important;
    }
  }

  &:hover {
    .container__overlay {
      display: block;
    }

    .container__controls {
      visibility: visible;
    }
  }

  &__controls {
    @extend %flex-center;
    position: absolute;
    z-index: 11;
    border-radius: 50%;
    background-color: $semi_transparent_white;
    width: 80px;
    height: 80px;
    visibility: hidden;
    cursor: pointer;

    svg {
      min-width: 90px;
      max-width: 90px;
      height: 90px;
    }
  }

  @media (min-width: $large-d) {
    max-width: 760px;
  }

  @include breakpoint(tablet-l) {
    max-width: 100%;
  }

  @include breakpoint(tablet-m) {
    height: 167px;
  }

  video {
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-width: 300px;
    position: relative;
  }

  &_image {
    height: 100%;
    width: 100%;
  }
}
