@import '../../../../../styles/vars.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';
@import '../../../../../styles/fonts.scss';
.container {
  position: relative;

  &__select {
    @extend %flex-between;
    cursor: pointer;
    padding: 17.5px 16px;
    border-radius: 5px;
    background-color: #fcfcfc;
    border: 1px solid #edeff1;

    &__text {
      @extend %Circular-Std-16-light;

      &_disable {
        color: rgba(0, 0, 0, 0.6) !important;
      }
    }

    &__icon {
      transform: rotate(0);

      &__active {
        transform: rotate(180deg);
      }
    }
  }

  &__dropdown {
    width: 100%;
    position: absolute;
    top: 66px;
    z-index: 3;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
      0px 3px 14px 2px rgba(0, 0, 0, 0.12);

    &__item {
      padding: 17.5px 16px;
      transition: 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #cccccc31;
      }
    }
  }
}
