@import '../../styles/vars.scss';
@import '../../styles/colors.scss';
@import '../../styles/mixins.scss';
@import '../../styles/fonts.scss';
.container {
  @extend %flex-column;
  gap: 24px;

  @include breakpoint(tablet-l) {
    padding: 0 20px;
    background-color: $whispering_gray;
  }

  &__link {
    text-decoration: underline !important;
  }

  &__icon {
    margin: 0 auto;
  }

  &__footer {
    @extend %flex-align-end;
    gap: 16px;
    margin-bottom: 15px;
    margin-top: 16px;

    &__total {
      @extend %flex-center;
      position: relative;
      gap: 10px;
      padding: 5.5px 14px;
      border-radius: 5px;
      border: 1px solid #ccc;
      background: $platinumGray;
      cursor: pointer;

      &__selected {
        @extend %Public-Sans-14-reg;
      }

      &__dropdown {
        width: 100%;
        position: absolute;
        z-index: 3;
        bottom: 50px;
        left: 0;
        background: $platinumGray;
        border: 1px solid #ccc;
        border-radius: 5px;

        &__item {
          padding: 10px 14px;
          transition: 0.3s;

          &:hover {
            background: #e7e6e6;
          }
        }
      }
    }

    &__text {
      @extend %Circular-Std-14-reg;
    }
  }

  &__actions {
    @extend %flex-column;
    gap: 8px;
    width: 100%;

    &__item {
      width: 100%;
      padding: 5px 8px;
      background-color: $paleBlue;
      color: $sapphire;
      border-radius: 2px;
      border: 1px solid $paleBlue;

      span {
        @extend %Circular-Std-12-light;
        text-transform: capitalize;

        @include breakpoint(wide) {
          font-size: 10px;
        }

        @include breakpoint(tablet) {
          font-size: 12px;
        }
      }
    }
  }
}

.activeSortHeader {
  &_asc {
    svg {
      g {
        path {
          &:nth-child(1) {
            stroke: $sapphire;
            opacity: 1 !important;
            fill: $sapphire;
          }
        }
      }
    }
  }

  svg {
    g {
      path {
        &:nth-child(2) {
          stroke: $sapphire;
          opacity: 1 !important;
          fill: $sapphire;
        }
      }
    }
  }
}
