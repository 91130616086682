@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  @extend %flex-align;

  &__label {
    margin-left: '8px';
  }
}
