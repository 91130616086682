@import '../../../styles/vars.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/fonts.scss';
.container {
  position: relative;
  cursor: pointer;

  &__content {
    @extend %flex-end;
    align-items: center;
    gap: 15px;
    width: 100%;
    cursor: pointer;
  }

  &__title {
    @extend %Circular-Std-14-med;
    color: $black !important;
  }

  &__absolute {
    // bottom: 30px;
    background: #fff;
    position: absolute;
    z-index: 1000;
    border-radius: 10px;
    border: 0.5px dashed #ccc;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05), 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  }
}
