@import '../../../../styles/vars.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/fonts.scss';
.container {
  @extend %flex-align;
  gap: 8px;

  &__img {
    border-width: 3px;
  }

  &__name {
    @extend %Circular-Std-14-reg;
    color: $tableCellGray !important;
  }
}
